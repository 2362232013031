import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { User } from '../../../../interfaces/user.interface';
import { Order } from '../../../../interfaces/order.interface';
import { Location } from '../../../../interfaces/location.interface';
import { DirectusService } from '../../../../vendors/directus/directus.service';
import { ToastService } from '../../../../services/toast.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MainSettings } from '../../../../vendors/directus/interfaces/main-settings.interface';
import { VendorSetup } from '../../../../interfaces/vendor.interface';
import { CodeDisplayMode } from '../../../../interfaces/code-display-mode.enum';
import {
  CreateQuickCode,
  SetApplePassbookURL,
  SetGoogleWalletData,
  SetGoogleWalletSaved,
  UserCheckIn,
} from '../../../../store/actions/user.actions';
import { CheckInCode } from '../../../../interfaces/check-in-code.interface';
import { BarcodeScanner, SupportedFormat } from '@capacitor-community/barcode-scanner';
import { Capacitor } from '@capacitor/core';
import moment from 'moment-timezone';
import { LoyaltyInstructionsModalComponent } from '@modules/loyalty/components';
import { TextField } from '../../../../vendors/directus/interfaces/text-field.interface';
import { filter } from 'rxjs/operators';
import { GlobalStateModel } from '../../../../store/state.model';
import { MobileAppSettings } from '../../../../vendors/directus/interfaces/mobile-app-settings.interface';
import { GooglePass } from '../../../../interfaces/google-pass';
import { Browser } from '@capacitor/browser';
import { HttpClient } from '@angular/common/http';
import { CapacitorPassToWallet } from 'capacitor-pass-to-wallet';

@Component({
  selector: 'app-pay-in-store',
  templateUrl: './pay-in-store.page.html',
  styleUrls: ['./pay-in-store.page.scss'],
})
// tslint:disable-next-line:component-class-suffix
export class PayInStorePage implements OnInit {
  @Select(state => state.app.mainSettings)
  mainSettings$: Observable<MainSettings>;
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select(state => state.user.checkInCode)
  checkInCode$: Observable<CheckInCode>;
  @Select(state => state.user.quickCode)
  quickCode$: Observable<CheckInCode>;
  @Select(state => state.app.textField) textFields$: Observable<TextField>;
  @Select((state: GlobalStateModel) => state.app.mobileAppSettings) mobileAppSettings$: Observable<MobileAppSettings>;
  @Select((state: GlobalStateModel) => state.user.applePassbookURL) applePassbookURL$: Observable<string>;
  @Select((state: GlobalStateModel) => state.user.googleWalletData) googleWalletData$: Observable<GooglePass>;

  @Input() displayUser: User;
  @Input() displayOrder: Order;
  @Input() displayLoc: Location;
  @Input() isCheckin: boolean;
  @Output() qrEmitter = new EventEmitter<any>();

  scanInStoreCopy = 'Scan In Restaurant To Earn Points Now';

  messageMapping: { [k: string]: string } = {
    '=0': 'less than 1 day.',
    '=1': 'day.',
    other: 'days',
  };
  exp;
  couponCode = false;
  offerPublicName;
  offerPublicDescription;
  offerPublicRestriction;
  applying = false;

  hasDevices: boolean;
  hasPermission: boolean;
  qrResultString: string;
  isLoading = false;

  scannerEnabled = false;
  showScannerButton = true;

  displayMode = CodeDisplayMode.qr;
  displayModes = CodeDisplayMode; // For use in template

  currentBarcode: string;
  barcodes: string[] = []; // To make sure that the scan is accurate

  enableQR = false;
  canScan = false;
  changeCode = false;
  selectedDeviceID: string;

  barcodeForm = new UntypedFormGroup({
    manualBarcode: new UntypedFormControl(),
  });

  results;

  scanning = false;

  checkedIn;

  prepare = () => {
    BarcodeScanner.prepare();
  };

  startScan = async () => {
    BarcodeScanner.hideBackground();
    document.documentElement.style.visibility = 'hidden';
    document.body.style.background = 'transparent';
    this.scanning = true;
    // tslint:disable-next-line:max-line-length
    const result = await BarcodeScanner.startScan({
      targetedFormats: [SupportedFormat.QR_CODE, SupportedFormat.CODE_128, SupportedFormat.EAN_13],
    });
    if (result.hasContent) {
      this.scanSuccessHandler(result.content);
      this.stopScan();
    } else {
      console.log(result);
    }
  };

  stopScan = () => {
    this.scanning = false;
    document.documentElement.style.visibility = 'visible';
    document.body.style.backgroundColor = '#fff';
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
  };

  checkPermission = async () => {
    const status = await BarcodeScanner.checkPermission({ force: true });
    if (status.granted) {
      this.canScan = true;
      this.prepare();
    }
  };

  constructor(
    private modalController: ModalController,
    private navCtrl: NavController,
    private directus: DirectusService,
    private alertCtrl: AlertController,
    private toast: ToastService,
    private store: Store,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.shouldEnableQR();
    this.setDisplayMode();
    this.setupAddToWallet();
  }

  setupAddToWallet() {
    switch (Capacitor.getPlatform()) {
      case 'ios':
        this.store.dispatch(new SetApplePassbookURL());
        break;
      case 'android':
        this.store.dispatch(new SetGoogleWalletData());
        break;
      default:
        this.store.dispatch([new SetApplePassbookURL(), new SetGoogleWalletData()]);
        break;
    }
  }

  setDisplayMode() {
    this.vendorSetup$.subscribe(vs => {
      switch (vs.loyalty_provider) {
        case 'personica':
          this.directus.getPersonicaSettings().subscribe(res => {
            if (res.display_codes_as === 'qrcode') {
              this.displayMode = CodeDisplayMode.qr;
            } else if (res.display_codes_as === 'barcode') {
              this.displayMode = CodeDisplayMode.barcode;
            } else {
              this.displayMode = CodeDisplayMode.none;
            }
          });
          break;
        case 'punchh-olo':
          this.directus.getPunchhSettings().subscribe(res => {
            if (res.display_codes_as.toLowerCase().replace(' ', '') === 'qrcode') {
              this.displayMode = CodeDisplayMode.qr;
            } else if (res.display_codes_as.toLowerCase().replace(' ', '') === 'barcode') {
              this.displayMode = CodeDisplayMode.barcode;
            } else {
              this.displayMode = CodeDisplayMode.none;
            }
          });
          break;
        case 'paytronix':
          // if (this.isCheckin) {
          //   this.checkInCode$.subscribe(code => {
          //     if (!code || moment(code.expirationDate).isBefore(moment())) {
          //       this.store.dispatch(new UserCheckIn()).subscribe(
          //         // eslint-disable-next-line @typescript-eslint/no-empty-function
          //         () => {},
          //         error => {
          //           this.toast.danger(error);
          //           this.dismiss();
          //         }
          //       );
          //     }
          //   });
          // } else
          {
            this.directus.getPaytronixSettings().subscribe(res => {
              if (Object.keys(res).includes('show_code_as_barcode')) {
                this.displayMode = res.show_code_as_barcode ? CodeDisplayMode.barcode : CodeDisplayMode.qr;
              } else {
                this.displayMode = CodeDisplayMode.barcode;
              }

              if (Object.keys(res).includes('prevent_code_toggle')) {
                this.changeCode = res.prevent_code_toggle;
              } else {
                this.changeCode = !res.prevent_code_toggle;
              }

              if (res.enable_checkin_in_store) {
                this.generateQuickCode();
              }
            });
          }
          break;
        case 'spendgo':
          this.directus.getSpendgoSettings().subscribe(res => {
            this.displayMode = res.show_code_as_barcode ? CodeDisplayMode.barcode : CodeDisplayMode.qr;
          });
          break;
        // this.store.dispatch()
      }
    });
  }

  shouldEnableQR() {
    this.mainSettings$.pipe(filter(ms => ms !== null)).subscribe(config => {
      this.enableQR = config.enable_qr_scanner;
      if (Capacitor.getPlatform() !== 'web' && this.enableQR) {
        // this.canScan = true;
        this.checkPermission();
      }
    });
  }

  scanSuccessHandler(qr: any) {
    if (qr) {
      this.qrEmitter.emit(qr);
      this.disableScanner();
      this.dismiss();
    }
  }

  manuallySubmitBarcode() {
    const qr = this.barcodeForm.get('manualBarcode').value;
    if (qr) {
      this.qrEmitter.emit(qr);
    } else {
      this.toast.showDismissableIonicToast('Please enter in code', 'de-ionic-warning-toast', 8000);
    }
  }

  enableScanner() {
    this.startScan();
  }

  disableScanner() {
    this.scannerEnabled = false;
  }

  dismiss() {
    this.modalController.dismiss();
  }

  async presentAlertLocation() {
    const alert = await this.alertCtrl.create({
      header: 'No Location Found',
      message: 'Please choose a restaurant location before applying a coupon.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel: blah');
          },
        },
        {
          text: 'View Locations',
          handler: () => {
            this.navCtrl.navigateForward('/locations');
            this.modalController.dismiss();
          },
        },
      ],
    });
    await alert.present();
  }

  applyCoupon() {
    if (!this.displayLoc) {
      this.presentAlertLocation();
      return;
    }
    this.applying = true;
  }

  switchDisplayMode() {
    switch (this.displayMode) {
      case CodeDisplayMode.qr:
        this.displayMode = CodeDisplayMode.barcode;
        return;
      case CodeDisplayMode.barcode:
        this.displayMode = CodeDisplayMode.qr;
        return;
      default:
        this.displayMode = CodeDisplayMode.none;
        return;
    }
  }

  openInstructions() {
    this.modalController
      .create({
        component: LoyaltyInstructionsModalComponent,
        showBackdrop: true,
      })
      .then(modal => modal.present());
  }

  close(): void {
    this.modalController.dismiss();
  }

  googlePaySuccessCallback() {
    const googlePass = this.store.selectSnapshot((state: GlobalStateModel) => state.user.googleWalletData);
    this.store.dispatch(new SetGoogleWalletSaved(googlePass.code));
  }

  async addToAppleWalletClick(url: string) {
    if (Capacitor.getPlatform() === 'ios') {
      const blobRes = await this.http.get(url, { responseType: 'blob' }).toPromise();
      // convert blob to base64
      const reader = new FileReader();
      reader.readAsDataURL(blobRes);
      reader.onloadend = () => {
        const base64data = reader.result;
        CapacitorPassToWallet.addToWallet({ base64: base64data.toString() });
      };
    } else {
      await Browser.open({ url: url });
    }
  }

  generateQuickCode() {
    this.isLoading = true;
    this.store.dispatch(new CreateQuickCode()).subscribe(
      () => {
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
        this.toast.danger(error);
      }
    );
  }
}
