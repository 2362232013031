<div class="w-100 mb-2">
  <h5 class="option-group-name header-font text-start m-0 mb-2" *ngIf="textField$ | async as textFields">
    {{ textFields.recommended_deals_copy ?? 'Recommended Deals' }}
  </h5>
  <div class="w-100 d-flex flex-row flex-nowrap gap-3 overflow-auto">
    <ng-container *ngFor="let recommendedDeal of recommendedDeals;">
      <app-recommended-deal-card [recommendedDeal]="recommendedDeal"></app-recommended-deal-card>
    </ng-container>
  </div>
</div>
