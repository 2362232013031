<ng-container
  *ngIf="
    displayMenu &&
    displayMenu.categories &&
    displayMenu.categories.length > 0 &&
    displayTheme
  "
>
  <ng-container *ngIf="navbarSettings$ | async as navbarSettings">
    <div
      class="w-100 mx-0 mx-lg-auto"
      style="margin-top: 4rem; margin-bottom: 4rem"
      [class.container]="navbarSettings.fixed_navbar_width"
      [class.px-lg-3]="!navbarSettings.fixed_navbar_width"
      [@fadeInOut]
    >
      <div class="w-100 mb-3 d-flex justify-content-between align-items-center" [class.px-3]="mobile.isMobile">
        <div>
          <h2 class="text-start fw-bolder header-font m-0">Our Menu</h2>
          <ng-container *ngIf="mainSettings">
            <p class="text-start">{{ mainSettings.slogan }}</p>
          </ng-container>
        </div>
      </div>

      <!-- Desktop / Tablet section -->
      <ng-container *ngIf="!isMobile; else mobileCarousel">
        <div class="desktop-menu-grid">
          <ng-container *ngFor="let category of displayMenu.categories; index as index">
            <!-- Only show categories if showAll = true or index < limit -->
            <ng-container *ngIf="showAllCategories || index < initialLimit">
              <div
                class="category-card"
                [@fadeInOut]
                (click)="goToCategory(category)"
              >
                <ng-container
                  *ngIf="
                    category.thumbnailImageURL || category.standardImageURL;
                    else noImage
                  "
                >
                  <img
                    class="cms-card-radius"
                    style="object-fit: cover; aspect-ratio: 1/1;"
                    [alt]="category.name"
                    [src]="category | defaultCategoryImage"
                  />
                </ng-container>
                <ng-template #noImage>
                  <div
                    class="w-100 h-auto img-border-inherit position-relative"
                    [style.background-color]="displayTheme?.card_background_color"
                  >
                    <img
                      src="../../../../assets/img/600x600.png"
                      class="cms-card-radius w-100"
                      [alt]="category.name"
                    />
                    <div
                      class="w-100 text-center fw-bold header-font"
                      style="
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                      "
                      [style.color]="displayTheme?.card_text_color"
                    >
                      {{ category.name | uppercase }}
                    </div>
                  </div>
                </ng-template>

                <ng-container *ngIf="mainSettings.show_category_name">
                  <h5 class="header-font fw-bold text-center mt-2 line-clamp-2 px-3">
                    {{ category.name }}
                  </h5>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>

      <div class="w-100 d-flex justify-content-center mt-3">
        <button
          class="de-primary-btn-outline-sm show-all"
          (click)="toggleShowAll()"
          *ngIf="displayMenu.categories.length > initialLimit"
        >
          {{ showAllCategories ? 'Show Less' : 'Show All' }}
        </button>
      </div>

      <!-- Mobile Carousel (unchanged) -->
      <ng-template #mobileCarousel>
        <ng-container *ngIf="!menuLoading">
          <div
            class="px-3 pb-2 me-3"
            style="display: grid; grid-gap: 16px; overflow-x: scroll; width: 100vw;"
            [style.grid-template-columns]="'repeat(' + displayMenu.categories.length + ', 250px)'"
          >
            <ng-container *ngFor="let category of displayMenu.categories; index as index">
              <div style="width: 250px" [@fadeInOut] class="category-card">
                <div
                  style="
                    display: grid;
                    grid-template-rows: max-content auto;
                    border-radius: var(--cms-card-radius);
                  "
                  (click)="goToCategory(category)"
                >
                  <ng-container
                    *ngIf="
                      category.thumbnailImageURL || category.standardImageURL;
                      else noImage
                    "
                  >
                    <img
                      class="cms-card-radius"
                      style="object-fit: cover; aspect-ratio: 1/1;"
                      [alt]="category.name"
                      [src]="category | defaultCategoryImage"
                    />
                  </ng-container>
                  <ng-template #noImage>
                    <div
                      class="w-100 h-auto cms-card-radius"
                      style="position: relative"
                    >
                      <img
                        src="../../../../assets/img/600x600.png"
                        class="cms-card-radius"
                        [alt]="category.name"
                        [style.background-color]="displayTheme?.card_background_color"
                      />
                      <div
                        class="w-100 text-center fw-bold header-font"
                        style="
                          position: absolute;
                          top: 50%;
                          left: 50%;
                          transform: translate(-50%, -50%);
                        "
                        [style.color]="displayTheme?.card_text_color"
                      >
                        {{ category.name | uppercase }}
                      </div>
                    </div>
                  </ng-template>
                  <ng-container *ngIf="mainSettings.show_category_name">
                    <h5 class="header-font fw-bold text-center mt-2 line-clamp-2 px-3">
                      {{ category.name }}
                    </h5>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-template>
    </div>
  </ng-container>
</ng-container>
