import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { OrderItem, Product } from '../../../../interfaces/product.interface';
import { Store } from '@ngxs/store';
import { UtilityService } from '@modules/utility/services';
import { ToastService } from '../../../../services/toast.service';
import { NavigationService } from '@modules/navigation/services';
import { AddToOrder, CheckUpsell } from '../../../../store/actions/order.actions';
import { GlobalStateModel } from '../../../../store/state.model';
import { Capacitor } from '@capacitor/core';
import { Haptics, NotificationType } from '@capacitor/haptics';
import { Category } from '../../../../interfaces/category.interface';

@Component({
  selector: 'app-recommended-deal-card',
  templateUrl: './recommended-deal-card.component.html',
  styleUrls: ['recommended-deal-card.component.scss'],
})
export class RecommendedDealCardComponent {
  @Input() recommendedDeal: Product;
  isLoading = false;

  constructor(
    private store: Store,
    private utility: UtilityService,
    private toast: ToastService,
    private navigation: NavigationService
  ) {}

  addToOrder() {
    if (this.isLoading) {
      return;
    }
    if (this.recommendedDeal.requiresModification) {
      this.modifyClicked(this.recommendedDeal as Product);
      return;
    }
    this.isLoading = true;
    this.store
      .dispatch(new AddToOrder(this.utility.toOrderItem(this.recommendedDeal as Product, [], 1, null)))
      .toPromise()
      .then((state: GlobalStateModel) => {
        if (Capacitor.getPlatform() !== 'web') {
          Haptics.notification({
            type: NotificationType.Success,
          })
            .then()
            .catch(console.log);
        }
        this.store.dispatch(new CheckUpsell(state.order.order.orderID));
        this.toast.success(`${this.recommendedDeal.name} added to order`);
        this.isLoading = false;
      })
      .catch(error => {
        document.getElementById('scroll-section').scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
        this.isLoading = false;
        if (typeof error === 'string') {
          this.toast.danger(error);
        } else {
          this.toast.danger(error.error.message);
        }
      });
  }

  modifyClicked(product: Product) {
    const order = this.store.selectSnapshot((state: GlobalStateModel) => state.order.order);
    const menu = this.store.selectSnapshot((state: GlobalStateModel) => state.menu.menu);
    const itemCat = this.findCategoryByProductID(product, menu.categories);
    const productSlug = product.nameSlug ? product.nameSlug : product.productID;
    this.navigation.navigateToMenuPage(
      order.location.slugURL ? order.location.slugURL : order.location.locationID,
      itemCat,
      productSlug,
      'modify'
    );
  }

  private findCategoryByProductID(product: Product | OrderItem, categories: Category[]): string {
    let productCategory;
    categories.forEach(cat => {
      if (cat.products.find((prod: any) => prod.productID === product.productID)) {
        productCategory = cat.nameSlug ? cat.nameSlug : cat.categoryID;
      }
    });
    return productCategory;
  }
}
