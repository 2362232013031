import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { distinctUntilChanged, distinctUntilKeyChanged, filter, switchMap, take } from 'rxjs/operators';
import { PaytronixProviderService } from '../../../vendors/paytronix/paytronix-provider.service';
import { UserService } from '../../../services/vendor-config-service/user.service';
import { OrderService } from '../../../services/vendor-config-service/order.service';
import { OLOProviderService } from '../../../vendors/olo/olo-provider.service';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import {
  CreateAccount,
  LogOut,
  SendPasswordResetCode,
  SendVerificationEmail,
  SignIn,
  SignInWithApple as SignInWithAppleStateAction,
  SignInWithFacebook,
  SignInWithToken,
} from '../../../store/actions/user.actions';
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import config from '../../../../capacitor.config';
import { NavigationService } from '@modules/navigation/services';
import { Select, Store } from '@ngxs/store';
import { CapacitorIntegrationService, SentryService, TrackingService } from '@common/services';
import { MainSettings } from '../../../vendors/directus/interfaces/main-settings.interface';
import { DOCUMENT } from '@angular/common';
import { Device } from '@capacitor/device';
import { ErrorService } from '../../../services/error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CreateAccount as NewAccount } from '../../../interfaces/create-account.interface';
import { VendorSetup } from '../../../interfaces/vendor.interface';
import { DirectusService } from '../../../vendors/directus/directus.service';
import { User } from '../../../interfaces/user.interface';
import { OloConfiguration } from '../../../vendors/directus/interfaces/olo-configuration.interface';
import { UserField } from '../../../interfaces/user-field';
import { BiometricAuth, BiometryType } from '@aparajita/capacitor-biometric-auth';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { AlertController } from '@ionic/angular';
import { ToastService } from '../../../services/toast.service';
import { Capacitor } from '@capacitor/core';
import { UserLogInResponse } from '../../../vendors/paytronix/interfaces/user-log-in-response.model';
import VendorConfig from '../../../vendors/config/vendor.config';
import { GlobalStateModel } from '../../../store/state.model';
import { Preferences } from '@capacitor/preferences';
import moment from 'moment-timezone';
import { DineEngineError } from '../../../interfaces/dineengine-error.interface';

@Injectable()
export class AuthService {
  @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;
  @Select(state => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select(state => state.user.user) user$: Observable<User>;
  @Select(state => state.user.thirdPartyWrapped) thirdPartyWrapped$: Observable<boolean>;

  private hideLoginOverlaySubject = new BehaviorSubject<boolean>(null);
  hideLoginOverlay$ = this.hideLoginOverlaySubject.asObservable();

  signInWithAppleOptions: SignInWithAppleOptions = {
    clientId: config.appId,
    redirectURI: config.server.hostname + '/login',
    scopes: 'name email',
    state: '12345',
  };

  vendorSetup: VendorSetup;

  user: User = null;
  loggedIn = false;
  showFacebookLogin = false;
  enableAppleSignIn = false; // Checks Main Settings
  showAppleSignIn = false; // Checks Platform
  isCheckin = false;
  isThirdPartyWrapped = false;
  isWrapped = false;

  errorMessage = '';
  errorIsEmailVerification = false;

  forgotPasswordSuccess = false;

  isLoading = false;
  codeLoading = false;
  facebookLoading = false;
  appleLoading = false;

  biometryType: BiometryType = BiometryType.none;

  constructor(
    // tslint:disable-next-line:variable-name
    @Inject(DOCUMENT) private _document: Document,
    private store: Store,
    private navigation: NavigationService,
    private capacitorIntegration: CapacitorIntegrationService,
    private sentry: SentryService,
    private userService: UserService,
    private orderService: OrderService,
    private errorService: ErrorService,
    private tracking: TrackingService,
    private directus: DirectusService,
    private alertController: AlertController,
    private toast: ToastService
  ) {
    // Device.getInfo().then(info => {
    //   // console.log(info)
    //   // Modals.alert({ title: 'Device Info', message: JSON.stringify(info) })
    //   this.showAppleSignIn = info.platform === 'ios' && info.platform === 'ios' && parseInt(info.osVersion, 10) > 13;
    //   // Toast.show({ text: String(this.showAppleSignIn), position: 'top', duration: 'long' })
    // });
    Device.getInfo().then(info => {
      this.showAppleSignIn = info.platform === 'ios' && info.platform === 'ios' && parseInt(info.osVersion, 10) > 13;
    });
    // this.orderService.getService().subscribe(oService => {
    //   if (oService instanceof OLOProviderService) {
    //     this.userService.getService().subscribe(uService => {
    //       uService
    //         .getSSOLoginSubject()
    //         .pipe(
    //           filter(sso => sso && sso.token !== null),
    //           take(1),
    //           distinctUntilKeyChanged('token')
    //         )
    //         .subscribe(token => {
    //           if (!token) {
    //             return;
    //           }
    //           if (uService.providerName === VendorConfig.paytronix) {
    //             uService.getOloSSOToken<UserLogInResponse>(token.token).subscribe(t => {
    //               token.token = t.access_token;
    //               oService.completeSSOLogin(token).subscribe(
    //                 () => {},
    //                 () => {}
    //               );
    //             });
    //           } else {
    //             oService.completeSSOLogin(token).subscribe(
    //               () => {},
    //               () => {}
    //             );
    //           }
    //         });
    //     });
    //   }
    // });
    this.mainSettings$.pipe(filter(ms => !!ms)).subscribe(ms => {
      this.enableAppleSignIn = ms.enable_sign_in_with_apple;
      if (ms.fb_app_id) {
        this.showFacebookLogin = true;
        if (_document.referrer && _document.referrer.includes('facebook')) {
          this.facebookLogin();
        }
      }
    });
    this.userService.getService().subscribe(uService => {
      uService.isOauthEnabled().subscribe(isEnabled => {
        if (isEnabled) {
          uService.redirectToOauthPage();
        } else {
          this.hideLoginOverlay(true);
        }
      });
    });
    this.vendorSetup$.subscribe(vendorSetup => {
      if (vendorSetup) {
        this.vendorSetup = vendorSetup;
        if (vendorSetup.loyalty_provider === 'paytronix') {
          this.directus.getPaytronixSettings().subscribe(ptxConfig => {
            this.isCheckin = !!ptxConfig.enable_checkin_in_store;
          });
        }
      }
    });
    this.user$.pipe(filter(u => !!u)).subscribe(user => {
      this.user = user;
      if (user.userID) {
        this.loggedIn = true;
      }
    });
    this.thirdPartyWrapped$.subscribe(thirdParty => {
      this.isThirdPartyWrapped = !!thirdParty;
    });
    this.store
      .select((state: GlobalStateModel) => state.app.oloConfig)
      .pipe(filter(oc => !!oc))
      .subscribe((oloConfig: OloConfiguration) => {
        this.isWrapped = oloConfig.wrap_olo_serve;
      });
  }

  getAuth$(): Observable<{}> {
    return of({});
  }

  refreshSSO() {
    return this.orderService.getService().pipe(
      switchMap(oService => {
        if (oService instanceof OLOProviderService) {
          return this.userService.getService().pipe(
            switchMap(uService => {
              return uService.getSSOLoginSubject().pipe(
                filter(sso => sso && sso.token !== null),
                take(1),
                switchMap(token => {
                  if (!token) {
                    return of(null);
                  }
                  if (uService instanceof PaytronixProviderService) {
                    return uService.getAuthToken().pipe(
                      switchMap(auth => {
                        return uService.getOloSSOToken<UserLogInResponse>(auth.access_token).pipe(
                          switchMap(t => {
                            // token.token = t.access_token;
                            return oService.completeSSOLogin({ ...token, token: t.access_token });
                          })
                        );
                      })
                    );
                  } else {
                    return oService.completeSSOLogin(token);
                  }
                })
              );
            })
          );
        }
      })
    );
  }

  signInWithUsernameAndPassword(username: string, password: string, rememberMe: boolean) {
    this.resetErrorMessage();
    this.store
      .dispatch(new SignIn(username, password))
      .toPromise()
      .then(async (state: any) => {
        if (Capacitor.getPlatform() !== 'web') {
          const biometricsAvailable = await BiometricAuth.checkBiometry();
          const deniedDate = await Preferences.get({ key: 'auth.biometrics.denied' });
          if (
            biometricsAvailable.isAvailable &&
            !(await SecureStoragePlugin.keys()).value.includes('auth.biometrics') &&
            (!deniedDate.value || moment(deniedDate.value).isBefore(moment()))
          ) {
            let biometricType = 'biometrics';
            this.biometryType = biometricsAvailable.biometryType;
            switch (biometricsAvailable.biometryType) {
              case BiometryType.touchId:
                biometricType = 'Touch ID';
                break;
              case BiometryType.faceId:
                biometricType = 'Face ID';
                break;
              case BiometryType.fingerprintAuthentication:
                biometricType = 'your fingerprint';
                break;
              case BiometryType.faceAuthentication:
                biometricType = 'your face';
                break;
              case BiometryType.irisAuthentication:
                biometricType = 'your iris';
                break;
            }
            const biometricsAlert = await this.alertController.create({
              mode: Capacitor.getPlatform() === 'ios' ? 'ios' : 'md',
              message: `Would you like to use ${biometricType} to sign in next time?`,
              buttons: [
                {
                  text: 'No',
                  role: 'cancel',
                  cssClass: 'secondary',
                  handler: async () => {
                    if (rememberMe) {
                      await SecureStoragePlugin.set({ key: 'auth.remember', value: 'true' });
                      await SecureStoragePlugin.set({ key: 'auth.username', value: username });
                      await SecureStoragePlugin.set({ key: 'auth.password', value: password });
                    } else {
                      await SecureStoragePlugin.remove({ key: 'auth.remember' });
                      await SecureStoragePlugin.remove({ key: 'auth.username' });
                      await SecureStoragePlugin.remove({ key: 'auth.password' });
                    }
                    await Preferences.set({ key: 'auth.biometrics.denied', value: moment().add(1, 'month').toISOString() });
                  },
                },
                {
                  text: 'Yes',
                  handler: async () => {
                    try {
                      await BiometricAuth.authenticate();
                      await SecureStoragePlugin.set({ key: 'auth.biometrics', value: 'true' });
                      await SecureStoragePlugin.set({ key: 'auth.username', value: username });
                      await SecureStoragePlugin.set({ key: 'auth.password', value: password });
                      // await this.finishLogin()
                    } catch (e) {
                      if (rememberMe) {
                        await SecureStoragePlugin.set({ key: 'auth.remember', value: 'true' });
                        await SecureStoragePlugin.set({ key: 'auth.username', value: username });
                        await SecureStoragePlugin.set({ key: 'auth.password', value: password });
                      } else {
                        await SecureStoragePlugin.remove({ key: 'auth.remember' });
                        await SecureStoragePlugin.remove({ key: 'auth.username' });
                        await SecureStoragePlugin.remove({ key: 'auth.password' });
                      }
                      // await this.finishLogin()
                    }
                  },
                },
              ],
            });
            await biometricsAlert.present();
          } else if (rememberMe) {
            await SecureStoragePlugin.set({ key: 'auth.remember', value: 'true' });
            await SecureStoragePlugin.set({ key: 'auth.username', value: username });
            await SecureStoragePlugin.set({ key: 'auth.password', value: password });
          } else {
            await SecureStoragePlugin.remove({ key: 'auth.remember' });
            await SecureStoragePlugin.remove({ key: 'auth.username' });
            await SecureStoragePlugin.remove({ key: 'auth.password' });
          }
        }
        this.tracking.userInitialization(state.user, this.vendorSetup.customer_tracking_provider);
        this.isLoading = false;
        sessionStorage.removeItem('recaptchaToken');
      })
      .catch(er => {
        console.log(er);
        if (er.message) {
          this.resetErrorMessage();
          this.errorMessage = this.errorService.loginError(er as HttpErrorResponse);
          if (er.name && er.name.includes('Email Verification')) {
            this.errorIsEmailVerification = true;
          }
        }
        this.isLoading = false;
      });
  }

  signUpWithUsernameAndPassword(account: NewAccount, additionalFields: UserField[]) {
    this.isLoading = true;
    this.resetErrorMessage();
    this.store
      .dispatch(new CreateAccount(account, additionalFields))
      .toPromise()
      .then((state: any) => {
        this.tracking.userInitialization(state.user, this.vendorSetup.customer_tracking_provider);
        this.isLoading = false;
      })
      .catch(er => {
        if (er.message) {
          this.resetErrorMessage();
          this.errorMessage = this.errorService.registerError(er as HttpErrorResponse);
          if (er.name && er.name.includes('Email Verification')) {
            this.errorIsEmailVerification = true;
          }
        }
        this.isLoading = false;
      });
  }

  forgotPasswordWithEmail(emailAddress: string) {
    this.resetErrorMessage();
    this.store.dispatch(new SendPasswordResetCode(emailAddress)).subscribe(
      () => {
        this.forgotPasswordSuccess = true;
        this.isLoading = false;
      },
      error => {
        if (error.message) {
          this.resetErrorMessage();
          this.errorMessage = error.message;
          this.isLoading = false;
        }
      }
    );
  }

  signInWithToken(code: string, redirectURI) {
    this.isLoading = true;
    this.codeLoading = true;
    this.store.dispatch(new SignInWithToken(code, redirectURI)).subscribe(
      (state: any) => {
        this.tracking.userInitialization(state.user, this.vendorSetup.customer_tracking_provider);
        this.isLoading = false;
      },
      er => {
        this.resetErrorMessage();
        this.errorMessage = this.errorService.loginError(er);
        this.isLoading = false;
        this.codeLoading = false;
      }
    );
  }

  facebookLogin() {
    this.resetErrorMessage();
    this.facebookLoading = true;
    const FACEBOOK_PERMISSIONS = ['email'];
    FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS })
      .then(result => {
        FacebookLogin.getCurrentAccessToken()
          .then(result2 => {
            FacebookLogin.getProfile<{ email: string }>({ fields: ['email'] })
              .then(result3 => {
                if (result2.accessToken.userId && result2.accessToken.token && result3.email) {
                  // tslint:disable-next-line:max-line-length
                  this.store
                    .dispatch(new SignInWithFacebook(result3.email, result2.accessToken.token, result2.accessToken.userId))
                    .toPromise()
                    .then((state: any) => {
                      this.capacitorIntegration.setRadarUserID(state.user.userID);
                      this.sentry.setEmail(state.user.email);
                      this.navigation
                        .navigateToProfilePage()
                        .then(() => {
                          this.facebookLoading = false;
                        })
                        .catch(error => {
                          this.facebookLoading = false;
                          console.log(error);
                          this.resetErrorMessage();
                          this.errorMessage = error;
                        });
                    })
                    .catch(error => {
                      this.facebookLoading = false;
                      console.log(error);
                      this.resetErrorMessage();
                      this.errorMessage = error;
                    });
                } else {
                  this.resetErrorMessage();
                  this.errorMessage = 'There was an error retrieving your info from Facebook. Please try again later.';
                  this.facebookLoading = false;
                }
              })
              .catch(error => {
                this.facebookLoading = false;
                console.log(error);
                this.resetErrorMessage();
                this.errorMessage = 'Facebook Sign In Error';
              });
          })
          .catch(error => {
            this.facebookLoading = false;
            console.log(error);
            this.resetErrorMessage();
            this.errorMessage = 'Facebook Sign In Error';
          });
      })
      .catch(result => {
        console.error(result);
        this.resetErrorMessage();
        this.errorMessage = 'Facebook Sign In Error';
        this.facebookLoading = false;
      });
  }

  initFacebook() {
    this.facebookLoading = true;
    FacebookLogin.getCurrentAccessToken()
      .then(result => {
        if (result.accessToken && result.accessToken.userId && result.accessToken.token) {
          FacebookLogin.getProfile<{ email: string }>({ fields: ['email'] })
            .then(result2 => {
              if (result2.email) {
                // tslint:disable-next-line:max-line-length
                this.store
                  .dispatch(new SignInWithFacebook(result2.email, result.accessToken.token, result.accessToken.userId))
                  .toPromise()
                  .then(() => {
                    this.navigation.navigateToProfilePage().then(() => (this.facebookLoading = false));
                  })
                  .catch(() => (this.facebookLoading = false));
              } else {
                this.facebookLoading = false;
              }
            })
            .catch(() => (this.facebookLoading = false));
        } else {
          this.facebookLoading = false;
        }
      })
      .catch(() => (this.facebookLoading = false));
  }

  signInWithApple() {
    this.appleLoading = true;
    SignInWithApple.authorize(this.signInWithAppleOptions)
      .then((result: SignInWithAppleResponse) => {
        // tslint:disable-next-line:max-line-length
        this.store
          .dispatch(new SignInWithAppleStateAction(result, this.signInWithAppleOptions.redirectURI))
          .toPromise()
          .then((state: any) => {
            this.capacitorIntegration.setRadarUserID(state.user.userID);
            this.sentry.setEmail(state.user.email);
            this.appleLoading = false;
          })
          .catch(error => {
            console.log(error);
            this.resetErrorMessage();
            this.errorMessage =
              'There was an error signing you in with Apple. If you already have an account with the email that is connected to your Apple ID, please connect the accounts in your profile after logging in.';
            this.appleLoading = false;
          });
      })
      .catch(error => {
        console.log(error);
        this.appleLoading = false;
      });
  }

  logOut() {
    this.logOutOfFacbook();
    this.store
      .dispatch(new LogOut())
      .toPromise()
      .then(() => {
        this.navigation.navigateToLoginPage();
      });
  }

  logOutOfFacbook() {
    FacebookLogin.getCurrentAccessToken()
      .then(result => {
        if (result.accessToken) {
          FacebookLogin.logout();
        }
      })
      .catch(() => {});
  }

  hideLoginOverlay(hide = false) {
    this.hideLoginOverlaySubject.next(hide);
  }

  async resendVerificationEmail(email: string) {
    try {
      await this.store.dispatch(new SendVerificationEmail(email)).toPromise();
      this.resetErrorMessage();
      this.errorMessage = 'Verification email sent';
      this.errorIsEmailVerification = true;
    } catch (e) {
      this.resetErrorMessage();
      this.errorMessage = 'There was an error sending the verification email. Please try again later.';
    }
  }

  private resetErrorMessage() {
    this.errorMessage = '';
    this.errorIsEmailVerification = false;
  }
}
