import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { GlobalStateModel } from '../../../../store/state.model';
import { Observable } from 'rxjs';
import { MainSettings } from '../../../../vendors/directus/interfaces/main-settings.interface';
import { Location } from '@angular/common';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-saved-addresses',
  templateUrl: './saved-addresses.component.html',
  styleUrls: ['saved-addresses.component.scss'],
})
export class SavedAddressesComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.app.mainSettings) mainSettings$: Observable<MainSettings>;

  Capacitor = Capacitor;

  constructor(public location: Location) {}
  ngOnInit() {}
}
