import { Injectable, Injector } from '@angular/core';
import { combineLatest, from, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, toArray } from 'rxjs/operators';
import { NovaDineAPIService } from './novadine-api.service';
import { NovaDineMappingService } from './novadine-mapping.service';
import { UserProvider } from 'src/providers/user-provider.interface';
import { OrderProvider } from 'src/providers/order-provider.interface';
import { MenuProvider } from 'src/providers/menu-provider.interface';
import { LocationProvider } from 'src/providers/location-provider.interface';
import { HandoffType } from 'src/interfaces/handoff-type.enum';
import { OrderItem, Product, Product as DineEngineProduct } from 'src/interfaces/product.interface';
import { CardDetails } from 'src/interfaces/card-details.interface';
import { User, User as DineEngineUser } from 'src/interfaces/user.interface';
import { Location as DineEngineLocation } from 'src/interfaces/location.interface';
import { Order as DineEngineOrder, Order } from 'src/interfaces/order.interface';
import { Menu, Menu as DineEngineMenu } from 'src/interfaces/menu.interface';
import { UpdateOrderRequest } from './interfaces/update-order-request.interface';
import { CreateBasketRequest } from './interfaces/create-basket-request.interface';
import { Store as NovaDineStore, Store } from './interfaces/store.interface';
import { CustomerRegistrationRequest } from './interfaces/customer-registration-request.interface';
import { UpdateCustomerInfoRequest } from './interfaces/update-customer-info-request.interface';
import { PaymentMethod } from './interfaces/payment-method.interface';
import { CashPaymentRequest, NewCardPaymentRequest, SavedCardPaymentRequest } from './interfaces/payment-request.interface';
import { Address as DineEngineAddress } from 'src/interfaces/address.interface';
import { LoyaltyProvider } from 'src/providers/loyalty-provider.interface';
import { Reward } from 'src/interfaces/reward.interface';
import { RewardsBalances } from 'src/interfaces/rewards-balances.interface';
import { OrderHistoryProvider } from 'src/providers/order-history-provider.interface';
import { SavedCard } from 'src/interfaces/saved-card.interface';
import { ChildItem } from './interfaces/update-order-item.interface';
import { UpdateBasketRequest } from './interfaces/update-basket-request.interface';
import { ImageContentService } from '../directus/content.service';
import { StoresRequest } from './interfaces/stores-request.interface';
import { CustomerAddress } from './interfaces/customer-address.interface';
import { CateringLink } from 'src/interfaces/catering-link.interface';
import { DirectusService } from '../directus/directus.service';
import { HistoryEvent } from 'src/interfaces/history-event.interface';
import { SSOLogin } from 'src/interfaces/sso-login.model';
import { DollarReward } from 'src/interfaces/dollar-reward.interface';
import { UtilityService } from '@modules/utility/services/utility.service';
import { DineEngineError } from 'src/interfaces/dineengine-error.interface';
import { PassResetResponse } from 'src/interfaces/pass-reset-response.interface';
import { CreateAccount } from '../../interfaces/create-account.interface';
import { PaymentTypes } from '../../interfaces/payment-types.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { CheckInCode } from '../../interfaces/check-in-code.interface';
import { Preferences as Storage } from '@capacitor/preferences';
import { PurchaseableReward, Variation } from '../../interfaces/purchaseable-reward.interface';
import { GiftCard } from '../../interfaces/giftcard.interface';
import { GroupOrder } from '../../interfaces/group-order.interface';
import { LocalStorageKey } from '../../models/common.enum';
import { PaytronixProviderService } from '../paytronix/paytronix-provider.service';
import moment, { Moment, now } from 'moment-timezone';
import { InboxMessage } from '../../interfaces/inbox-message.interface';
import { Referral } from '../../interfaces/referral.interface';
import { GooglePass } from '../../interfaces/google-pass';
import VendorConfig from '../config/vendor.config';
import { Select } from '@ngxs/store';
import { GlobalStateModel } from '../../store/state.model';
import { MainSettings } from '../directus/interfaces/main-settings.interface';

@Injectable({
  providedIn: 'root',
})
// tslint:disable-next-line: max-line-length
export class NovaDineProviderService
  implements LocationProvider, MenuProvider, OrderProvider, UserProvider, LoyaltyProvider, OrderHistoryProvider
{
  @Select((state: GlobalStateModel) => state.app.mainSettings) mainSettings$: Observable<MainSettings>;
  private userKey = LocalStorageKey.NOVADINE_USER_ID;
  private orderKey = 'NovaDineOrderKey';
  private maintainMenuCacheMs = 15 * 60 * 1000; // 15 minutes
  private cachedMenus: {
    restaurantID: string;
    handoffType: HandoffType;
    menu: DineEngineMenu;
    cacheDate: Date;
  }[] = [];
  private simpleStoreCache: Store[] = [];
  providerName = VendorConfig.novadine;

  constructor(
    private utils: UtilityService,
    private novadineApi: NovaDineAPIService,
    private mapping: NovaDineMappingService,
    private contentService: ImageContentService,
    private directusService: DirectusService,
    private injector: Injector
  ) {}

  clearGroupOrder(): Observable<GroupOrder> {
    throw new Error('Method not implemented.');
  }

  setManualFire(orderID: string | number): Observable<DineEngineOrder> {
    return this.getCurrentOrder(null);
  }

  getResetPasswordCode(email: string): Observable<any> {
    return this.forgotPassword(email);
  }

  getSSOLoginSubject(): Observable<SSOLogin> {
    return of(null);
  }

  isOauthEnabled(): Observable<boolean> {
    return of(false);
  }

  redirectToOauthPage() {
    throw new Error('Method not implemented');
  }

  getLocationtableNumber(tableNumber: number, locationID: number): Observable<string> {
    return of(tableNumber.toString());
  }

  cateringLink(): Observable<CateringLink> {
    // This could change when we get a catering integration
    const link: CateringLink = {
      enabled: false,
      link: '',
      linkGuest: '',
      clientId: '',
    };
    return of(link);
  }

  getOrderHistory(userID: string | number): Observable<DineEngineOrder[]> {
    return this.getUserID().pipe(
      switchMap(id => {
        return this.novadineApi.getAllStores().pipe(
          switchMap(stores => {
            return this.novadineApi
              .getPastOrders(id, {
                includeItemDetails: true,
                onlySavedOrders: true,
              })
              .pipe(
                map(orders => {
                  const filteredOrders = orders.filter(order => stores.find(store => order.restid === store.restaurant_id));
                  const allOrders: Order[] = [];
                  for (let i = 0; i < filteredOrders.length && i < 5; i++) {
                    // tslint:disable-next-line:max-line-length
                    allOrders.push(
                      this.mapping.orderInfoToOrder(
                        filteredOrders[i],
                        stores.find(store => filteredOrders[i].restid === store.restaurant_id)
                      )
                    );
                  }
                  return allOrders;
                })
              );
          })
        );
      })
    );
  }

  // tslint:disable-next-line:max-line-length
  reorderFromHistory(authToken: string, externalOrderRef: string, orderID: string, ignoreUnavailableProducts: boolean): Observable<Order> {
    return this.novadineApi.getOrderDetails(orderID).pipe(
      switchMap(order => {
        console.log(order.restaurant);
        return this.novadineApi.getStore(order.restaurant.store_id).pipe(
          switchMap(store => {
            const readyTime = this.getASAPReadyTime(store, order.order.service_type_id);
            const body = {
              customer_id: order.customer.customer_id.toString(),
              ready_ts: this.mapping.toOrderDateString(readyTime),
              store_id: order.restaurant.store_id,
              service_type_id: order.order.service_type_id,
              base_order_id: order.order.order_id.toString(),
            };
            return this.novadineApi.startOrUpdateOrder(true, body).pipe(
              switchMap(res => {
                this.saveOrderID(res.order.order_id.toString());
                return this.novadineApi.getPaymentMethodsForOrder(res.order.order_id.toString()).pipe(
                  switchMap(paymentMethods => {
                    const slugorder = this.mapping.toOrder(
                      res,
                      store,
                      paymentMethods,
                      res.customer_location ? res.customer_location : null
                    );
                    return this.contentService.getOrderItemsWithSlugsandImages(slugorder);
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  removeCard(card: SavedCard): Observable<SavedCard> {
    return this.getUserID().pipe(
      switchMap(id => {
        return this.novadineApi.removeFromSavedCards(id, card.savedCardID.toString()).pipe(
          map(() => {
            return {
              ...card,
              isRemovable: false,
            };
          })
        );
      })
    );
  }

  saveCardAsDefault(card: SavedCard): Observable<SavedCard> {
    return of(null);
  }

  getSavedCards(userID: string, isAccountPage: boolean): Observable<SavedCard[]> {
    return this.novadineApi.getSavedCards(userID).pipe(
      map(cards => {
        return cards.map(card => this.mapping.toSavedCard(card));
      })
    );
  }

  getPointsBalance(userID: string | number): Observable<RewardsBalances> {
    return this.mainSettings$.pipe(
      filter(settings => !!settings),
      take(1),
      switchMap(settings => {
        return this.novadineApi.retrieveCustomerLoyaltyBalances(String(userID)).pipe(
          map(balances => {
            if (balances.info && balances.info.point_balances?.length) {
              // tslint:disable-next-line:max-line-length
              return this.mapping.retrieveCustomerLoyaltyBalancesResponseToRewardsBalances(
                balances,
                parseInt(settings.loyalty_points_threshold, 10)
              );
            } else {
              return null;
            }
          })
        );
      })
    );
  }

  getOffers(userID: string | number): Observable<any> {
    throw new Error('Method not implemented.');
  }

  getGiftCardBalance(basketGuid: string, cardNumber: string, pin: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  getLoyaltyActivity(): Observable<HistoryEvent[]> {
    return of([]);
  }

  redeemPointsFromScanner(barCode: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  earnPoints(userID: string | number): Observable<any> {
    throw new Error('Method not implemented.');
  }

  getRewards(userID: string | number, locationID: string | number): Observable<Reward[]> {
    return this.novadineApi.retrieveAvailableLoyaltyDiscountsForOrder(this.getOrderID()).pipe(
      map(discounts => {
        // tslint:disable-next-line:max-line-length
        return discounts.discounts
          .filter(discount => discount.discount_code)
          .map(discount => this.mapping.retrieveAvailableDiscountsForOrderDiscountToRewards(discount));
      })
    );
  }

  redeemReward(reward: Reward): Observable<DineEngineOrder> {
    return this.addCoupon(this.getOrderID(), String(reward.rewardID));
  }

  redeemInStoreReward(reward: Reward): Observable<Reward> {
    throw new Error('Method not implemented.');
  }

  redeemBankedPoints(points: number): Observable<Reward> {
    throw new Error('Method not implemented.');
  }

  voidBankedPoints(reward: DollarReward): Observable<any> {
    throw new Error('Method not implemented.');
  }

  removeAppliedReward(reward: Reward): Observable<DineEngineOrder> {
    return this.getUserID().pipe(
      switchMap(id => {
        return this.getCurrentOrder(id).pipe(
          switchMap(order => {
            return this.novadineApi
              .removeCouponFromOrder(order.orderID, {
                customer_id: id,
                discount_id: reward.rewardID,
              })
              .pipe(
                switchMap(() => {
                  return this.getCurrentOrder(id);
                })
              );
          })
        );
      })
    );
  }

  checkInAtStore(): Observable<CheckInCode> {
    return of(null);
  }

  createQuickCode(): Observable<CheckInCode> {
    return of(null);
  }

  getPurchaseableRewards(): Observable<PurchaseableReward[]> {
    return of(null);
  }

  purchaseReward(reward: PurchaseableReward, variation?: Variation): Observable<boolean> {
    return of(false);
  }

  canTransferGiftCardToAccount(): Observable<boolean> {
    return of(false);
  }

  transferGiftCardBalanceToAccount(from: GiftCard): Observable<{ success: boolean }> {
    return throwError(new DineEngineError('Method not implemented'));
  }

  getLoyaltyStoredValueCardInfo(userID: string): Observable<GiftCard> {
    return throwError(new DineEngineError('Method not implemented'));
  }

  notifyOfArrival(orderID: string, extraMessage: string): Observable<DineEngineOrder> {
    throw new Error('Method not implemented.');
  }

  getProviderUpsells(basketGuid: string | number): Observable<any> {
    throw new Error('Method not implemented.');
  }

  addProviderUpsell(basketGuid: string, body: any): Observable<any> {
    throw new Error('Method not implemented.');
  }

  logInWithFacebook(email: string, accessToken: string, userID: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  connectWithFacebook(email: string, accessToken: string, userID: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  logInWithApple(appleResult: SignInWithAppleResponse, redirectURI: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  connectWithApple(appleResult: SignInWithAppleResponse, redirectURI: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  cancelOrder(orderID: string): Observable<Order> {
    throw new Error('Method not implemented.');
  }

  editOrder(orderID: string): Observable<Order> {
    throw new Error('Method not implemented.');
  }

  addCoupon(orderID: string | number, couponCode: string): Observable<DineEngineOrder> {
    return this.novadineApi.getOrderDetails(orderID.toString()).pipe(
      switchMap(order => {
        const body = {
          customer_id: order.customer.customer_id.toString(),
          coupon_code: couponCode,
        };
        return this.novadineApi.addCouponToOrder(orderID.toString(), body).pipe(
          switchMap(res => {
            if (res.errors && res.errors.length) {
              const msg = res.errors.join(', ');
              return throwError(new DineEngineError(msg));
            }
            return this.novadineApi.getStore(res.restaurant.store_id).pipe(
              switchMap(store => {
                return this.novadineApi.getPaymentMethodsForOrder(orderID.toString()).pipe(
                  switchMap(paymentMethods => {
                    const slugOrder = this.mapping.toOrder(
                      res,
                      store,
                      paymentMethods,
                      res.customer_location ? res.customer_location : null
                    );
                    return this.contentService.getOrderItemsWithSlugsandImages(slugOrder);
                  })
                );
              })
            );
          }),
          catchError(err => {
            let msg = err.message;
            if (err instanceof HttpErrorResponse) {
              msg = err.error.error;
            }
            return throwError({ error: { message: msg } });
          })
        );
      })
    );
  }

  removeCoupon(orderID: string): Observable<Order> {
    return this.novadineApi.getOrderDetails(orderID.toString()).pipe(
      switchMap(order => {
        if (order.coupons && order.coupons.length) {
          const coupon = order.coupons[0];
          const params = {
            customer_id: order.customer.customer_id,
            discount_id: coupon.coupon_id,
          };
          return this.novadineApi.removeCouponFromOrder(orderID.toString(), params).pipe(
            switchMap(res => {
              return this.novadineApi.getStore(res.restaurant.store_id).pipe(
                switchMap(store => {
                  return this.novadineApi.getPaymentMethodsForOrder(orderID.toString()).pipe(
                    switchMap(paymentMethods => {
                      const slugOrder = this.mapping.toOrder(
                        res,
                        store,
                        paymentMethods,
                        res.customer_location ? res.customer_location : null
                      );
                      return this.contentService.getOrderItemsWithSlugsandImages(slugOrder);
                    })
                  );
                })
              );
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

  getAvailableOrderDays(locationID: string | number, orderID: string | number): Observable<Date[]> {
    return this.novadineApi.getStore(locationID.toString()).pipe(
      switchMap(restaurant => {
        const days: Date[] = [];
        while (days.length < 7) {
          days.push(moment().add(days.length, 'days').utcOffset(restaurant.timezone_offset_mins, true).startOf('day').toDate());
        }
        return of(days.filter(d => (restaurant.is_open ? true : !moment(d).isSame(moment(), 'day'))));
      })
    );
  }

  getAvailableOrderTimes(locationID: string | number, orderID: string | number, date: Date): Observable<Moment[]> {
    return this.novadineApi.getStore(locationID.toString()).pipe(
      switchMap(restaurant => {
        // get restaurant start time for date
        const startTime = restaurant.hours.find(
          h => h.day_of_week === moment(date).utcOffset(restaurant.timezone_offset_mins, true).weekday()
        ).start_time;
        const startMoment = moment([moment(date).year(), moment(date).dayOfYear(), startTime].join(' '), 'YYYY DDD HH:mm').utcOffset(
          restaurant.timezone_offset_mins,
          true
        );
        // get restaurant end time for date
        const endTime = restaurant.hours.find(
          h => h.day_of_week === moment(date).utcOffset(restaurant.timezone_offset_mins, true).weekday()
        ).end_time;
        const endMoment = moment([moment(date).year(), moment(date).dayOfYear(), endTime].join(' '), 'YYYY DDD HH:mm').utcOffset(
          restaurant.timezone_offset_mins,
          true
        );
        const times: Moment[] = [];
        const start = moment(date).utcOffset(restaurant.timezone_offset_mins, true).startOf('day');
        const end = moment(date).utcOffset(restaurant.timezone_offset_mins, true).endOf('day');
        start.add(15, 'minutes');
        while (start.isBefore(end)) {
          times.push(start);
          start.add(15, 'minutes');
        }
        return of(
          times.filter(
            time =>
              moment(time).isAfter(moment().add(30, 'minutes')) &&
              moment(time).isAfter(moment(startMoment).add(30, 'minutes')) &&
              moment(time).isBefore(moment(endMoment).subtract(30, 'minutes'))
          )
        );
      })
    );
  }

  getLocations(withCalendars?: boolean): Observable<DineEngineLocation[]> {
    return this.novadineApi.getAllStores().pipe(
      switchMap(stores => {
        this.simpleStoreCache = [];
        stores.forEach(store => this.simpleStoreCache.push(store));
        const locations = stores.map(store => this.mapping.toLocation(store, null));
        return combineLatest(locations.map(loc => this.contentService.getLocationWithMapIconURL(loc)));
      })
    );
  }

  getLocation(locationID: string): Observable<DineEngineLocation> {
    const cachedStore = this.simpleStoreCache.find(store => store.store_id === locationID);
    if (cachedStore) {
      const location = this.mapping.toLocation(cachedStore, null);
      return this.contentService.getLocationWithMapIconURL(location);
    } else {
      return this.novadineApi.getStore(locationID).pipe(
        switchMap(store => {
          this.simpleStoreCache.push(store);
          const location = this.mapping.toLocation(store, null);
          console.log(location);
          return this.contentService.getLocationWithMapIconURL(location);
        })
      );
    }
  }

  getLocationBySlug(slugID: string): Observable<DineEngineLocation> {
    return this.directusService.getSingleLocationBySlug(slugID).pipe(
      switchMap(cmsLocation => {
        return this.getLocation(cmsLocation.menu_id);
      })
    );
  }

  getLocationsNear(latitude: number, longitude: number, milesRadius: number, maxResults: number): Observable<DineEngineLocation[]> {
    const request: StoresRequest = {
      latitude,
      longitude,
    };
    return this.novadineApi.getStores(request).pipe(
      switchMap(stores => {
        return combineLatest(stores.map(store => this.contentService.getLocationWithMapIconURL(this.mapping.toLocation(store, null))));
      }),
      catchError(() => {
        return of<DineEngineLocation[]>([]);
      })
    );
  }

  // tslint:disable-next-line: max-line-length
  checkForDelivery(
    restaurantID: string,
    handoffType: HandoffType.delivery | HandoffType.dispatch,
    timeWanted: Date,
    address: DineEngineAddress
  ): Observable<{ canDeliver: boolean; failureReason?: string }> {
    // tslint:disable-next-line:max-line-length
    return this.novadineApi.getStoreDeliveryInfo(restaurantID, address.zipCode, address.address1, address.city, address.state).pipe(
      map(res => {
        return {
          canDeliver: res.success,
          failureReason: res.success ? '' : 'Not in delivery zone',
        };
      })
    );
  }

  // tslint:disable-next-line: max-line-length
  getProduct(
    locationID: string,
    handoffType: HandoffType,
    menuID: string,
    categoryID: string,
    productID: string,
    isProductPageCall: boolean
  ): Observable<DineEngineProduct> {
    if (isNaN(locationID as any)) {
      // URL contains a slug
      return this.directusService.getSingleLocationBySlug(locationID).pipe(
        switchMap(locationInfo => {
          return this.getProduct(locationInfo.menu_id, handoffType, menuID, categoryID, productID, isProductPageCall);
        })
      );
    } else {
      return this.novadineApi.getStoreMenuCategoryItemDetails(locationID, menuID, categoryID, productID).pipe(
        switchMap(details => {
          const product = this.mapping.toProductFromProductDetails(details, menuID);
          return this.contentService.getProductWithImages(product);
        })
      );
    }
    /*  } */
  }

  getRecommendedDealsForProduct(locationID: string | number, handoffType: HandoffType, productID: string | number): Observable<Product[]> {
    return this.directusService.getContentProducts().pipe(
      switchMap(products => {
        return this.getMenuByHandoffType(String(locationID), handoffType).pipe(
          switchMap(menu => {
            const product = this.getProductFromMenu(menu, '', productID.toString());
            if (!product) {
              return of([]);
            } else {
              const contentProduct = products.find(p => p.name.trim() === product.name.trim());
              if (!contentProduct) {
                return of([]);
              } else {
                if (contentProduct.recommended_deals && contentProduct.recommended_deals.length) {
                  // get all of the products that are recommended deals
                  return from(contentProduct.recommended_deals).pipe(
                    switchMap(recommendedDeal => {
                      const category = menu.categories.find(cat =>
                        cat.products.find(prod => prod.name.trim() === recommendedDeal.deal_item_id.name.trim())
                      );
                      if (category) {
                        return of(category.products.find(prod => prod.name.trim() === recommendedDeal.deal_item_id.name.trim()));
                      } else {
                        return of(null);
                      }
                    }),
                    filter(prod => prod !== null),
                    toArray()
                  );
                }
              }
            }
          })
        );
      })
    );
  }

  private getProductFromMenu(menu: Menu, categoryID: string, productID: string): Product {
    const category = menu.categories.find(cat => cat.categoryID === categoryID);
    if (category) {
      return category.products.find(prod => prod.productID.toString() === productID);
    } else {
      let product = null;
      for (const catKey in menu.categories) {
        if (Object.prototype.hasOwnProperty.call(menu.categories, catKey) && !product) {
          product = menu.categories[catKey].products.find(prod => prod.productID.toString() === productID);
        }
      }
      if (product) {
        return product;
      } else {
        return menu.singleUseProducts?.products.find(prod => prod.productID === productID);
      }
    }
  }

  getMenuByHandoffType(locationID: string, handoffType: HandoffType): Observable<DineEngineMenu> {
    if (isNaN(locationID as any)) {
      // URL contains a slug
      return this.directusService.getSingleLocationBySlug(locationID).pipe(
        switchMap(locationInfo => {
          return this.getMenuByHandoffType(locationInfo.menu_id, handoffType);
        })
      );
    } else {
      const cachedMenu = this.getCachedMenu(locationID, handoffType);
      if (cachedMenu) {
        return of(cachedMenu);
      } else {
        const serviceTypeID = this.mapping.toServiceTypeID(handoffType);
        return this.novadineApi.getStoreBaseMenu(locationID, serviceTypeID).pipe(
          switchMap(menus => {
            const menu = this.mapping.toMenu(menus[0]);
            return this.contentService.getMenuWithImages(menu).pipe(tap(m => this.storeCachedMenu(locationID, handoffType, m)));
          })
        );
      }
    }
  }

  getCurrentOrder(userID: string): Observable<DineEngineOrder> {
    const orderID = this.getOrderID();
    if (orderID) {
      return this.novadineApi.getOrderDetails(orderID).pipe(
        switchMap(res => {
          return this.novadineApi.getStore(res.restaurant.store_id).pipe(
            switchMap(store => {
              return this.novadineApi.getPaymentMethodsForOrder(orderID).pipe(
                switchMap(pMethods => {
                  const slugOrder = this.mapping.toOrder(res, store, pMethods, res.customer_location ? res.customer_location : null);
                  return this.contentService.getOrderItemsWithSlugsandImages(slugOrder);
                })
              );
            })
          );
        })
      );
    } else {
      // console.log('getting past orders');
      // return this.novadineApi.getPastOrders(userID, {onlyOpenOrders: true}).pipe(switchMap(orders => {
      //   if (!orders || !orders.length) {
      //     return of(null);
      //   } else {
      //     const existingOrderID = orderID
      //         ? orderID
      //         : orders.sort((a, b) => new Date(a.created).getTime() - new Date(b.created).getTime())[0].orderid.toString();
      //     this.saveOrderID(existingOrderID);
      //     return this.novadineApi.getOrderDetails(existingOrderID).pipe(switchMap(res => {
      //       return this.novadineApi.getStore(res.restaurant.store_id).pipe(switchMap(store => {
      //         return this.novadineApi.getPaymentMethodsForOrder(existingOrderID).pipe(switchMap(paymentMethods => {
      //           const slugOrder = this.mapping.toOrder(res, store, paymentMethods, res.customer_location ? res.customer_location : null);
      //           return this.contentService.getOrderItemsWithSlugsandImages(slugOrder);
      //         }));
      //       }));
      //     }));
      //   }
      // }));
      return of(null);
    }
  }

  clearOrder(): Observable<string> {
    this.removeOrderID();
    return of(this.getOrderID());
  }

  startOrder(userID: string, locationID: string, handoffType: HandoffType, tableNumber: string): Observable<DineEngineOrder> {
    if (isNaN(locationID as any)) {
      // URL contains a slug
      return this.directusService.getSingleLocationBySlug(locationID).pipe(
        switchMap(locationInfo => {
          return this.startOrder(userID, locationInfo.menu_id, handoffType, tableNumber);
        })
      );
    } else {
      const serviceTypeID = this.mapping.toServiceTypeID(HandoffType.pickup);
      return this.novadineApi.getStore(locationID).pipe(
        switchMap(store => {
          const readyTime = this.getASAPReadyTime(store, serviceTypeID);
          const body: CreateBasketRequest = {
            customer_id: userID,
            ready_ts: this.mapping.toOrderDateString(readyTime),
            store_id: locationID,
            service_type_id: serviceTypeID,
            // table_number: tableNumber
          };
          return this.novadineApi.startOrUpdateOrder(false, body).pipe(
            switchMap(res => {
              this.saveOrderID(res.order.order_id.toString());
              return this.novadineApi.getPaymentMethodsForOrder(res.order.order_id.toString()).pipe(
                switchMap(paymentMethods => {
                  const slugorder = this.mapping.toOrder(res, store, paymentMethods, res.customer_location ? res.customer_location : null);
                  return this.contentService.getOrderItemsWithSlugsandImages(slugorder);
                })
              );
            })
          );
        })
      );
    }
  }

  transferBasket(orderID: string, locationID: string): Observable<Order> {
    if (isNaN(locationID as any)) {
      // URL contains a slug
      return this.directusService.getSingleLocationBySlug(locationID).pipe(
        switchMap(locationInfo => {
          return this.transferBasket(orderID, locationInfo.menu_id);
        })
      );
    } else {
      // const serviceTypeID = this.mapping.toServiceTypeID(handoffType);
      return this.novadineApi.getOrderDetails(orderID).pipe(
        switchMap(order => {
          return this.novadineApi.getStore(locationID).pipe(
            switchMap(store => {
              const body = {
                customer_id: order.customer.customer_id,
                ready_ts: order.order.dp_stamp,
                store_id: locationID,
                service_type_id: order.order.service_type_id,
                order_id: order.order.order_id,
                is_time_bump: true,
              };
              return this.novadineApi.startOrUpdateOrder(true, body).pipe(
                switchMap(res => {
                  this.saveOrderID(res.order.order_id.toString());
                  return this.novadineApi.getPaymentMethodsForOrder(res.order.order_id.toString()).pipe(
                    switchMap(paymentMethods => {
                      const slugorder = this.mapping.toOrder(
                        res,
                        store,
                        paymentMethods,
                        res.customer_location ? res.customer_location : null
                      );
                      return this.contentService.getOrderItemsWithSlugsandImages(slugorder);
                    })
                  );
                })
              );
            })
          );
        })
      );
    }
  }

  // tslint:disable-next-line: max-line-length
  setDispatchOrDeliveryAddress(
    orderID: string,
    userID: string,
    address: DineEngineAddress,
    handoffType: HandoffType
  ): Observable<DineEngineOrder> {
    const serviceTypeID = this.mapping.toServiceTypeID(handoffType);
    const request = {
      street_number: address.address1.split(' ')[0],
      address1: address.address1.replace(address.address1.split(' ')[0] + ' ', ''),
      address2: address.address2 ? address.address2 : '',
      city: address.city,
      state_code: address.state,
      postal_code: address.zipCode,
      return_details: 'return',
      service_type_id: serviceTypeID,
    } as CustomerAddress;
    return this.novadineApi.getOrderDetails(orderID.toString()).pipe(
      switchMap(order => {
        return this.novadineApi.getStore(order.restaurant.store_id).pipe(
          switchMap(store => {
            return this.getUserID().pipe(
              switchMap(id => {
                return this.novadineApi.saveCustomerAddress(id, request).pipe(
                  switchMap(res => {
                    const readyTime = this.getASAPReadyTime(store, serviceTypeID);
                    const req = {
                      customer_id: order.order.customer_id,
                      address_id: res.address_id,
                      order_id: order.order.order_id,
                      ready_ts: this.mapping.toOrderDateString(readyTime),
                      store_id: order.restaurant.store_id,
                      service_type_id: serviceTypeID,
                      is_time_bump: true,
                    } as UpdateBasketRequest;
                    return this.novadineApi.startOrUpdateOrder(true, req).pipe(
                      switchMap(orderRes => {
                        this.saveOrderID(orderRes.order.order_id.toString());
                        return this.novadineApi.getPaymentMethodsForOrder(orderRes.order.order_id.toString()).pipe(
                          switchMap(paymentMethods => {
                            const slugOrder = this.mapping.toOrder(
                              orderRes,
                              store,
                              paymentMethods,
                              orderRes.customer_location ? orderRes.customer_location : null
                            );
                            return this.contentService.getOrderItemsWithSlugsandImages(slugOrder);
                          })
                        );
                      })
                    );
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  checkDeliveryStatus(orderGuid: string, authToken: string) {
    throw new Error('Method not implemented');
  }

  getOrderStatus(orderGuid: string): Observable<any> {
    return this.novadineApi.getOrderDetails(orderGuid).pipe(
      switchMap(order => {
        return this.novadineApi.getStore(order.restaurant.store_id).pipe(
          switchMap(store => {
            return this.novadineApi.getPaymentMethodsForOrder(order.order.order_id.toString()).pipe(
              switchMap(paymentMethods => {
                const slugorder = this.mapping.toOrder(
                  order,
                  store,
                  paymentMethods,
                  order.customer_location ? order.customer_location : null
                );
                return this.contentService.getOrderItemsWithSlugsandImages(slugorder);
              })
            );
          })
        );
      })
    );
  }

  validateOrder(userID: string, locationID: string, orderID: string): Observable<any> {
    return this.getCurrentOrder(userID); // TODO
  }

  submitPayment(orderID: string, cardDetails: CardDetails, applyCents: number, shouldSave: boolean, token: string): Observable<Order> {
    return this.getPaymentMethod(orderID, cardDetails).pipe(
      switchMap(pm => {
        let body: NewCardPaymentRequest | SavedCardPaymentRequest | CashPaymentRequest;
        if (cardDetails.paymentType === PaymentTypes.cash) {
          body = <CashPaymentRequest>{
            pay_proc_id: pm ? pm.pay_proc_id : -1,
            payment_cents: applyCents,
          };
        } else if (cardDetails.isSavedCard) {
          body = <SavedCardPaymentRequest>{
            pay_proc_id: pm.pay_proc_id,
            payment_cents: applyCents,
            cc_account_id: cardDetails.savedCardID,
            security_code: cardDetails.cvv,
            payer_authentication: {
              known_providers: ['cardinal'],
            },
          };
        } else {
          body = <NewCardPaymentRequest>{
            pay_proc_id: pm.pay_proc_id,
            payment_cents: applyCents,
            security_code: cardDetails.cvv,
            card_number: cardDetails.cardNumber,
            expiration_month: cardDetails.expirationMonth,
            expiration_year: cardDetails.expirationYear,
            first_name: cardDetails.firstName,
            last_name: cardDetails.lastName,
            company: cardDetails.company,
            address1: cardDetails.billingAddress.address1,
            address2: cardDetails.billingAddress.address2 ? cardDetails.billingAddress.address2 : '#',
            city: cardDetails.billingAddress.city,
            state: cardDetails.billingAddress.state,
            zip_code: cardDetails.billingAddress.zipCode,
            should_save: shouldSave,
            payer_authentication: {
              known_providers: ['cardinal'],
            },
          };
        }
        return this.novadineApi.getPaymentMethodsForOrder(orderID).pipe(
          switchMap(paymentMethods => {
            return this.novadineApi.submitOrderPaymentUsingNewCard(orderID, body).pipe(
              switchMap(info => {
                if (info.error) {
                  return throwError(new DineEngineError(info.error));
                } else {
                  return this.getPaymentStatus(info, orderID, cardDetails, applyCents, token).pipe(
                    switchMap(() => {
                      this.saveOrderID('');
                      return this.novadineApi.getOrderDetails(orderID).pipe(
                        switchMap(order => {
                          return this.novadineApi.getStore(order.restaurant.store_id).pipe(
                            switchMap(store => {
                              const slugorder = this.mapping.toOrder(
                                order,
                                store,
                                paymentMethods,
                                order.customer_location ? order.customer_location : null
                              );
                              return this.contentService.getOrderItemsWithSlugsandImages(slugorder);
                            })
                          );
                        })
                      );
                    })
                  );
                }
              })
            );
          })
        );
      })
    );
  }

  // tslint:disable-next-line:max-line-length
  submitPaymentAsGuest(
    orderID: string,
    cardDetails: CardDetails,
    applyCents: number,
    shouldSave: boolean,
    token: string
  ): Observable<Order> {
    const customerInfo = {
      email: cardDetails.emailAddress,
      first_name: cardDetails.firstName,
      last_name: cardDetails.lastName,
      phone: cardDetails.phoneNumber,
      email_optin: !!cardDetails.guestOptIn,
      password: '',
      auto_login: false,
    } as CustomerRegistrationRequest;
    return this.novadineApi.registerNewCustomer(customerInfo).pipe(
      switchMap(userInfo => {
        return this.saveUserID(userInfo.customer_id.toString()).pipe(
          switchMap(() => {
            return this.submitPayment(orderID, cardDetails, applyCents, shouldSave, token);
          })
        );
      }),
      catchError(err => {
        return throwError(this.mapping.regNewCustErr(err));
      })
    );
  }

  validatePayment(orderID: string, cardDetails: CardDetails): Observable<boolean> {
    return this.getPaymentMethod(orderID, cardDetails).pipe(
      map(pm => {
        return pm !== undefined && pm !== null;
      })
    );
  }

  addToOrder(item: OrderItem, orderID: string, userID: string): Observable<DineEngineOrder> {
    const req = this.getUpdateOrderRequest(item, userID, item.quantity);
    return this.finishAddToOrder(orderID, req);
  }

  removeFromOrder(item: OrderItem, orderID: string, userID: string | number): Observable<DineEngineOrder> {
    return this.novadineApi.removeItemFromOrder(orderID, item.orderItemID).pipe(
      switchMap(orderResponse => {
        return this.novadineApi.getStore(orderResponse.restaurant.store_id).pipe(
          switchMap(store => {
            return this.novadineApi.getPaymentMethodsForOrder(orderID).pipe(
              switchMap(paymentMethods => {
                const slugorder = this.mapping.toOrder(
                  orderResponse,
                  store,
                  paymentMethods,
                  orderResponse.customer_location ? orderResponse.customer_location : null
                );
                return this.contentService.getOrderItemsWithSlugsandImages(slugorder);
              })
            );
          })
        );
      })
    );
  }

  updateBasketItem(item: OrderItem, orderID: string, userID: string, quant: number): Observable<DineEngineOrder> {
    return this.getUserID().pipe(
      switchMap(id => {
        return this.novadineApi.getOrderDetails(orderID).pipe(
          switchMap(order => {
            const req = this.getUpdateOrderRequest(item, String(order.customer.customer_id), quant);
            return this.novadineApi.removeItemFromOrder(orderID, item.orderItemID).pipe(
              switchMap(() => {
                return this.finishAddToOrder(orderID, req);
              })
            );
          })
        );
      })
    );
  }

  private finishAddToOrder(orderID: string, req: UpdateOrderRequest): Observable<DineEngineOrder> {
    return this.novadineApi.updateOrderItems(orderID, req).pipe(
      switchMap(orderResponse => {
        return this.novadineApi.getStore(orderResponse.restaurant.store_id).pipe(
          switchMap(store => {
            return this.novadineApi.getPaymentMethodsForOrder(orderID).pipe(
              switchMap(paymentMethods => {
                const slugorder = this.mapping.toOrder(
                  orderResponse,
                  store,
                  paymentMethods,
                  orderResponse.customer_location ? orderResponse.customer_location : null
                );
                return this.contentService.getOrderItemsWithSlugsandImages(slugorder);
              })
            );
          })
        );
      })
    );
  }

  setTimePreference(orderID: string | number, userID: string | number, time: Date): Observable<DineEngineOrder> {
    return this.novadineApi.getOrderDetails(orderID.toString()).pipe(
      switchMap(order => {
        const req = {
          customer_id: userID,
          order_id: orderID,
          ready_ts: this.mapping.toOrderDateString(time),
          store_id: order.restaurant.store_id,
          service_type_id: order.order.service_type_id,
          is_time_bump: true,
        } as UpdateBasketRequest;
        return this.novadineApi.startOrUpdateOrder(true, req).pipe(
          switchMap(res => {
            return this.novadineApi.getStore(order.restaurant.store_id).pipe(
              switchMap(store => {
                return this.novadineApi.getPaymentMethodsForOrder(orderID.toString()).pipe(
                  switchMap(paymentMethods => {
                    const slugorder = this.mapping.toOrder(
                      res,
                      store,
                      paymentMethods,
                      res.customer_location ? res.customer_location : null
                    );
                    return this.contentService.getOrderItemsWithSlugsandImages(slugorder);
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  setTimePreferenceToASAP(orderID: string | number, userID: string | number): Observable<DineEngineOrder> {
    return this.novadineApi.getOrderDetails(orderID.toString()).pipe(
      switchMap(order => {
        return this.novadineApi.getStore(order.restaurant.store_id.toString()).pipe(
          switchMap(store => {
            const time =
              order && order.order && order.order.suggested_ready_time
                ? new Date(order.order.suggested_ready_time)
                : this.getASAPReadyTime(store, order.order.service_type_id);
            const req = {
              customer_id: userID,
              order_id: orderID,
              ready_ts: this.mapping.toOrderDateString(time),
              store_id: order.restaurant.store_id.toString(),
              service_type_id: order.order.service_type_id,
              is_time_bump: true,
            } as UpdateBasketRequest;
            return this.novadineApi.startOrUpdateOrder(true, req).pipe(
              switchMap(res => {
                return this.novadineApi.getPaymentMethodsForOrder(orderID.toString()).pipe(
                  switchMap(paymentMethods => {
                    const slugorder = this.mapping.toOrder(
                      res,
                      store,
                      paymentMethods,
                      res.customer_location ? res.customer_location : null
                    );
                    return this.contentService.getOrderItemsWithSlugsandImages(slugorder);
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  setHandoffType(orderID: string, userID: string, handoffType: any): Observable<DineEngineOrder> {
    const serviceTypeID = this.mapping.toServiceTypeID(handoffType);
    return this.novadineApi.getOrderDetails(orderID).pipe(
      switchMap(order => {
        const locationID = order.restaurant.store_id;
        return this.novadineApi.getStore(locationID).pipe(
          switchMap(store => {
            const asapReadyTime = this.getASAPReadyTime(store, serviceTypeID);
            const orderReadyTime = new Date(order.order.ready_time);
            const body = {
              customer_id: userID,
              ready_ts:
                asapReadyTime > orderReadyTime
                  ? this.mapping.toOrderDateString(asapReadyTime)
                  : this.mapping.toOrderDateString(new Date(order.order.ready_time)),
              store_id: locationID,
              service_type_id: serviceTypeID,
              order_id: orderID,
            };
            return this.novadineApi.startOrUpdateOrder(true, body).pipe(
              switchMap(res => {
                this.saveOrderID(res.order.order_id.toString());
                return this.novadineApi.getPaymentMethodsForOrder(res.order.order_id.toString()).pipe(
                  switchMap(paymentMethods => {
                    const slugorder = this.mapping.toOrder(
                      res,
                      store,
                      paymentMethods,
                      res.customer_location ? res.customer_location : null
                    );
                    return this.contentService.getOrderItemsWithSlugsandImages(slugorder);
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  setOrderLocation(userID: string | number, locationID: string | number, orderID: string): Observable<DineEngineOrder> {
    throw new Error('Method not implemented.');
  }

  setTip(userID: string | number, orderID: string, tipCents: number): Observable<DineEngineOrder> {
    return this.novadineApi.getOrderDetails(orderID).pipe(
      switchMap(order => {
        const req = {
          order: {
            comments_to_restaurant: order.order.comments_to_restaurant ? order.order.comments_to_restaurant : '',
            message: '',
            charges: {
              tip_cents: Math.floor(tipCents * 100),
            },
          },
        };
        const locationID = order.restaurant.store_id;
        return this.novadineApi.getStore(locationID).pipe(
          switchMap(store => {
            return this.novadineApi.updateOrder(orderID, req).pipe(
              switchMap(res => {
                return this.novadineApi.getPaymentMethodsForOrder(res.order.order_id.toString()).pipe(
                  switchMap(paymentMethods => {
                    const slugorder = this.mapping.toOrder(
                      res,
                      store,
                      paymentMethods,
                      res.customer_location ? res.customer_location : null
                    );
                    return this.contentService.getOrderItemsWithSlugsandImages(slugorder);
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  logIn(username: string, password: string): Observable<string> {
    return this.directusService.getNovadineSettings().pipe(
      switchMap(settings => {
        // return this.novadineApi.logInByForeignAuth(username, password, settings.provider.toLowerCase()).pipe(switchMap(customerID => {
        //   return this.saveUserID(customerID.toString()).pipe(map(() => {
        //     return String(customerID);
        //   }));
        // }), catchError(() => {
        //   return this.novadineApi.logInExistingCustomer(username, password).pipe(switchMap(customerID => {
        //     return this.saveUserID(String(customerID)).pipe(map(() => {
        //       return String(customerID);
        //     }));
        //   }), catchError(err => {
        //     return throwError(this.mapping.loginError(err));
        //   }));
        // }));
        if (settings.provider.toLowerCase() === 'novadine') {
          return this.novadineApi.logInExistingCustomer(username, password).pipe(
            switchMap(customerID => {
              return this.saveUserID(customerID.toString()).pipe(
                map(() => {
                  return customerID.toString();
                })
              );
            }),
            catchError(err => {
              return throwError(this.mapping.loginError(err));
            })
          );
        } else {
          return this.novadineApi.logInByForeignAuth(username, password, settings.provider.toLowerCase()).pipe(
            switchMap(customerID => {
              return this.saveUserID(customerID.toString()).pipe(
                map(() => {
                  return customerID.toString();
                })
              );
            }),
            catchError(err => {
              return throwError(new Error('Invalid email/password'));
            })
          );
        }
      })
    );
  }

  logInWithToken(token: string, redirectURL: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  logOut(): Observable<string> {
    return this.novadineApi.logOutCustomer().pipe(
      switchMap(() => {
        return this.saveUserID(null).pipe(
          switchMap(() => {
            return this.logInAsGuest().pipe(
              map(guestID => {
                return guestID;
              })
            );
          })
        );
      })
    );
  }

  forgotPassword(email: string): Observable<DineEngineUser> {
    return this.directusService.getNovadineSettings().pipe(
      switchMap(settings => {
        switch (settings.provider.toLowerCase()) {
          case 'paytronix':
            const pService = this.injector.get(PaytronixProviderService);
            return pService.forgotPassword(email);
          case 'novadine':
            return this.novadineApi.forgotPassword(email).pipe(
              map(() => {
                return null;
              })
            );
        }
      })
    );
  }

  changePassword(email: string, oldPassword: string, newPassword: string): Observable<DineEngineUser> {
    return this.novadineApi.changePassword(email, newPassword).pipe(
      map(() => {
        return null;
      })
    );
  }

  resetPassword(newPass: string): Observable<PassResetResponse> {
    const token = this.utils.getQueryStringParam('t');

    if (!newPass) {
      return throwError(new DineEngineError("New password can't be blank."));
    }
    if (!token) {
      return throwError(new DineEngineError('Token parameters missing.'));
    }

    return this.novadineApi.resetPassword('', newPass, token).pipe(
      map(res => {
        return res;
      })
    );
  }

  deleteAccount(userID: string): Observable<boolean> {
    return this.novadineApi.deleteUser(userID).pipe(
      map(res => {
        return res.ok;
      })
    );
  }

  // tslint:disable-next-line: max-line-length
  updateUserInfo(user: User): Observable<DineEngineUser> {
    const req: UpdateCustomerInfoRequest = {
      email: user.email,
      first_name: user.firstName,
      last_name: user.lastName,
      phone: user.phoneNumber,
      email_optin: user.emailOptIn,
      birthdate: moment(user.birthday).format('YYYY-MM-DD'),
    };
    return this.novadineApi.updateCustomerInfo(user.userID, req).pipe(
      switchMap(userInfo => {
        return this.novadineApi.retrieveCustomerLoyaltyBalances(user.userID).pipe(
          map(loyaltyBalances => {
            return this.mapping.toUser(userInfo, loyaltyBalances);
          })
        );
      })
    );
  }

  createAccount(newAccount: CreateAccount): Observable<DineEngineUser> {
    const req: CustomerRegistrationRequest = {
      email: newAccount.email,
      password: newAccount.password,
      first_name: newAccount.firstName,
      last_name: newAccount.lastName,
      phone: newAccount.phone,
      email_optin: newAccount.emailOptIn,
      source: location.hostname,
      platform: 'dineEngine',
      auto_login: false,
      email_user: null,
      company_name: null,
      fax: null,
      tax_exempt: null,
      tax_exempt_id: null,
      tax_exempt_exp: null,
      extra: null,
    };
    return this.novadineApi.registerNewCustomer(req).pipe(
      switchMap(userInfo => {
        return this.novadineApi.retrieveCustomerLoyaltyBalances(userInfo.customer_id.toString()).pipe(
          switchMap(loyaltyBalances => {
            return this.saveUserID(userInfo.customer_id.toString()).pipe(
              map(() => {
                return this.mapping.toUser(userInfo, loyaltyBalances);
              })
            );
          })
        );
      }),
      catchError(err => {
        if (err.error.error) {
          return throwError(new DineEngineError(err.error.error));
        } else {
          return throwError(new Error(err));
        }
      })
    );
  }

  logInAsGuest(): Observable<string> {
    return this.novadineApi.logInGuestCustomer(location.hostname).pipe(
      switchMap(customerID => {
        return this.saveUserID(customerID.toString()).pipe(
          map(() => {
            return customerID.toString();
          })
        );
      })
    );
  }

  getUserInfo(userID: string): Observable<DineEngineUser> {
    return this.novadineApi.getCustomerInfo(userID).pipe(
      switchMap(res => {
        return this.novadineApi.retrieveCustomerLoyaltyBalances(userID).pipe(
          map(loyaltyBalances => {
            return this.mapping.toUser(res, loyaltyBalances);
          })
        );
      })
    );
  }

  getLoggedInUserInfo(): Observable<DineEngineUser> {
    return this.getUserID().pipe(
      switchMap(id => {
        if (!id) {
          return of(null);
        }
        return this.novadineApi.getLoggedInCustomerInfo().pipe(
          switchMap(userInfo => {
            if (userInfo && userInfo.customer_id) {
              return this.novadineApi.retrieveCustomerLoyaltyBalances(userInfo.customer_id.toString()).pipe(
                switchMap(loyaltyBalances => {
                  return this.saveUserID(userInfo.customer_id.toString()).pipe(
                    map(() => {
                      return this.mapping.toUser(userInfo, loyaltyBalances);
                    })
                  );
                })
              );
            }
            return of(null);
          }),
          catchError(() => {
            return of(null);
          })
        );
      })
    );
  }

  is3rdPartyWrapped(): boolean {
    return false;
  }

  setBasketCustomfield(orderId: string, customId: number | string, value: any): Observable<Order> {
    throw new Error('Method not implemented.');
  }

  setSpecialInstructions(orderID: string, inst: string): Observable<Order> {
    return this.getCurrentOrder(null);
    /* return this.novadineApi.getOrderDetails(orderID).pipe(switchMap(order => {
        const req = {
            'order': {
                'comments_to_restaurant': inst,
                'message': '',
                'charges': {
                    'tip_cents': order.order.charges.tip_cents
                }
            }
        };
        const locationID = order.restaurant.store_id;
        return this.novadineApi.getStore(locationID).pipe(switchMap(store => {
            return this.novadineApi.updateOrder(orderID, req).pipe(map(res => {
                return this.mapping.toOrder(res, store);
            }));
        }));
    })); */
  }

  addDonation(basketGuid: string, id: number, amount: number): Observable<Order> {
    throw new Error('Method not implemented.');
  }

  private getPaymentStatus(info, orderID, cardDetails, applyCents, token): Observable<Order> {
    return this.novadineApi.checkPaymentStatus(info.pending_payment.token, info.pending_payment.pay_proc_id.toString()).pipe(
      switchMap(orderRes => {
        console.log(orderRes);
        if (orderRes && orderRes.pendingPayment && orderRes.pendingPayment.percentComplete) {
          return this.getPaymentStatus(info, orderID, cardDetails, applyCents, token);
        } else {
          if (orderRes && orderRes.errors) {
            throw new Error(orderRes.errors[1]);
          } else {
            return this.getUserID().pipe(
              switchMap(id => {
                return this.novadineApi.addToSavedOrders(id, orderID).pipe(
                  switchMap(() => {
                    return of(null);
                  })
                );
              })
            );
          }
        }
      })
    );
  }

  private isOpenDuring(store: NovaDineStore, dt: Date): boolean {
    const hoursThisWeek = this.mapping.toTimeFrame(this.mapping.toMenuHours(store.hours));
    return hoursThisWeek.some(tf => tf.start.getTime() <= dt.getTime() && tf.end.getTime() > dt.getTime());
  }

  private getTodaysOpeningTime(store: NovaDineStore): Date {
    const now = new Date();
    const hoursThisWeek = this.mapping.toTimeFrame(this.mapping.toMenuHours(store.hours));
    const hoursToday = hoursThisWeek.find(tf => tf.start.getDay() === now.getDay());
    return hoursToday ? moment(hoursToday.start).utcOffset(store.timezone_offset_mins, true).toDate() : null;
  }

  private getTomorrowsOpeningTime(store: NovaDineStore): Date {
    const now = new Date();
    const hoursThisWeek = this.mapping.toTimeFrame(this.mapping.toMenuHours(store.hours));
    const hoursTomorrow = hoursThisWeek.find(tf => tf.start.getDay() === now.getDay() + 1);
    return hoursTomorrow ? moment(hoursTomorrow.start).utcOffset(store.timezone_offset_mins, true).toDate() : null;
  }

  private getPaymentMethod(orderID: string, cardDetails: CardDetails): Observable<PaymentMethod> {
    return this.novadineApi.getPaymentMethodsForOrder(orderID).pipe(
      map(paymentMethods => {
        let paymentProcID: PaymentMethod;
        if (cardDetails.paymentType === PaymentTypes.cash) {
          paymentProcID = paymentMethods.find(pm => pm.name.toLowerCase() === 'cash');
        } else if (cardDetails.isSavedCard) {
          paymentProcID = paymentMethods.find(pm => pm.cc_account_id && pm.cc_account_id === cardDetails.savedCardID);
        } else {
          paymentProcID = paymentMethods.find(pm => pm.match_re && new RegExp(pm.match_re).test(cardDetails.cardNumber));
        }
        return paymentProcID;
      })
    );
  }

  private saveUserID(userID: string): Observable<void> {
    console.log('saveUserID', userID);
    return from(Storage.set({ key: this.userKey, value: userID }))
      .pipe
      //     switchMap(() => {
      //   // return this.getUserID().pipe(switchMap(id => {
      //   //   console.log('savedUserID', id)
      //   //   if (id) {
      //   //     return of<void>();
      //   //   } else {
      //   //     return this.saveUserID(userID);
      //   //   }
      //   // }));
      // })
      ();
    // localStorage.setItem(this.userKey, userID);
  }

  private getUserID(): Observable<string> {
    return from(Storage.get({ key: this.userKey })).pipe(
      map(res => {
        return res.value;
      })
    );
    // return localStorage.getItem(this.userKey);
  }

  private saveOrderID(orderID: string) {
    sessionStorage.setItem(this.orderKey, orderID);
  }

  private getOrderID(): string {
    return sessionStorage.getItem(this.orderKey);
  }

  private removeOrderID(): void {
    sessionStorage.removeItem(this.orderKey);
  }

  private getCachedMenu(restaurantID: string, handoffType: HandoffType): DineEngineMenu {
    const cachedMenu = this.cachedMenus.find(
      m =>
        m.restaurantID === restaurantID &&
        ((isNaN(handoffType) && isNaN(m.handoffType)) || m.handoffType === handoffType) &&
        new Date().getTime() - m.cacheDate.getTime() < this.maintainMenuCacheMs
    );
    return cachedMenu ? cachedMenu.menu : null;
  }

  private storeCachedMenu(restaurantID: string, handoffType: HandoffType, menu: DineEngineMenu) {
    this.cachedMenus.push({
      restaurantID,
      handoffType,
      menu,
      cacheDate: new Date(),
    });
  }

  private getASAPReadyTime(store: Store, serviceTypeID: number): Date {
    const service = store.services.find(serv => serv.service_type_id === serviceTypeID);
    const leadTimeMinutes = service ? service.lead_time : 0;
    const now = new Date();
    const readyTime = this.isOpenDuring(store, now)
      ? moment(now).utcOffset(store.timezone_offset_mins, true).toDate()
      : this.getTodaysOpeningTime(store) >= now
        ? this.getTodaysOpeningTime(store)
        : this.getTomorrowsOpeningTime(store);
    readyTime.setMinutes(readyTime.getMinutes() + leadTimeMinutes + 10); // +1 because it takes some time to get to the server
    return readyTime;
  }

  private getUpdateOrderRequest(item: OrderItem, userID: string, quantity: number): UpdateOrderRequest {
    return {
      customer_id: userID,
      items: [
        {
          comment: item.instructions,
          menu_id: Number(item.menuID),
          quantity,
          item_id: Number(item.productID),
          category_id: Number(item.categoryID),
          child_items: item.options.map(
            mod =>
              ({
                categoryId: Number(mod.modifierCategoryID),
                itemId: Number(mod.optionID),
                menu_id: Number(item.menuID),
                pickListId: Number(mod.optionGroupID),
                quantity: mod.quantity * quantity,
              }) as ChildItem
          ),
        },
      ],
    } as UpdateOrderRequest;
  }

  getLoyaltyLocations(): Observable<any> {
    return of(null);
  }

  joinGroupOrder(groupGuid: string, name: string): Observable<GroupOrder> {
    return undefined;
  }

  // tslint:disable-next-line:max-line-length
  startGroupOrder(
    restaurantID: number,
    basketGuid: string,
    deadline: Date,
    members: string[],
    note: string,
    name: string
  ): Observable<GroupOrder> {
    // return this.getAuthToken().pipe(switchMap(id => {
    return this.getLocation(restaurantID.toString()).pipe(
      map(location => {
        // return this.oloAPI.getAllBillingSchemesAndAccounts(basketGuid).pipe(switchMap(billingSchemes => {
        // tslint:disable-next-line:max-line-length
        //       return this.novadineApi.createGroupOrder(id, restaurantID, basketGuid, deadline, note).pipe(map((response: GroupOrderResponse) => {
        //         console.log(response);
        //         const order = this.mapping.groupOrderResponseToOrder(response, location, billingSchemes.billingschemes);
        //         console.log(order);
        //         return order;
        const dateStr = '20221006 12:57';
        const year = Number(dateStr.substr(0, 4));
        const month = Number(dateStr.substr(4, 2));
        const day = Number(dateStr.substr(6, 2));
        const hour = Number(dateStr.substr(9, 2));
        const minute = Number(dateStr.substr(12, 2));
        const ert = new Date(year, month - 1, day, hour, minute, 0, 0);
        const order = {
          orderID: '',
          taxCents: 10,
          subTotalCents: 200,
          tipCents: 20,
          totalCents: 230,
          appliedCouponCents: 40,
          orderPlacedTimestamp: null,
          handoffType: HandoffType.pickup,
          orderReadyTimestamp: ert,
          location,
          orderStatus: null,
          items: [],
          deliveryAddress: null,
          customFields: null,
          earliestReadyTimestamp: ert,
          isASAP: true,
          appliedCouponCode: null,
          appliedRewards: [],
          balanceCents: 0,
          isGroup: false,
          paidCents: 0,
          specialInstructions: null,
          fees: [],
          deliveryFee: null,
          canTip: true,
          arrivalstatus: null,
          supportedPaymentTypes: [],
          requiresFullBillingAddress: false,
          orderReference: '',
          curbsideCustomFields: null,
          tableNumber: '5',
          isEditable: true,
        } as Order;
        return {
          groupOrderID: '',
          groupOrderDeadline: ert,
          groupOrderNote: '',
          groupOrderOwner: 'Eric',
          groupOrderIsOpen: true,
          order,
        } as GroupOrder;
        // }));
        // }));
      })
    );
    // }));
  }

  // tslint:disable-next-line:max-line-length
  updateGroupOrder(
    restaurantID: number,
    basketGuid: string,
    groupGuid: string,
    deadline: Date,
    members: string[],
    note: string,
    name: string
  ): Observable<GroupOrder> {
    return of(null);
  }

  getCurrentGroupOrder(restaurantID: number, basketGuid: string, name: string): Observable<GroupOrder> {
    return of(null);
  }

  getGroupOrder(groupID: string, name: string): Observable<GroupOrder> {
    return of(null);
  }

  deleteMessage(userID: string, messageID: string): Observable<InboxMessage[]> {
    return undefined;
  }

  getMessages(userID: string): Observable<InboxMessage[]> {
    return undefined;
  }

  markMessageAsRead(userID: string, messageID: string): Observable<InboxMessage[]> {
    return undefined;
  }

  getApplePassUrl(userID: string): Observable<string> {
    return of(null);
  }

  getGooglePassData(userID: string): Observable<GooglePass> {
    return of(null);
  }

  markGooglePassAsSaved(userID: string, objectCode: string): Observable<boolean> {
    return of(true);
  }

  getReferrals(userID: string): Observable<Referral[]> {
    return undefined;
  }

  sendReferrals(userID: string, emails: string[], message: string): Observable<Referral[]> {
    return undefined;
  }

  supportsReferrals(): boolean {
    return false;
  }

  checkIfCanSupportMultipleRewards(orderID: string): Observable<boolean> {
    return of(false);
  }
}
