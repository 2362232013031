import { Injectable } from '@angular/core';
import { combineLatest, find, forkJoin, from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, delay, filter, map, switchMap, toArray } from 'rxjs/operators';
import { OloAPIService } from '../olo/olo-api.service';
import { OLOMappingService } from '../olo/olo-mapping.service';
import { OrderHistoryProvider } from 'src/providers/order-history-provider.interface';
import { OrderProvider } from 'src/providers/order-provider.interface';
import { MenuProvider } from 'src/providers/menu-provider.interface';
import { LocationProvider } from 'src/providers/location-provider.interface';
import { UserProvider } from 'src/providers/user-provider.interface';
import { HandoffType } from 'src/interfaces/handoff-type.enum';
import { CardDetails } from 'src/interfaces/card-details.interface';
import { OrderItem, Product } from 'src/interfaces/product.interface';
import { Menu } from 'src/interfaces/menu.interface';
import { User } from 'src/interfaces/user.interface';
import { Order } from 'src/interfaces/order.interface';
import { Location, LoyaltyLocation } from 'src/interfaces/location.interface';
import { Address } from 'src/interfaces/address.interface';
import { Address as OloAddress } from '../olo/interfaces/address.interface';
import { AddProductRequest } from '../olo/interfaces/add-product-request.interface';
import { Order as OloOrder } from '../olo/interfaces/recent-orders-response.interface';
import { PunchhAPIService } from '../punchh/punchh-api.service';
import { UserRequest } from '../punchh/interfaces/create-user-request.interace';
import { PunchhMappingService } from '../punchh/punchh-mapping.service';
import {
  SubmitOrderRequestWithCreditCardAsGuest,
  SubmitOrderRequestWithCreditCardAsUser,
} from '../olo/interfaces/submit-order-request-with-credit-card.interface';
import { Billingaccount, SubmitOrderMultiPaymentRequest } from '../olo/interfaces/submit-order-multi-payment-request.interface copy';
import { OrderItemModifier } from 'src/interfaces/option.interface';
import { LoyaltyProvider } from 'src/providers/loyalty-provider.interface';
import { Reward } from 'src/interfaces/reward.interface';
import { SavedCard } from 'src/interfaces/saved-card.interface';
import { RestaurantDeliveryRequest } from '../olo/interfaces/restaurant-delivery-request.interface';
import { RewardsBalances } from 'src/interfaces/rewards-balances.interface';
import { RestaurantMenu } from '../olo/interfaces/restaurant-menu.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { ImageContentService } from '../directus/content.service';
import { UtilityService } from '@modules/utility/services/utility.service';
import { CateringLink } from 'src/interfaces/catering-link.interface';
import { PunchhSettingsContentService } from '../punchh/content.service';
import { DirectusService } from '../directus/directus.service';
import { Upsells } from 'src/interfaces/upsells.interface';
import {
  SubmitOrderRequestWithGiftCardAsGuest,
  SubmitOrderRequestWithGiftCardAsUser,
} from '../olo/interfaces/submit-order-request-with-gift-card.interface';
import { DollarReward } from 'src/interfaces/dollar-reward.interface';
import {
  SubmitOrderRequestWithSavedCardAsGuest,
  SubmitOrderRequestWithSavedCardAsUser,
} from '../olo/interfaces/submit-order-request-with-saved-card.interface';
import { AddUpsellItemsRequest } from '../olo/interfaces/add-upsell-items-request.interface';
import moment, { Moment } from 'moment-timezone';
import { HistoryEvent } from 'src/interfaces/history-event.interface';
import { SSOLogin } from 'src/interfaces/sso-login.model';
import { PassResetResponse } from 'src/interfaces/pass-reset-response.interface';
import { CreateAccount } from '../../interfaces/create-account.interface';
import { GiftCardItem } from '../../interfaces/gift-card-item.interface';
import { PaymentTypes } from '../../interfaces/payment-types.enum';
import {
  SubmitOrderRequestWithCashAsGuest,
  SubmitOrderRequestWithCashAsUser,
} from '../olo/interfaces/submit-order-request-with-cash.interface';
import { ToastService } from '../../services/toast.service';
import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { AppleUser, MobileSignInWithAppleRequest, SignInWithAppleRequest } from '../punchh/interfaces/sign-in-with-apple-request.interface';
import { CheckInCode } from '../../interfaces/check-in-code.interface';
import { Preferences as Storage } from '@capacitor/preferences';
import { NavigationService } from '@modules/navigation/services';
import { UpdateUserInfoRequest } from '../punchh/interfaces/update-user-info-request.interface';
import {
  SubmitOrderWithPrepaidTransactionAsGuest,
  SubmitOrderWithPrepaidTransactionAsUser,
} from '../olo/interfaces/submit-order-with-prepaid-transaction.interface';
import { PurchaseableReward, Variation } from '../../interfaces/purchaseable-reward.interface';
import { Billingaccount as SavedBillingAccount } from '../olo/interfaces/billing-accounts-response.interface';
import { DineEngineError } from '../../interfaces/dineengine-error.interface';
import { GiftCard } from '../../interfaces/giftcard.interface';
import { LoyaltyReward } from '../../interfaces/loyalty-reward.interface';
import { InboxMessage } from '../../interfaces/inbox-message.interface';
import { GroupOrderResponse } from '../olo/interfaces/group-order.interface';
import { GroupOrder } from '../../interfaces/group-order.interface';
import { LocalStorageKey } from '../../models/common.enum';
import { Billingscheme } from '../olo/interfaces/billing-schemes-and-account-response.interface';
import { Capacitor } from '@capacitor/core';
import {
  SubmitOrderRequestWithCreditCardTokenAsGuest,
  SubmitOrderRequestWithCreditCardTokenAsUser,
} from '../olo/interfaces/submit-order-request-with-credit-card-token.interface';
import { UserField } from '../../interfaces/user-field';
import { Cacheable } from 'ts-cacheable';
import { GooglePass } from '../../interfaces/google-pass';

import { Referral as OloReferral } from '../olo/interfaces/add-referral-to-basket-request.interface';
import { Referral } from '../../interfaces/referral.interface';
import VendorConfig from '../config/vendor.config';
import tzlookup from 'tz-lookup';
// import { RestaurantLeadTimeEstimate } from '../olo/interfaces/retrieve-estimated-lead-times.interface';

const userCacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: 'root',
})
// tslint:disable-next-line: max-line-length
export class PunchhOLOProviderService
  implements OrderProvider, OrderHistoryProvider, MenuProvider, LocationProvider, UserProvider, LoyaltyProvider
{
  private basketGuidKey = 'OLOBasketGuid';
  private oloAuthTokenKey = LocalStorageKey.OLO_AUTH_TOKEN;
  private groupGuidKey = 'OLOGroupGuid';
  private punchhAuthTokenKey = LocalStorageKey.PUNCHH_AUTH_TOKEN;
  private punchhAccessTokenKey = LocalStorageKey.PUNCHH_ACCESS_TOKEN;
  private punchhMobileAuthTokenKey = LocalStorageKey.PUNCHH_MOBILE_AUTH_TOKEN;
  private punchhMobileAccessTokenKey = LocalStorageKey.PUNCHH_MOBILE_ACCESS_TOKEN;
  private punchhOauthJwtTokenKey = LocalStorageKey.PUNCHH_OAUTH_JWT_TOKEN;
  private punchhOauthCodeKey = LocalStorageKey.PUNCHH_OAUTH_CODE;
  private punchhAppleUserDataKey = LocalStorageKey.PUNCHH_APPLE_USER_DATA;
  private maintainMenuCacheMs = 15 * 60 * 1000; // 15 minutes

  showHiddenLocations = false;

  providerName = VendorConfig.punchhOlo;

  // For Tableside, we need to send some fields that are not natively present in an Olo order.
  customData: Array<{ key: string; value: string }> = [];

  private punchhOauthToken: string;
  private punchhOauthTokenSubject = new Subject<string>();

  constructor(
    private punchhAPI: PunchhAPIService,
    private oloAPI: OloAPIService,
    private oloMapping: OLOMappingService,
    private punchhMapping: PunchhMappingService,
    private route: ActivatedRoute,
    private router: Router,
    private utils: UtilityService,
    private contentService: ImageContentService,
    private punchhSettingsService: PunchhSettingsContentService,
    private directusService: DirectusService,
    private toast: ToastService,
    private navigation: NavigationService
  ) {
    const qsOauthCode = this.utils.getQueryStringParam('code');
    const qsOauthJwt = this.utils.getQueryStringParam('jwt');
    const isOauth = this.utils.getQueryStringParam('oauth');

    if (qsOauthCode && qsOauthJwt && isOauth) {
      this.storePunchhOauthCode(qsOauthCode);
      this.storePunchhOauthJwtToken(qsOauthJwt);
      this.handleOauthLogin(qsOauthCode).subscribe(token => {
        this.punchhOauthToken = token;
        this.punchhOauthTokenSubject.next(this.punchhOauthToken);
      });
      this.getLoggedInUserInfo().subscribe(() => {
        this.navigation.navigateToPreviousOrdersPage();
      });
    }

    this.route.queryParams.subscribe(params => {
      const token = params.security_token;
      if (token) {
        localStorage.setItem('is3rdPartyWrapped', 'true');
        this.doPunchhOloSSOSignIn(token);
      }
    });
    this.directusService.getOloSettings().subscribe(config => {
      this.showHiddenLocations = config.show_hidden_locations;
    });
    this.directusService.getPunchhSettings().subscribe(() => {});
  }

  setManualFire(orderID: string | number): Observable<Order> {
    return this.oloAPI.setBasketTimeModeToManualFire(orderID.toString()).pipe(
      switchMap(() => {
        return this.getCurrentOrder(null);
      })
    );
  }

  getResetPasswordCode(email: string): Observable<any> {
    return this.forgotPassword(email);
  }

  deleteAccount(userID: string): Observable<boolean> {
    return this.getPunchhMobileAccessToken().pipe(
      switchMap(token => {
        return this.punchhAPI.deleteAccount(token).pipe(
          map(() => {
            return true;
          })
        );
      })
    );
  }

  getLoyaltyLocations(): Observable<LoyaltyLocation[]> {
    return this.punchhAPI.getMeta().pipe(
      map(res => {
        return res.locations.map(location => this.punchhMapping.punchhLocationToLoyaltyLocation(location));
      })
    );
  }

  private doPunchhOloSSOSignIn(token: string) {
    this.punchhAPI.createAccessTokenForSSO(token).subscribe(res => {
      this.storePunchhAuthToken(res.user.authentication_token);
      this.storePunchhAccessToken(res.user.access_token);
      this.oloAPI.createOrGetSSOLinkedOLO('punchh', res.user.access_token, this.getBasketGuid()).pipe(
        map(user => {
          this.storeOLOAuthToken(user.authtoken);
        })
      );
    });
  }

  getSSOLoginSubject(): Observable<SSOLogin> {
    return of(null);
  }

  isOauthEnabled(): Observable<boolean> {
    return this.punchhAPI.isOauthEnabled();
  }

  redirectToOauthPage() {
    return this.punchhAPI.redirectToOauthPage();
  }

  getLocationtableNumber(tableNumber: number, locationID: number): Observable<string> {
    return of(tableNumber.toString());
  }

  handleOauthLogin(code: string): Observable<string> {
    return this.punchhAPI.getOauthToken(code).pipe(
      switchMap(res => {
        return this.punchhAPI.getUserInfoByAccessToken(res.access_token).pipe(
          switchMap(res2 => {
            return forkJoin({
              auth: this.storePunchhAuthToken(res2.authentication_token),
              access: this.storePunchhAccessToken(res.access_token),
            }).pipe(
              switchMap(() => {
                return this.oloAPI.createOrGetSSOLinkedOLO('punchh', res.access_token, this.getBasketGuid()).pipe(
                  switchMap(user => {
                    return this.storeOLOAuthToken(user.authtoken).pipe(
                      map(() => {
                        return user.authtoken;
                      })
                    );
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  cateringLink(): Observable<CateringLink> {
    return this.punchhAPI.getCateringLinkConfig().pipe(
      switchMap(res => {
        if (!res.link) {
          return of(res);
        }
        return forkJoin({
          accessToken: this.getPunchhAccessToken(),
          oauthJWT: this.getPunchhOauthJwtToken(),
          oauthCode: this.getPunchhOauthCode(),
        }).pipe(
          map(tokens => {
            const access = encodeURIComponent(tokens.accessToken || '');
            const jwt = encodeURIComponent(tokens.oauthJWT || '');
            const code = encodeURIComponent(tokens.oauthCode || '');
            const clientId = encodeURIComponent(res.clientId || '');
            const link = res.link.replace('{code}', code).replace('{jwt}', jwt).replace('{client}', clientId).replace('{access}', access);
            return {
              ...res,
              link,
            };
          })
        );
      })
    );
  }

  addCoupon(orderID: string, couponCode: string): Observable<Order> {
    return this.oloAPI.applyCoupon(orderID, couponCode).pipe(
      switchMap(res => {
        return this.getLocation(res.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
              switchMap(billingSchemes => {
                const order = this.oloMapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  removeCoupon(orderID: string): Observable<Order> {
    return this.oloAPI.removeCoupon(orderID).pipe(
      switchMap(res => {
        return this.oloAPI.getBasket(orderID).pipe(
          switchMap(() => {
            return this.getLocation(res.vendorid).pipe(
              switchMap(location => {
                return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
                  switchMap(billingSchemes => {
                    const order = this.oloMapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                    return this.contentService.getOrderItemsWithSlugsandImages(order);
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  notifyOfArrival(orderID: string, extraMessage: string): Observable<Order> {
    return this.oloAPI.notifyOfArrival(orderID, extraMessage).pipe(
      switchMap(res => {
        return this.getLocation(res.vendorid).pipe(
          switchMap(location => {
            const order = this.oloMapping.orderToOrder(res, location);
            return this.contentService.getOrderItemsWithSlugsandImages(order);
          })
        );
      })
    );
  }

  getOffers(userID: string | number): Observable<any> {
    return this.getPunchhAuthToken().pipe(
      switchMap(token => {
        return this.punchhAPI.getAvailableOffers(token).pipe(
          map(() => {
            // return loyaltyOffers;
            return null;
          })
        );
      })
    );
  }

  getLoyaltyActivity(): Observable<HistoryEvent[]> {
    // const authToken = this.getPunchhAuthToken();
    return this.getPunchhAuthToken().pipe(
      switchMap(token => {
        return this.punchhAPI.getAccountHistory(token).pipe(
          map(history => {
            return this.punchhMapping.accountHistoryToHistoryEvents(history);
          })
        );
      })
    );
  }

  getMessages(userID: string): Observable<InboxMessage[]> {
    return this.getPunchhMobileAccessToken().pipe(
      switchMap(token => {
        return this.punchhAPI.mobileFetchUserBalance(token).pipe(
          map(res => {
            return res.messages.map(message => this.punchhMapping.messageToInboxMessage(message));
          })
        );
      })
    );
  }

  markMessageAsRead(userID: string, messageID: string): Observable<InboxMessage[]> {
    return this.getPunchhMobileAccessToken().pipe(
      switchMap(token => {
        return this.punchhAPI.mobileMarkMessageRead(token, messageID).pipe(
          switchMap(() => {
            return this.getMessages(userID);
          })
        );
      })
    );
  }

  deleteMessage(userID: string, messageID: string): Observable<InboxMessage[]> {
    return this.getPunchhMobileAccessToken().pipe(
      switchMap(token => {
        return this.punchhAPI.mobileDeleteMessage(token, messageID).pipe(
          switchMap(() => {
            return this.getMessages(userID);
          })
        );
      })
    );
  }

  redeemReward(reward: Reward): Observable<Order> {
    return this.oloAPI.applyRewards(this.getBasketGuid(), reward.membershipID, [reward.externalRef.toString()]).pipe(
      switchMap(() => {
        return this.oloAPI.validateBasket(this.getBasketGuid(), false).pipe(
          switchMap(() => {
            return this.oloAPI.getBasket(this.getBasketGuid()).pipe(
              switchMap(basketRes => {
                return this.getLocation(basketRes.vendorid).pipe(
                  switchMap(location => {
                    return this.oloAPI.getAllBillingSchemesAndAccounts(basketRes.id).pipe(
                      switchMap(billingSchemes => {
                        const order = this.oloMapping.createBasketResponseToOrder(basketRes, location, billingSchemes.billingschemes);
                        return this.contentService.getOrderItemsWithSlugsandImages(order);
                      })
                    );
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  redeemInStoreReward(reward: Reward): Observable<Reward> {
    return this.getPunchhAuthToken().pipe(
      switchMap(token => {
        return this.punchhAPI.getAccountBalance(token).pipe(
          switchMap(res => {
            const rewardID = res.rewards.find(rwrd => rwrd.redeemable_id === parseInt(reward.externalRef, 10)).id;
            return this.punchhAPI.redeemReward(rewardID, token).pipe(
              map(redemption => {
                const newReward: Reward = {
                  ...reward,
                  redemptionInfo: {
                    ...reward.redemptionInfo,
                    redemptionCode: redemption.internal_tracking_code,
                    expiryHours: redemption.expiry_hours,
                  },
                };
                // reward.redemptionInfo.redemptionCode = redemption.internal_tracking_code;
                // reward.redemptionInfo.expiryHours = redemption.expiry_hours;
                return newReward;
              })
            );
          })
        );
      })
    );
  }

  redeemBankedPoints(points: number): Observable<Reward> {
    return this.getPunchhAuthToken().pipe(
      switchMap(token => {
        return this.punchhAPI.redeemPoints(points, token).pipe(
          map(redemption => {
            return {
              redemptionInfo: {
                redemptionCode: redemption.internal_tracking_code,
                expiryHours: redemption.expiry_hours,
              },
            } as Reward;
          })
        );
      })
    );
  }

  redeemPointsFromScanner(barCode: string): Observable<any> {
    return this.getPunchhMobileAccessToken().pipe(
      switchMap(token => {
        return this.punchhAPI.redeemPointsFromScanner(barCode, token).pipe(
          map(res => {
            return res;
          }),
          catchError(err => {
            return throwError(this.punchhMapping.barCodeErr(err));
          })
        );
      })
    );
  }

  voidBankedPoints(reward: DollarReward): Observable<any> {
    return this.getPunchhAuthToken().pipe(
      switchMap(token => {
        return this.punchhAPI.voidPoints(reward.redemptionInfo.redemptionCode, token, true);
      })
    );
  }

  removeAppliedReward(reward: Reward): Observable<Order> {
    return this.oloAPI.removeAppliedReward(this.getBasketGuid(), Number(reward.rewardID)).pipe(
      switchMap(res => {
        return this.getLocation(res.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
              switchMap(billingSchemes => {
                const order = this.oloMapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  getRewards(userID: string, locationID: string): Observable<Reward[]> {
    return this.getOLOAuthToken().pipe(
      switchMap(token => {
        if (token) {
          return this.oloAPI.getUsersQualifyingRewards(token, locationID).pipe(
            map(res => {
              return res.rewards.map(r => this.oloMapping.toReward(r));
            })
          );
        } else {
          return of(null);
        }
      })
    );
  }

  getAvailableLoyaltyRewards(userID: string): Observable<LoyaltyReward[]> {
    return this.getPunchhAuthToken().pipe(
      switchMap(token => {
        return this.punchhAPI.getAccountBalance(token).pipe(
          map(res => {
            return res.redeemables.map(redeemable => this.punchhMapping.redeemableToLoyaltyReward(redeemable));
          })
        );
      })
    );
  }

  getPointsBalance(userID: string | number): Observable<RewardsBalances> {
    return this.getPunchhAuthToken().pipe(
      switchMap(token => {
        return this.punchhAPI.getAccountBalance(token).pipe(
          map(res => {
            // const rewards = res.redeemables.map(r => this.oloMapping.punchhRedeemableToReward(r));
            return {
              // balance: {
              points: res.points_balance,
              bankedRewards: res.net_balance,
              pointsUnit: 'points',
              // threshold: 500
              pointsThreshold: res.redeemables.sort((a, b) => b.points - a.points).find(r => r.points > res.points_balance)?.points,
              storedValueCents: null,
              // },
              // rewards: rewards
            };
          })
        );
      })
    );
  }

  redeemPoints(userID: string | number): Observable<any> {
    throw new Error('Method not implemented.');
  }

  earnPoints(userID: string | number): Observable<any> {
    throw new Error('Method not implemented.');
  }

  checkInAtStore(): Observable<CheckInCode> {
    return of(null);
  }

  createQuickCode(): Observable<CheckInCode> {
    return of(null);
  }

  getPurchaseableRewards(): Observable<PurchaseableReward[]> {
    return of(null);
  }

  purchaseReward(reward: PurchaseableReward, variation?: Variation): Observable<boolean> {
    return of(false);
  }

  canTransferGiftCardToAccount(): Observable<boolean> {
    return of(false);
  }

  transferGiftCardBalanceToAccount(from: GiftCard): Observable<{ success: boolean }> {
    throw new Error('Method not implemented.');
  }

  getLoyaltyStoredValueCardInfo(userID: string): Observable<GiftCard> {
    throw new Error('Method not implemented.');
  }

  getGiftCardBalance(basketGuid: string, cardNumber: string, pin: string): Observable<GiftCardItem> {
    return this.oloAPI.getAllBillingSchemesAndAccounts(basketGuid).pipe(
      switchMap(schemes => {
        const billingscheme = schemes.billingschemes.find((sche: any) => sche.type === 'giftcard');
        return this.oloAPI.getGiftCardBalance(basketGuid, billingscheme.id, cardNumber, pin).pipe(
          map(gcb => {
            return this.oloMapping.toGiftCard(gcb, cardNumber, pin);
          })
        );
      })
    );
  }

  removeCard(card: SavedCard): Observable<SavedCard> {
    return this.getOLOAuthToken().pipe(
      switchMap(token => {
        return this.oloAPI.deleteUserBillingAccount(token, Number(card.savedCardID)).pipe(
          map(() => {
            return null;
          })
        );
      })
    );
  }

  saveCardAsDefault(card: SavedCard): Observable<SavedCard> {
    return this.getOLOAuthToken().pipe(
      switchMap(token => {
        return this.oloAPI.setUserDefaultCreditCard(token, Number(card.savedCardID)).pipe(
          map(() => {
            return null;
          })
        );
      })
    );
  }

  getSavedCards(userID: string | number, isAccountPage: boolean): Observable<SavedCard[]> {
    return this.getOLOAuthToken().pipe(
      switchMap(token => {
        if (isAccountPage) {
          return this.oloAPI.getUserBillingAccounts(token).pipe(
            switchMap(res => {
              const creditCards = res.billingaccounts.filter(account => account.accounttype === 'creditcard');
              const giftCards = res.billingaccounts.filter(
                account =>
                  (account.accounttype !== 'creditcard' && account.accounttype !== 'payinstore') ||
                  account.accounttype.toLowerCase().includes('gift card')
              );

              // Turn giftCards array into an observable of arrays
              const giftCardBalances$ = giftCards.length
                ? from(giftCards).pipe(
                    concatMap(card => this.getSavedGiftCardBalance(token, card)),
                    toArray()
                  )
                : // If no gift cards, just emit [undefined] for consistency
                  of([undefined]);

              return giftCardBalances$.pipe(
                map(cardsWithBalance => {
                  return (
                    creditCards
                      .concat(cardsWithBalance)
                      // filter out undefined placeholders
                      .filter(a => a !== undefined)
                      // map to your final SavedCard structure
                      .map(account => this.oloMapping.toCreditCard(account))
                  );
                })
              );
            })
          );
        } else {
          return this.oloAPI.getQualifyingUserBillingAccounts(token, this.getBasketGuid()).pipe(
            switchMap(res => {
              const creditCards = res.billingaccounts.filter(account => account.accounttype === 'creditcard');
              const giftCards = res.billingaccounts.filter(
                account =>
                  (account.accounttype !== 'creditcard' && account.accounttype !== 'payinstore') ||
                  account.accounttype.toLowerCase().includes('gift card')
              );

              // Same approach for the "else" case
              const giftCardBalances$ = giftCards.length
                ? from(giftCards).pipe(
                    concatMap(card => this.getSavedGiftCardBalance(token, card)),
                    toArray()
                  )
                : of([undefined]);

              return giftCardBalances$.pipe(
                map(cardsWithBalance => {
                  return creditCards
                    .concat(cardsWithBalance)
                    .filter(a => a !== undefined)
                    .map(account => this.oloMapping.toCreditCard(account));
                })
              );
            })
          );
        }
      })
    );
  }

  private getSavedGiftCardBalance(authtoken: string, account: SavedBillingAccount): Observable<SavedBillingAccount> {
    return this.oloAPI.getUserGiftCardBalance(authtoken, account.accountid).pipe(
      map(res => {
        account.balance = res;
        return account;
      }),
      catchError(() => of(account))
    );
  }

  getLocations(withCalendars?: boolean): Observable<Location[]> {
    return this.oloAPI.getAllParticipatingRestaurants(this.showHiddenLocations).pipe(
      switchMap(res => {
        const locations = res.restaurants.map(restaurant => this.oloMapping.restaurantToLocation(restaurant));
        return combineLatest(locations.map(loc => this.contentService.getLocationWithMapIconURL(loc))).pipe(
          switchMap(locs => {
            if (withCalendars && locs.length < 50) {
              return combineLatest(locs.map(loc => this.getLocationWithHours(loc))).pipe(
                switchMap(locsWithHours => {
                  return combineLatest(locsWithHours.map(loc => this.contentService.getSingleLocationWithSlug(loc)));
                })
              );
            } else {
              return combineLatest(locs.map(loc => this.contentService.getSingleLocationWithSlug(loc)));
            }
          })
        );
      })
    );
  }

  getLocation(locationID: number): Observable<Location> {
    return this.oloAPI.getRestaurant(locationID).pipe(
      switchMap(restaurant => {
        const loc = this.oloMapping.restaurantToLocation(restaurant);
        return this.contentService.getLocationWithMapIconURL(loc).pipe(
          switchMap(location => {
            return this.getLocationWithHours(location).pipe(
              switchMap(locWithHours => {
                return this.contentService.getSingleLocationWithSlug(locWithHours);
              })
            );
          })
        );
      })
    );
  }

  getLocationBySlug(slugID: string): Observable<Location> {
    return this.directusService.getSingleLocationBySlug(slugID).pipe(
      switchMap(locationInfo => {
        return this.getLocation(Number(locationInfo.menu_id));
      })
    );
  }

  getLocationsNear(latitude: number, longitude: number, milesRadius: number, maxResults: number): Observable<Location[]> {
    return this.oloAPI
      .getNearbyParticipatingRestaurants(latitude, longitude, milesRadius, maxResults, moment().toDate(), moment().add(1, 'week').toDate())
      .pipe(
        switchMap(res => {
          const locations = res.restaurants.map(restaurant => this.oloMapping.restaurantToLocation(restaurant));
          if (locations.length === 0) {
            return of<Location[]>([]);
          } else {
            return combineLatest(locations.map(loc => this.contentService.getLocationWithMapIconURL(loc))).pipe(
              switchMap(locs => {
                return combineLatest(locs.map(loc => this.contentService.getSingleLocationWithSlug(loc)));
              })
            );
          }
        })
      );
  }

  // tslint:disable-next-line: max-line-length
  checkForDelivery(
    restaurantID: number,
    handoffType: HandoffType.delivery | HandoffType.dispatch,
    timeWanted: Date,
    address: Address
  ): Observable<{ canDeliver: boolean; failureReason?: string }> {
    let requestBody: RestaurantDeliveryRequest = {
      handoffmode: handoffType === HandoffType.delivery ? 'delivery' : 'dispatch',
      timewantedmode: 'asap',
      street: address.address1,
      city: address.city,
      zipcode: address.zipCode,
    };
    if (moment(timeWanted).isAfter(moment(), 'minute')) {
      requestBody = {
        ...requestBody,
        timewantedmode: 'advance',
        timewantedutc: moment(timeWanted).utc().format('YYYYMMDD HH:mm'),
      };
    }
    if (requestBody.handoffmode && requestBody.timewantedmode && requestBody.street && requestBody.city && requestBody.zipcode) {
      return this.oloAPI.checkRestaurantDelivery(restaurantID, requestBody).pipe(
        map(res => {
          return {
            canDeliver: res.candeliver,
            failureReason: res.candeliver ? '' : res.message,
          };
        })
      );
    } else {
      return throwError(
        new Error(
          'Something went wrong, this may not be a valid delivery address. Please try your address again or try entering a different address.'
        )
      );
    }
  }

  logIn(username: string, password: string): Observable<string> {
    return forkJoin({
      online: this.punchhAPI.logIn(username, password),
      // mobile: this.punchhAPI.mobileLogIn(username, password)
    }).pipe(
      switchMap(results => {
        return forkJoin({
          authToken: this.storePunchhAuthToken(results.online.authentication_token),
          accessToken: this.storePunchhAccessToken(results.online.access_token),
          mobileAccessToken: this.storePunchhMobileAccessToken(results.online.access_token),
        }).pipe(
          switchMap(() => {
            // tslint:disable-next-line:max-line-length
            return this.oloAPI
              .createOrGetSSOLinkedOLO('punchh', results.online.access_token || results.online.authentication_token, this.getBasketGuid())
              .pipe(
                switchMap(user => {
                  return this.storeOLOAuthToken(user.authtoken).pipe(
                    map(() => {
                      return user.authtoken;
                    })
                  );
                }),
                catchError(err => {
                  return throwError(this.oloMapping.ssoLoginError(err));
                })
              );
          })
        );
      }),
      catchError(err => {
        return throwError(this.punchhMapping.loginError(err));
      })
    );
  }

  logInWithToken(token: string, redirectURL: string): Observable<string> {
    return this.punchhAPI.loginWithSSOToken(token).pipe(
      switchMap(ssoRes => {
        return forkJoin({
          authToken: this.storePunchhAuthToken(ssoRes.user.authentication_token),
          accessToken: this.storePunchhAccessToken(ssoRes.token.access_token),
          mobileAccessToken: this.storePunchhMobileAccessToken(ssoRes.token.access_token),
        }).pipe(
          switchMap(() => {
            // tslint:disable-next-line:max-line-length
            return this.oloAPI.createOrGetSSOLinkedOLO('punchh', ssoRes.token.access_token, this.getBasketGuid()).pipe(
              switchMap(user => {
                return this.storeOLOAuthToken(user.authtoken).pipe(
                  map(() => {
                    return user.authtoken;
                  })
                );
              }),
              catchError(err => throwError(this.oloMapping.ssoLoginError(err)))
            );
          })
        );
      })
    );
  }

  logInWithFacebook(email: string, accessToken: string, userID: string): Observable<string> {
    return forkJoin({
      online: this.punchhAPI.connectWithFacebook(email, accessToken, userID),
      mobile: this.punchhAPI.mobileConnectWithFacebook(accessToken),
    }).pipe(
      switchMap(results => {
        return forkJoin({
          auth: this.storePunchhAuthToken(results.online.authentication_token),
          mAccess: this.storePunchhMobileAccessToken(results.mobile.access_token.token),
        }).pipe(
          switchMap(() => {
            // tslint:disable-next-line:max-line-length
            return this.oloAPI
              .createOrGetSSOLinkedOLO('punchh', results.online.access_token || results.online.authentication_token, this.getBasketGuid())
              .pipe(
                switchMap(user => {
                  return this.storeOLOAuthToken(user.authtoken).pipe(
                    map(() => {
                      return user.authtoken;
                    })
                  );
                }),
                catchError(err => {
                  return throwError(this.oloMapping.ssoLoginError(err));
                })
              );
          })
        );
      }),
      catchError(err => {
        return throwError(this.punchhMapping.loginError(err));
      })
    );
  }

  connectWithFacebook(email: string, accessToken: string, userID: string): Observable<any> {
    return throwError('Not Implemented');
  }

  logInWithApple(appleResult: SignInWithAppleResponse, redirectURI: string): Observable<string> {
    return this.getPunchhAppleSignInUser().pipe(
      switchMap(pASIUser => {
        const ssoBody: SignInWithAppleRequest = {
          client: '',
          redirect_uri: redirectURI,
          user: {
            first_name: appleResult.response.givenName ? appleResult.response.givenName : pASIUser.first_name,
            last_name: appleResult.response.familyName ? appleResult.response.familyName : pASIUser.last_name,
            email: appleResult.response.email ? appleResult.response.email : pASIUser.email,
            external_source_id: appleResult.response.user ? appleResult.response.user : pASIUser.external_source_id,
            authorization_code: appleResult.response.authorizationCode,
          },
        };
        const mobileBody: MobileSignInWithAppleRequest = {
          ...ssoBody.user,
          gender: null,
          external_source: 'Apple',
        };
        return forkJoin({
          // sso: this.punchhAPI.signInWithApple(ssoBody),
          mobile: this.punchhAPI.mobileSignInWithApple(mobileBody),
          apple: this.storePunchhAppleSignInUser(ssoBody.user),
        }).pipe(
          switchMap(tokens => {
            console.log(tokens);
            return this.punchhAPI.getUserInfoByAccessToken(tokens.mobile.access_token.token).pipe(
              switchMap(res => {
                return forkJoin({
                  // tslint:disable-next-line:max-line-length
                  auth: this.storePunchhAuthToken(res.authentication_token), // TODO: Update this with Punchh process of getting auth tokens
                  access: this.storePunchhAccessToken(res.access_token),
                  mAccess: this.storePunchhMobileAccessToken(tokens.mobile.access_token.token),
                }).pipe(
                  switchMap(() => {
                    // tslint:disable-next-line:max-line-length
                    return this.oloAPI
                      .createOrGetSSOLinkedOLO('punchh', res.access_token || res.authentication_token, this.getBasketGuid())
                      .pipe(
                        switchMap(user => {
                          return this.storeOLOAuthToken(user.authtoken).pipe(
                            map(() => {
                              return user.authtoken;
                            })
                          );
                        }),
                        catchError(err => {
                          return throwError(this.oloMapping.ssoLoginError(err));
                        })
                      );
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  connectWithApple(appleResult: SignInWithAppleResponse, redirectURI: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  logOut(userID: string): Observable<string> {
    return this.oloAPI.disableAuthToken(userID).pipe(
      map(userDetails => {
        this.removePunchhAuthToken();
        this.removePunchhAccessToken();
        this.removeOLOAuthToken();
        this.removePunchhMobileAccessToken();
        this.storePunchhOauthCode('');
        this.storePunchhOauthJwtToken('');
        return userDetails.authtoken;
      })
    );
  }

  forgotPassword(email: string): Observable<any> {
    return this.punchhAPI.sendForgotPasswordEmail(email);
  }

  changePassword(email: string, oldPassword: string, newPassword: string): Observable<any> {
    return this.getPunchhAuthToken().pipe(
      switchMap(token => {
        return this.punchhAPI.changePassword(newPassword, token);
      })
    );
  }

  resetPassword(newPassword: string): Observable<PassResetResponse> {
    throw new Error('Method not implemented.');
  }

  // tslint:disable-next-line: max-line-length
  updateUserInfo(user: User): Observable<User> {
    return this.oloAPI.updateUserDetails(user.userID, user.firstName, user.lastName, user.email).pipe(
      switchMap(userDetails => {
        return this.storeOLOAuthToken(userDetails.authtoken).pipe(
          switchMap(() => {
            return this.oloAPI.updateUserContactNumber(userDetails.authtoken, user.phoneNumber).pipe(
              switchMap(contact => {
                const prefs = {
                  marketingsms: String(user.sMSOptIn),
                  optin: String(user.emailOptIn),
                  upsell: 'true',
                  emailreceipts: 'true',
                  followups: 'true',
                };
                if (user.favoriteLocation) {
                  return this.getPunchhAuthToken().pipe(
                    switchMap(punchhToken => {
                      const u = {
                        favourite_location_ids: user.favoriteLocation.locationID,
                      } as UpdateUserInfoRequest;
                      return this.punchhAPI.updateUserInfo(punchhToken, u).pipe(
                        switchMap(() => {
                          return this.getOLOAuthToken().pipe(
                            switchMap(token => {
                              return this.oloAPI.updateUserCommunicationPreferences(token, prefs).pipe(
                                map(newPrefs => {
                                  return {
                                    ...this.oloMapping.userDetailsToUser(userDetails),
                                    phoneNumber: contact.contactdetails,
                                    emailOptIn: newPrefs.optin === 'true',
                                    sMSOptIn: newPrefs.marketingsms === 'true',
                                  };
                                })
                              );
                            })
                          );
                        })
                      );
                    })
                  );
                } else {
                  return this.getOLOAuthToken().pipe(
                    switchMap(token => {
                      return this.oloAPI.updateUserCommunicationPreferences(token, prefs).pipe(
                        map(newPrefs => {
                          return {
                            ...this.oloMapping.userDetailsToUser(userDetails),
                            phoneNumber: contact.contactdetails,
                            emailOptIn: newPrefs.optin === 'true',
                            sMSOptIn: newPrefs.marketingsms === 'true',
                          };
                        })
                      );
                    })
                  );
                }
              })
            );
          })
        );
      })
    );
  }

  getProviderUpsells(basketGuid: string | number): Observable<Upsells> {
    return this.oloAPI.getEligibleUpsellItems(basketGuid).pipe(
      map(res => {
        return this.oloMapping.toUpsell(res);
      })
    );
  }

  addProviderUpsell(basketGuid: string, body: AddUpsellItemsRequest): Observable<Order> {
    return this.oloAPI.addUpsellItemsToBasket(basketGuid, body).pipe(
      switchMap(res => {
        return this.getLocation(res.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
              switchMap(billingSchemes => {
                const order = this.oloMapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          }),
          catchError(err => {
            return throwError(this.oloMapping.oloError(err));
          })
        );
      }),
      catchError(err => {
        return throwError(this.oloMapping.oloError(err));
      })
    );
  }

  createAccount(newAccount: CreateAccount, additionalFields: UserField[]): Observable<User> {
    const req = {
      email: newAccount.email,
      first_name: newAccount.firstName,
      last_name: newAccount.lastName,
      password: newAccount.password,
      phone: newAccount.phone,
      fav_location_id: newAccount.favoriteLocation ? newAccount.favoriteLocation.locationID : '',
    } as UserRequest;
    if (additionalFields && additionalFields.length > 0) {
      additionalFields.forEach(field => {
        req[field.providerFieldName] = field.value;
      });
    }
    return this.punchhAPI.createUser(req).pipe(
      switchMap(user => {
        return forkJoin({
          auth: this.storePunchhAuthToken(user.authentication_token),
          access: this.storePunchhAccessToken(user.access_token),
        }).pipe(
          switchMap(() => {
            return this.oloAPI.createOrGetSSOLinkedOLO('punchh', user.access_token, this.getBasketGuid()).pipe(
              switchMap(auth => {
                this.storeOLOAuthToken(auth.authtoken);
                const prefs = {
                  marketingsms: String(newAccount.smsOptIn),
                  optin: String(newAccount.emailOptIn),
                  upsell: 'true',
                  emailreceipts: 'true',
                  followups: 'true',
                };
                return this.getOLOAuthToken().pipe(
                  switchMap(token => {
                    return this.getLoyaltyLocations().pipe(
                      switchMap(locations => {
                        return this.oloAPI.updateUserCommunicationPreferences(token, prefs).pipe(
                          map(newPrefs => {
                            let newUser = this.punchhMapping.toUser(user, locations);
                            newUser = {
                              ...newUser,
                              emailOptIn: newPrefs.optin === 'true',
                              sMSOptIn: newPrefs.marketingsms === 'true',
                            };
                            return newUser;
                          }),
                          catchError(err => throwError(this.oloMapping.oloError(err)))
                        );
                      })
                    );
                  })
                );
              }),
              catchError(err => throwError(this.oloMapping.oloError(err)))
            );
          })
        );
      }),
      catchError(err => {
        if (err instanceof DineEngineError) {
          return throwError(err);
        } else {
          return throwError(this.punchhMapping.createAccountError(err));
        }
      })
    );
  }

  logInAsGuest(): Observable<string> {
    return of(null);
  }

  getUserInfo(userID: string): Observable<User> {
    return this.getOLOAuthToken().pipe(
      switchMap(oToken => {
        return this.oloAPI.getUserDetails(oToken).pipe(
          switchMap(userDetails => {
            return this.oloAPI.getUserContactNumber(oToken).pipe(
              switchMap(contact => {
                return this.oloAPI.getUserCommunicationPreferences(oToken).pipe(
                  switchMap(userPrefs => {
                    return this.getPunchhAuthToken().pipe(
                      switchMap(pToken => {
                        return this.getLoyaltyLocations().pipe(
                          switchMap(locations => {
                            if (pToken) {
                              return this.punchhAPI.getUserInfo(pToken).pipe(
                                map(res => {
                                  return {
                                    ...this.oloMapping.userDetailsToUser(userDetails),
                                    userAsBarcode: res.user_as_barcode,
                                    userAsQrCode: res.user_as_qrcode,
                                    phoneNumber: contact.contactdetails,
                                    emailOptIn: userPrefs.optin === 'true',
                                    sMSOptIn: userPrefs.marketingsms === 'true',
                                    favoriteLocation: res.favourite_locations
                                      ? locations.find(l => l.locationID === res.favourite_locations)
                                      : null,
                                    providerSpecificFields: res,
                                  };
                                })
                              );
                            } else {
                              return this.getPunchhAccessToken().pipe(
                                switchMap(aToken => {
                                  return this.punchhAPI.getUserInfoByAccessToken(aToken).pipe(
                                    switchMap(res => {
                                      return this.storePunchhAuthToken(res.authentication_token).pipe(
                                        map(() => {
                                          return {
                                            ...this.oloMapping.userDetailsToUser(userDetails),
                                            userAsBarcode: res.user_as_barcode,
                                            userAsQrCode: res.user_as_qrcode,
                                            phoneNumber: contact.contactdetails,
                                            emailOptIn: userPrefs.optin === 'true',
                                            sMSOptIn: userPrefs.marketingsms === 'true',
                                            favoriteLocation: res.favourite_locations
                                              ? locations.find(l => l.locationID === res.favourite_locations)
                                              : null,
                                            providerSpecificFields: res,
                                          };
                                        })
                                      );
                                    })
                                  );
                                })
                              );
                            }
                          })
                        );
                      })
                    );
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  getLoggedInUserInfo(): Observable<User> {
    return this.getPunchhMobileAccessToken().pipe(
      switchMap(pMAToken => {
        if (pMAToken) {
          return this.oloAPI.createOrGetSSOLinkedOLO('punchh', pMAToken, this.getBasketGuid()).pipe(
            switchMap(oToken => {
              return this.storeOLOAuthToken(oToken.authtoken).pipe(
                switchMap(() => {
                  return this.getUserInfo(oToken.authtoken);
                })
              );
            })
          );
        } else {
          return this.getPunchhOauthCode().pipe(
            switchMap(pOCode => {
              if (pOCode) {
                return this.getPunchhOauthToken().pipe(
                  switchMap(() => {
                    return this.getPunchhAccessToken().pipe(
                      switchMap(aToken => {
                        // tslint:disable-next-line:max-line-length
                        return this.oloAPI.createOrGetSSOLinkedOLO('punchh', aToken, this.getBasketGuid()).pipe(
                          switchMap(oToken => {
                            return this.storeOLOAuthToken(oToken.authtoken).pipe(
                              switchMap(() => {
                                return this.getUserInfo(oToken.authtoken);
                              })
                            );
                          })
                        );
                      })
                    );
                  })
                );
              } else {
                return of(null);
              }
            })
          );
        }
      })
    );
  }

  getPunchhOauthToken(): Observable<string> {
    return this.punchhOauthToken ? of(this.punchhOauthToken) : this.punchhOauthTokenSubject.asObservable();
  }

  is3rdPartyWrapped(): boolean {
    return localStorage.getItem('is3rdPartyWrapped') === 'true';
  }

  getCurrentOrder(userID: string): Observable<Order> {
    const basketGuid = this.getBasketGuid();
    if (basketGuid) {
      return this.oloAPI.getBasket(basketGuid).pipe(
        switchMap(basketRes => {
          return this.getLocation(basketRes.vendorid).pipe(
            switchMap(location => {
              return this.oloAPI.getAllBillingSchemesAndAccounts(basketRes.id).pipe(
                switchMap(billingSchemes => {
                  const order = this.oloMapping.createBasketResponseToOrder(basketRes, location, billingSchemes.billingschemes);
                  return this.contentService.getOrderItemsWithSlugsandImages(order);
                })
              );
            })
          );
        })
      );
    } else {
      return of(null);
    }
  }

  clearOrder(): Observable<string> {
    this.removeBasketGuid();
    return of(this.getBasketGuid());
  }

  startOrder(userID: string, locationID: string, handoffType: HandoffType, tableNumber?: string, address?: Address): Observable<Order> {
    if (isNaN(locationID as any)) {
      // URL contains a slug
      return this.directusService.getSingleLocationBySlug(locationID).pipe(
        switchMap(locationInfo => {
          return this.startOrder(userID, locationInfo.menu_id, handoffType);
        })
      );
    } else {
      return this.getOLOAuthToken().pipe(
        switchMap(oToken => {
          return this.oloAPI.createBasket(parseInt(locationID, 10), oToken).pipe(
            switchMap(basketResponse => {
              this.storeBasketGuid(basketResponse.id);
              if (handoffType === HandoffType.delivery || handoffType === HandoffType.dispatch) {
                return this.setDispatchOrDeliveryAddress(basketResponse.id, userID, address, handoffType).pipe(
                  switchMap(() => {
                    return this.completeOrder(basketResponse, handoffType, userID);
                  })
                );
              } else {
                return this.completeOrder(basketResponse, handoffType, userID);
              }
            })
          );
        })
      );
    }
  }

  private completeOrder(basketResponse, handoffType, userID) {
    this.setReferralToken(basketResponse.id);
    if (handoffType !== HandoffType.delivery) {
      return this.oloAPI.setHandoffMethod(basketResponse.id, this.oloMapping.handoffTypeToHandoffType(handoffType)).pipe(
        switchMap(res => {
          return this.getLocation(res.vendorid).pipe(
            switchMap(location => {
              return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
                switchMap(billingSchemes => {
                  const order = this.oloMapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                  // tslint:disable-next-line:max-line-length
                  // if (order.isASAP && !moment().isBetween(moment(order.location.pickupHours[1].start), moment(order.location.pickupHours[1].end).subtract(15, 'minutes')) && !moment().isBetween(moment(order.location.pickupHours[0].start), moment(order.location.pickupHours[0].end).subtract(15, 'minutes'))) {
                  // tslint:disable-next-line:max-line-length
                  //         return this.setTimePreference(order.orderID, userID, order.earliestReadyTimestamp).pipe(switchMap(updatedOrder => {
                  //             return this.contentService.getOrderItemsWithSlugsandImages(updatedOrder);
                  //         }));
                  // } else {
                  return this.contentService.getOrderItemsWithSlugsandImages(order);
                  // }
                })
              );
            })
          );
        }),
        catchError(() => {
          return of(null);
        })
      );
    } else {
      return this.getLocation(basketResponse.vendorid).pipe(
        switchMap(location => {
          return this.oloAPI.getAllBillingSchemesAndAccounts(basketResponse.id).pipe(
            switchMap(billingSchemes => {
              // tslint:disable-next-line:max-line-length
              const order = this.oloMapping.createBasketResponseToOrder(basketResponse, location, billingSchemes.billingschemes);
              // tslint:disable-next-line:max-line-length
              // if (order.isASAP && !moment().isBetween(moment(order.location.pickupHours[1].start), moment(order.location.pickupHours[1].end).subtract(15, 'minutes')) && !moment().isBetween(moment(order.location.pickupHours[0].start), moment(order.location.pickupHours[0].end).subtract(15, 'minutes'))) {
              // tslint:disable-next-line:max-line-length
              //         return this.setTimePreference(order.orderID, userID, order.earliestReadyTimestamp).pipe(switchMap(updatedOrder => {
              //             return this.contentService.getOrderItemsWithSlugsandImages(updatedOrder);
              //         }));
              // } else {
              return this.contentService.getOrderItemsWithSlugsandImages(order);
              // }
            })
          );
        })
      );
    }
  }

  private setReferralToken(orderID: string): void {
    this.getRWGToken().subscribe(token => {
      if (token) {
        const referral: OloReferral = {
          source: 'rwg_token',
          token,
        };
        this.oloAPI.addReferralToBasket(orderID, [referral]).subscribe(() => {
          this.removeRWGToken();
        });
      }
    });
  }

  transferBasket(orderID: string, locationID: string): Observable<Order> {
    if (isNaN(locationID as any)) {
      // URL contains a slug
      return this.directusService.getSingleLocationBySlug(locationID).pipe(
        switchMap(locationInfo => {
          return this.transferBasket(orderID, locationInfo.menu_id);
        })
      );
    } else {
      return this.oloAPI.transferBasketToAnotherRestaurant(orderID, parseInt(locationID, 10)).pipe(
        switchMap(transferResponse => {
          this.storeBasketGuid(transferResponse.basket.id);
          return this.getLocation(transferResponse.basket.vendorid).pipe(
            switchMap(location => {
              return this.oloAPI.getAllBillingSchemesAndAccounts(transferResponse.basket.id).pipe(
                switchMap(billingSchemes => {
                  // tslint:disable-next-line:max-line-length
                  const order = this.oloMapping.createBasketResponseToOrder(
                    transferResponse.basket,
                    location,
                    billingSchemes.billingschemes
                  );
                  transferResponse.itemsnottransferred.forEach(item => {
                    this.toast.info(`${item} is not available at this location, it has been removed from your cart`);
                  });
                  return this.contentService.getOrderItemsWithSlugsandImages(order);
                })
              );
            })
          );
        })
      );
    }
  }

  getAvailableOrderDays(locationID: string | number, orderID: string | number): Observable<Date[]> {
    return this.getLocation(Number(locationID)).pipe(
      map(restaurant => {
        const { orderAheadDays } = restaurant;

        // Get the timezone name from coordinates
        const timezone = tzlookup(restaurant.address.latitude, restaurant.address.longitude);

        const today = moment.tz(timezone).startOf('day');

        // Generate available days
        const days: Date[] = Array.from({ length: orderAheadDays }, (_, i) => {
          return today.clone().add(i, 'days').toDate();
        });

        return days;
      })
    );
  }

  getAvailableOrderTimes(locationID: string | number, orderID: string | number, date: Date): Observable<moment.Moment[]> {
    return this.getLocation(Number(locationID)).pipe(
      switchMap(restaurant => {
        // Get the timezone name from coordinates
        const timezone = tzlookup(restaurant.address.latitude, restaurant.address.longitude);

        return this.oloAPI.getAvailableTimeWantedTimes(Number(locationID), String(orderID), date, timezone).pipe(
          map(availableTimes => {
            return availableTimes.times.map(time => moment.tz(time.time, 'YYYYMMDD HH:mm', timezone));
          })
        );
      })
    );
  }

  validateOrder(userID: string, locationID: number, orderID: string): Observable<Order> {
    return this.oloAPI.validateBasket(orderID, true).pipe(
      switchMap(res => {
        return this.oloAPI.getBasket(res.basketid).pipe(
          switchMap(basket => {
            return this.getLocation(locationID).pipe(
              switchMap(location => {
                return this.oloAPI.getAllBillingSchemesAndAccounts(basket.id).pipe(
                  switchMap(billingSchemes => {
                    const order = this.oloMapping.createBasketResponseToOrder(basket, location, billingSchemes.billingschemes);
                    return this.contentService.getOrderItemsWithSlugsandImages(order);
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  addToOrder(orderItem: OrderItem, orderID: string, userID: string): Observable<Order> {
    return this.oloAPI.addProductsToBasket(orderID, this.oloMapping.orderItemToAddProductsBody(orderItem)).pipe(
      switchMap(res => {
        if (res.errors.length) {
          // map all errors into one sentence and throw error
          const error = res.errors.map(e => e.message).join(' ');
          return throwError(error);
        }
        return this.getLocation(res.basket.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(res.basket.id).pipe(
              switchMap(billingSchemes => {
                const order = this.oloMapping.createBasketResponseToOrder(res.basket, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  updateBasketItem(orderItem: OrderItem, orderID: string, userID: string, quant: number): Observable<Order> {
    return this.oloAPI.updateProductsInBasket(orderID, this.oloMapping.orderItemToAddProductsBody(orderItem, quant)).pipe(
      switchMap(res => {
        if (res.errors.length) {
          // map all errors into one sentence and throw error
          const error = res.errors.map(e => e.message).join(' ');
          return throwError(error);
        }
        return this.getLocation(res.basket.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(res.basket.id).pipe(
              switchMap(billingSchemes => {
                const order = this.oloMapping.createBasketResponseToOrder(res.basket, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  removeFromOrder(orderItem: OrderItem, orderID: string, userID: string): Observable<Order> {
    return this.oloAPI.removeProductFromBasket(orderID, Number(orderItem.orderItemID)).pipe(
      switchMap(res => {
        return this.getLocation(res.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
              switchMap(billingSchemes => {
                const order = this.oloMapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  cancelOrder(orderID: string): Observable<Order> {
    return this.oloAPI.cancelOrder(orderID).pipe(
      switchMap(res => {
        return this.getLocation(res.vendorid).pipe(
          map(location => {
            return this.oloMapping.orderToOrder(res, location);
          })
        );
      })
    );
  }

  editOrder(orderID: string): Observable<Order> {
    return this.oloAPI.editOrder(orderID).pipe(
      switchMap(res => {
        return this.getLocation(res.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
              map(billingSchemes => {
                this.storeBasketGuid(res.id);
                return this.oloMapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
              })
            );
          })
        );
      })
    );
  }

  setTimePreference(orderID: string, userID: string, time: Date): Observable<Order> {
    return this.oloAPI.getBasket(orderID).pipe(
      switchMap(basket => {
        return this.oloAPI.getRestaurant(basket.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.setBasketTimeWanted(orderID, moment(time).utcOffset(location.utcoffset)).pipe(
              switchMap(basketRes => {
                return this.oloAPI.getAllBillingSchemesAndAccounts(basketRes.id).pipe(
                  switchMap(billingSchemes => {
                    const order = this.oloMapping.createBasketResponseToOrder(
                      basketRes,
                      this.oloMapping.restaurantToLocation(location),
                      billingSchemes.billingschemes
                    );
                    return this.contentService.getOrderItemsWithSlugsandImages(order);
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  setTimePreferenceToASAP(orderID: string, userID: string): Observable<Order> {
    return this.oloAPI.setBasketTimeModeToASAP(orderID).pipe(
      switchMap(basketRes => {
        return this.getLocation(basketRes.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(basketRes.id).pipe(
              switchMap(billingSchemes => {
                const order = this.oloMapping.createBasketResponseToOrder(basketRes, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  setHandoffType(orderID: string, userID: string, handoffType: HandoffType): Observable<Order> {
    let oloHandoffType;
    if (typeof handoffType !== 'string') {
      oloHandoffType = this.oloMapping.handoffTypeToHandoffType(handoffType);
    } else {
      oloHandoffType = this.oloMapping.handoffTypeToString(handoffType);
    }
    return this.oloAPI.setHandoffMethod(orderID, oloHandoffType).pipe(
      switchMap(basketRes => {
        return this.getLocation(basketRes.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(basketRes.id).pipe(
              switchMap(billingSchemes => {
                const order = this.oloMapping.createBasketResponseToOrder(basketRes, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      }),
      catchError(() => {
        return of(null);
      })
    );
  }

  // tslint:disable-next-line: max-line-length
  setDispatchOrDeliveryAddress(orderID: string, userID: string, address: Address, handoffType: HandoffType): Observable<Order> {
    if (userID) {
      return this.getUserInfo(userID).pipe(
        switchMap(() => {
          const oloAddress = {
            city: address.city,
            streetaddress: address.address1,
            building: address.address2 ? address.address2 : null,
            zipcode: address.zipCode,
            // phonenumber: user.phoneNumber,
            isdefault: false,
            specialinstructions: address.specialInstructions ? address.specialInstructions : null,
          } as OloAddress;
          return this.oloAPI.getBasket(orderID).pipe(
            switchMap(() => {
              if (handoffType === HandoffType.delivery) {
                return this.oloAPI.setBasketDeliveryAddress(orderID, oloAddress).pipe(
                  switchMap(res => {
                    return this.getLocation(res.vendorid).pipe(
                      switchMap(location => {
                        return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
                          switchMap(billingSchemes => {
                            const order = this.oloMapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                            return this.contentService.getOrderItemsWithSlugsandImages(order);
                          })
                        );
                      })
                    );
                  })
                );
              } else {
                return this.oloAPI.setBasketDispatchAddress(orderID, oloAddress).pipe(
                  switchMap(res => {
                    return this.getLocation(res.vendorid).pipe(
                      switchMap(location => {
                        return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
                          switchMap(billingSchemes => {
                            const order = this.oloMapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                            return this.contentService.getOrderItemsWithSlugsandImages(order);
                          })
                        );
                      })
                    );
                  })
                );
              }
            })
          );
        })
      );
    } else {
      const oloAddress = {
        city: address.city,
        streetaddress: address.address1,
        building: address.address2 ? address.address2 : null,
        zipcode: address.zipCode,
        // phonenumber: '00000000000',
        isdefault: false,
        id: 0,
        specialinstructions: address.specialInstructions ? address.specialInstructions : null,
      } as OloAddress;
      return this.oloAPI.getBasket(orderID).pipe(
        switchMap(() => {
          if (handoffType === HandoffType.delivery) {
            return this.oloAPI.setBasketDeliveryAddress(orderID, oloAddress).pipe(
              switchMap(res => {
                return this.getLocation(res.vendorid).pipe(
                  switchMap(location => {
                    return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
                      switchMap(billingSchemes => {
                        const order = this.oloMapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                        return this.contentService.getOrderItemsWithSlugsandImages(order);
                      })
                    );
                  })
                );
              })
            );
          } else {
            return this.oloAPI.setBasketDispatchAddress(orderID, oloAddress).pipe(
              switchMap(res => {
                return this.getLocation(res.vendorid).pipe(
                  switchMap(location => {
                    return this.oloAPI.getAllBillingSchemesAndAccounts(res.id).pipe(
                      switchMap(billingSchemes => {
                        const order = this.oloMapping.createBasketResponseToOrder(res, location, billingSchemes.billingschemes);
                        return this.contentService.getOrderItemsWithSlugsandImages(order);
                      })
                    );
                  })
                );
              })
            );
          }
        })
      );
    }
  }

  checkDeliveryStatus(orderGuid: string, authToken: string): Observable<any> {
    if (authToken) {
      return this.oloAPI.checkOrderDispatchStatus(orderGuid, authToken);
    } else {
      return this.oloAPI.checkGuestOrderDispatchStatus(orderGuid);
    }
  }

  getOrderStatus(orderGuid: string): Observable<Order> {
    return this.oloAPI.checkOrderStatus(orderGuid).pipe(
      switchMap(order => {
        return this.getLocation(order.vendorid).pipe(
          map(location => {
            return this.oloMapping.orderToOrder(order, location);
          })
        );
      })
    );
  }

  setOrderLocation(userID: string, locationID: number, orderID: string): Observable<Order> {
    return this.oloAPI.transferBasketToAnotherRestaurant(orderID, locationID).pipe(
      switchMap(transfer => {
        return this.getLocation(transfer.basket.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(transfer.basket.id).pipe(
              map(billingSchemes => {
                return this.oloMapping.transferBasketResponseToOrder(transfer, location, billingSchemes.billingschemes);
              })
            );
          })
        );
      })
    );
  }

  setTip(userID: string, orderID: string, tipCents: number): Observable<Order> {
    return this.oloAPI.setTip(orderID, tipCents).pipe(
      switchMap(basketRes => {
        return this.getLocation(basketRes.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(basketRes.id).pipe(
              switchMap(billingSchemes => {
                const order = this.oloMapping.createBasketResponseToOrder(basketRes, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  setBasketCustomfield(orderId: string, customId: number | string, value: any): Observable<Order> {
    return this.oloAPI.setBasketCustomFieldValue(orderId, customId, value).pipe(
      switchMap(basketRes => {
        return this.getLocation(basketRes.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(basketRes.id).pipe(
              switchMap(billingSchemes => {
                const order = this.oloMapping.createBasketResponseToOrder(basketRes, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  // tslint:disable-next-line:max-line-length
  submitPayment(
    orderID: string,
    cardDetails: CardDetails,
    applyCents: number,
    shouldSave: boolean,
    token: string,
    inst?: string
  ): Observable<Order> {
    console.log('starting submit', cardDetails);
    return this.getOLOAuthToken().pipe(
      switchMap(id => {
        if (!id) {
          return of([null])
            .pipe(concatMap(item => of(item).pipe(delay(1000))))
            .pipe(
              switchMap(() => {
                return this.submitPayment(orderID, cardDetails, applyCents, shouldSave, token, inst);
              })
            );
        } else {
          // tslint:disable-next-line:max-line-length
          return this.oloAPI.validateBasket(orderID, false).pipe(
            switchMap(order => this.setTableNumber(order.basketid, sessionStorage.getItem('tablenumber'), cardDetails.firstName)),
            switchMap(order => {
              this.customData = [];
              if (cardDetails.paymentType === PaymentTypes.cash) {
                const body = {
                  authtoken: id,
                  billingmethod: 'cash',
                  usertype: 'user',
                } as SubmitOrderRequestWithCashAsUser;
                return this.oloAPI.submitOrderWithSinglePayment(order.orderID, body).pipe(
                  switchMap(res => {
                    this.storeBasketGuid('');
                    sessionStorage.setItem('deliveryBasketGuid', res.id);
                    return this.getLocation(res.vendorid).pipe(
                      map(location => {
                        return this.oloMapping.orderToOrder(res, location);
                      })
                    );
                  })
                );
              } else if (cardDetails.isMultiPayment) {
                // Multi payments
                return this.oloAPI.getAllBillingSchemesAndAccounts(orderID).pipe(
                  switchMap(schemes => {
                    const billingscheme = schemes.billingschemes.find((sche: any) => sche.type === 'giftcard');
                    const billingAccounts: Billingaccount[] = [];
                    cardDetails.multiPaymentBillingAccounts.forEach(ba => {
                      if (ba.billingfields && ba.billingfields.length) {
                        ba.billingschemeid = billingscheme.id;
                        ba.billingmethod = 'storedvalue';
                      } else if (ba.savedcardid) {
                        ba.billingmethod = 'billingaccount';
                      } else if (ba.token && !ba.isDigitalWallet) {
                        ba.billingmethod = 'creditcardtoken';
                      } else if (ba.token && ba.isDigitalWallet) {
                        ba.billingschemeid = this.findDigitalWalletBillingScheme(schemes.billingschemes, true, '');
                        ba.billingmethod = 'digitalwallet';
                      } else {
                        ba.billingmethod = 'creditcard';
                      }
                      const billingField = {
                        billingmethod: ba.billingmethod,
                        amount: Number((ba.amount / 100).toFixed(2)),
                        tipportion: Number((ba.tipportion / 100).toFixed(2)),
                        cardnumber: ba.cardnumber ? ba.cardnumber : null,
                        expiryyear: ba.expiryyear
                          ? String(ba.expiryyear).length === 4
                            ? Number(ba.expiryyear)
                            : Number('20' + ba.expiryyear)
                          : null,
                        expirymonth: ba.expirymonth ? ba.expirymonth : null,
                        cvv: ba.cvv ? ba.cvv : null,
                        streetaddress: ba.streetaddress ? ba.streetaddress : null,
                        streetaddress2: ba.streetaddress2 ? ba.streetaddress2 : null,
                        city: ba.city ? ba.city : null,
                        state: ba.state ? ba.state : null,
                        zip: ba.zip ? ba.zip : null,
                        country: ba.country ? ba.country : null,
                        saveonfile: ba.saveonfile ? String(ba.saveonfile) : null,
                        billingaccountid: ba.savedcardid ? ba.savedcardid : null,
                        billingschemeid: ba.billingschemeid ? ba.billingschemeid : null,
                        billingfields: ba.billingfields ? ba.billingfields : null,
                        token: ba.token ? ba.token : null,
                        cardtype: ba.cardtype ? ba.cardtype : null,
                        cardlastfour: ba.cardlastfour ? ba.cardlastfour : null,
                      } as Billingaccount;
                      billingAccounts.push(billingField);
                    });
                    let body: SubmitOrderMultiPaymentRequest;
                    body = {
                      authtoken: id,
                      usertype: 'user',
                      firstname: null,
                      lastname: null,
                      emailaddress: null,
                      contactnumber: cardDetails.phoneNumber,
                      reference: null,
                      orderref: null,
                      customdata: this.customData,
                      guestoptin: cardDetails.guestOptIn ? true : false,
                      billingaccounts: billingAccounts,
                    } as SubmitOrderMultiPaymentRequest;
                    return this.oloAPI.submitOrderWithMultiplePayments(orderID, body).pipe(
                      switchMap(res => {
                        this.storeBasketGuid('');
                        sessionStorage.setItem('deliveryBasketGuid', res.id);
                        return this.getLocation(res.vendorid).pipe(
                          map(location => {
                            return this.oloMapping.orderToOrder(res, location);
                          })
                        );
                      })
                    );
                  })
                );
              } else {
                return this.oloAPI.getAllBillingSchemesAndAccounts(orderID).pipe(
                  switchMap(schemes => {
                    const billingscheme = schemes.billingschemes.find((sche: any) => sche.type === 'giftcard');
                    // tslint:disable-next-line: max-line-length
                    let body:
                      | SubmitOrderRequestWithCreditCardAsUser
                      | SubmitOrderRequestWithGiftCardAsUser
                      | SubmitOrderRequestWithSavedCardAsUser
                      | SubmitOrderWithPrepaidTransactionAsUser
                      | SubmitOrderRequestWithCreditCardTokenAsUser;
                    if (billingscheme && billingscheme.id && cardDetails.isGiftCard && !cardDetails.isSavedCard) {
                      body = {
                        authtoken: id,
                        billingmethod: 'storedvalue',
                        billingschemeid: billingscheme.id,
                        billingfields: [
                          {
                            name: 'number',
                            value: cardDetails.giftCardNumber.replace(/-/g, ''),
                          },
                        ],
                        usertype: 'user',
                        contactnumber: cardDetails.phoneNumber.replace(/-/g, ''),
                        saveonfile: String(shouldSave),
                        customdata: this.customData,
                      } as SubmitOrderRequestWithGiftCardAsUser;
                      if (cardDetails.pin !== '') {
                        body.billingfields.push({
                          name: 'pin',
                          value: cardDetails.pin,
                        });
                      }
                    } else if (cardDetails.isSavedCard) {
                      body = {
                        authtoken: id,
                        billingmethod: 'billingaccount',
                        billingaccountid: cardDetails.savedCardID,
                        usertype: 'user',
                        contactnumber: cardDetails.phoneNumber.replace(/-/g, ''),
                        saveonfile: 'false',
                        customdata: this.customData,
                      } as SubmitOrderRequestWithSavedCardAsUser;
                    } else if (cardDetails.paymentType === PaymentTypes.prepaid) {
                      body = {
                        authtoken: id,
                        billingmethod: 'prepaid',
                        usertype: 'user',
                        prepaidtransactionid: cardDetails.prepaidID,
                        prepaiddescription: cardDetails.prepaidDescription,
                      } as SubmitOrderWithPrepaidTransactionAsUser;
                    } else if (cardDetails.paymentType === PaymentTypes.creditCardToken) {
                      body = {
                        billingmethod: cardDetails.isDigitalWallet ? 'digitalwallet' : 'creditcardtoken',
                        // tslint:disable-next-line:max-line-length
                        billingschemeid: this.findDigitalWalletBillingScheme(
                          schemes.billingschemes,
                          cardDetails.isDigitalWallet,
                          cardDetails.digitalWalletType
                        ),
                        authtoken: id,
                        usertype: 'user',
                        contactnumber: cardDetails.phoneNumber,
                        token: cardDetails.token,
                        cardtype: cardDetails.cardType,
                        cardlastfour: cardDetails.cardLastFour,
                        expirymonth: Number(cardDetails.expirationMonth),
                        // tslint:disable-next-line:max-line-length
                        expiryyear:
                          String(cardDetails.expirationYear).length === 4
                            ? Number(cardDetails.expirationYear)
                            : Number('20' + cardDetails.expirationYear),
                        city: cardDetails.billingAddress.city,
                        state: cardDetails.billingAddress.state,
                        zip: cardDetails.billingAddress.zipCode,
                        streetaddress: cardDetails.billingAddress.address1,
                        streetaddress2: cardDetails.billingAddress.address2,
                        country: cardDetails.billingAddress.country ? cardDetails.billingAddress.country : 'US',
                        saveonfile: shouldSave ? 'true' : 'false',
                      } as SubmitOrderRequestWithCreditCardTokenAsUser;
                    } else {
                      body = {
                        authtoken: id,
                        billingmethod: 'creditcard',
                        usertype: 'user',
                        saveonfile: shouldSave ? 'true' : 'false',
                        cardnumber: cardDetails.cardNumber,
                        contactnumber: cardDetails.phoneNumber,
                        cvv: cardDetails.cvv,
                        expirymonth: Number(cardDetails.expirationMonth),
                        // tslint:disable-next-line:max-line-length
                        expiryyear:
                          String(cardDetails.expirationYear).length === 4
                            ? Number(cardDetails.expirationYear)
                            : Number('20' + cardDetails.expirationYear),
                        city: cardDetails.billingAddress.city,
                        state: cardDetails.billingAddress.state,
                        zip: cardDetails.billingAddress.zipCode,
                        streetaddress: cardDetails.billingAddress.address1,
                        streetaddress2: cardDetails.billingAddress.address2,
                        country: cardDetails.billingAddress.country ? cardDetails.billingAddress.country : 'US',
                        customdata: this.customData,
                      } as SubmitOrderRequestWithCreditCardAsUser;
                    }
                    const existingMode = sessionStorage.getItem('mode');
                    if (existingMode === 'tableside') {
                      body.receivinguser = {
                        firstname: cardDetails.firstName,
                        lastname: cardDetails.lastName,
                        emailaddress: cardDetails.emailAddress,
                        contactnumber: cardDetails.phoneNumber,
                      };
                    }
                    return this.oloAPI.submitOrderWithSinglePayment(orderID, body).pipe(
                      switchMap(res => {
                        this.storeBasketGuid('');
                        sessionStorage.setItem('deliveryBasketGuid', res.id);
                        return this.getLocation(res.vendorid).pipe(
                          map(location => {
                            return this.oloMapping.orderToOrder(res, location);
                          })
                        );
                      })
                    );
                  })
                );
              }
            })
          );
        }
      })
    );
  }

  // tslint:disable-next-line:max-line-length
  submitPaymentAsGuest(
    orderID: string,
    cardDetails: CardDetails,
    applyCents: number,
    shouldSave: boolean,
    token: string,
    inst?: string
  ): Observable<Order> {
    return this.oloAPI.validateBasket(orderID, false).pipe(
      switchMap(order => this.setTableNumber(order.basketid, sessionStorage.getItem('tablenumber'), cardDetails.firstName)),
      switchMap(order => {
        this.customData = [];
        if (cardDetails.paymentType === PaymentTypes.cash) {
          const body = {
            billingmethod: 'cash',
            usertype: 'guest',
            firstname: cardDetails.firstName,
            lastname: cardDetails.lastName,
            emailaddress: cardDetails.emailAddress,
            contactnumber: cardDetails.phoneNumber,
          } as SubmitOrderRequestWithCashAsGuest;
          return this.oloAPI.submitOrderWithSinglePayment(order.orderID, body).pipe(
            switchMap(res => {
              this.storeBasketGuid('');
              sessionStorage.setItem('deliveryBasketGuid', res.id);
              return this.getLocation(res.vendorid).pipe(
                map(location => {
                  return this.oloMapping.orderToOrder(res, location);
                })
              );
            })
          );
        } else if (cardDetails.isMultiPayment) {
          // Multi payments
          return this.oloAPI.getAllBillingSchemesAndAccounts(orderID).pipe(
            switchMap(schemes => {
              const billingscheme = schemes.billingschemes.find((sche: any) => sche.type === 'giftcard');
              const billingAccounts: Billingaccount[] = [];
              cardDetails.multiPaymentBillingAccounts.forEach(ba => {
                if (ba.billingfields && ba.billingfields.length) {
                  ba.billingschemeid = billingscheme.id;
                  ba.billingmethod = 'storedvalue';
                } else if (ba.savedcardid) {
                  ba.billingmethod = 'billingaccount';
                } else if (ba.token && !ba.isDigitalWallet) {
                  ba.billingmethod = 'creditcardtoken';
                } else if (ba.token && ba.isDigitalWallet) {
                  ba.billingschemeid = this.findDigitalWalletBillingScheme(schemes.billingschemes, true);
                  ba.billingmethod = 'digitalwallet';
                } else {
                  ba.billingmethod = 'creditcard';
                }
                const billingField = {
                  billingmethod: ba.billingmethod,
                  amount: Number((ba.amount / 100).toFixed(2)),
                  tipportion: Number((ba.tipportion / 100).toFixed(2)),
                  cardnumber: ba.cardnumber ? ba.cardnumber : null,
                  expiryyear: ba.expiryyear
                    ? String(ba.expiryyear).length === 4
                      ? Number(ba.expiryyear)
                      : Number('20' + ba.expiryyear)
                    : null,
                  expirymonth: ba.expirymonth ? ba.expirymonth : null,
                  cvv: ba.cvv ? ba.cvv : null,
                  streetaddress: ba.streetaddress ? ba.streetaddress : null,
                  streetaddress2: ba.streetaddress2 ? ba.streetaddress2 : null,
                  city: ba.city ? ba.city : null,
                  state: ba.state ? ba.state : null,
                  zip: ba.zip ? ba.zip : null,
                  country: ba.country ? ba.country : null,
                  saveonfile: ba.saveonfile ? ba.saveonfile : null,
                  billingaccountid: ba.savedcardid ? ba.savedcardid : null,
                  billingschemeid: ba.billingschemeid ? ba.billingschemeid : null,
                  billingfields: ba.billingfields ? ba.billingfields : null,
                  token: ba.token ? ba.token : null,
                  cardtype: ba.cardtype ? ba.cardtype : null,
                  cardlastfour: ba.cardlastfour ? ba.cardlastfour : null,
                } as Billingaccount;
                billingAccounts.push(billingField);
              });
              let body: SubmitOrderMultiPaymentRequest;
              body = {
                authtoken: null,
                usertype: 'guest',
                firstname: cardDetails.firstName,
                lastname: cardDetails.lastName,
                emailaddress: cardDetails.emailAddress,
                contactnumber: cardDetails.phoneNumber,
                reference: null,
                orderref: null,
                customdata: this.customData,
                guestoptin: cardDetails.guestOptIn ? true : false,
                billingaccounts: billingAccounts,
              } as SubmitOrderMultiPaymentRequest;
              return this.oloAPI.submitOrderWithMultiplePayments(orderID, body).pipe(
                switchMap(res => {
                  this.storeBasketGuid('');
                  sessionStorage.setItem('deliveryBasketGuid', res.id);
                  return this.getLocation(res.vendorid).pipe(
                    map(location => {
                      return this.oloMapping.orderToOrder(res, location);
                    })
                  );
                })
              );
            })
          );
        } else {
          return this.oloAPI.getAllBillingSchemesAndAccounts(orderID).pipe(
            switchMap(schemes => {
              const billingscheme = schemes.billingschemes.find((sche: any) => sche.type === 'giftcard');
              // tslint:disable-next-line: max-line-length
              let body:
                | SubmitOrderRequestWithCreditCardAsGuest
                | SubmitOrderRequestWithGiftCardAsGuest
                | SubmitOrderRequestWithSavedCardAsGuest
                | SubmitOrderWithPrepaidTransactionAsGuest
                | SubmitOrderRequestWithCreditCardTokenAsGuest;
              if (billingscheme && billingscheme.id && cardDetails.isGiftCard) {
                body = {
                  billingmethod: 'storedvalue',
                  billingschemeid: billingscheme.id,
                  billingfields: [
                    {
                      name: 'number',
                      value: cardDetails.giftCardNumber,
                    },
                    {
                      name: 'pin',
                      value: cardDetails.pin,
                    },
                  ],
                  usertype: 'guest',
                  firstname: cardDetails.firstName,
                  lastname: cardDetails.lastName,
                  emailaddress: cardDetails.emailAddress,
                  contactnumber: cardDetails.phoneNumber,
                  customdata: this.customData,
                  guestoptin: cardDetails.guestOptIn ? true : false,
                } as SubmitOrderRequestWithGiftCardAsGuest;
              } else if (cardDetails.isSavedCard) {
                body = {
                  billingmethod: 'billingaccount',
                  billingaccountid: cardDetails.savedCardID,
                  usertype: 'guest',
                  firstname: cardDetails.firstName,
                  lastname: cardDetails.lastName,
                  emailaddress: cardDetails.emailAddress,
                  contactnumber: cardDetails.phoneNumber,
                  customdata: this.customData,
                  guestoptin: cardDetails.guestOptIn ? true : false,
                } as SubmitOrderRequestWithSavedCardAsGuest;
              } else if (cardDetails.paymentType === PaymentTypes.prepaid) {
                body = {
                  billingmethod: 'prepaid',
                  usertype: 'guest',
                  firstname: cardDetails.firstName,
                  lastname: cardDetails.lastName,
                  emailaddress: cardDetails.emailAddress,
                  contactnumber: cardDetails.phoneNumber,
                  prepaidtransactionid: cardDetails.prepaidID,
                  prepaiddescription: cardDetails.prepaidDescription,
                } as SubmitOrderWithPrepaidTransactionAsGuest;
              } else if (cardDetails.paymentType === PaymentTypes.creditCardToken) {
                body = {
                  billingmethod: cardDetails.isDigitalWallet ? 'digitalwallet' : 'creditcardtoken',
                  // tslint:disable-next-line:max-line-length
                  billingschemeid: this.findDigitalWalletBillingScheme(
                    schemes.billingschemes,
                    cardDetails.isDigitalWallet,
                    cardDetails.digitalWalletType
                  ),
                  usertype: 'guest',
                  firstname: cardDetails.firstName,
                  lastname: cardDetails.lastName,
                  emailaddress: cardDetails.emailAddress,
                  contactnumber: cardDetails.phoneNumber,
                  token: cardDetails.token,
                  cardtype: cardDetails.cardType,
                  cardlastfour: cardDetails.cardLastFour,
                  expirymonth: Number(cardDetails.expirationMonth),
                  expiryyear: Number(cardDetails.expirationYear),
                  city: cardDetails.billingAddress.city,
                  state: cardDetails.billingAddress.state,
                  zip: cardDetails.billingAddress.zipCode,
                  streetaddress: cardDetails.billingAddress.address1,
                  streetaddress2: cardDetails.billingAddress.address2,
                  country: cardDetails.billingAddress.country ? cardDetails.billingAddress.country : 'US',
                } as SubmitOrderRequestWithCreditCardTokenAsGuest;
              } else {
                body = {
                  billingmethod: 'creditcard',
                  usertype: 'guest',
                  firstname: cardDetails.firstName,
                  lastname: cardDetails.lastName,
                  emailaddress: cardDetails.emailAddress,
                  cardnumber: cardDetails.cardNumber,
                  contactnumber: cardDetails.phoneNumber,
                  cvv: cardDetails.cvv,
                  expirymonth: Number(cardDetails.expirationMonth),
                  expiryyear: Number('20' + cardDetails.expirationYear),
                  city: cardDetails.billingAddress.city,
                  state: cardDetails.billingAddress.state,
                  zip: cardDetails.billingAddress.zipCode,
                  streetaddress: cardDetails.billingAddress.address1,
                  streetaddress2: cardDetails.billingAddress.address2,
                  country: cardDetails.billingAddress.country ? cardDetails.billingAddress.country : 'US',
                  customdata: this.customData,
                  guestoptin: cardDetails.guestOptIn ? true : false,
                } as SubmitOrderRequestWithCreditCardAsGuest;
              }
              return this.oloAPI.submitOrderWithSinglePayment(orderID, body).pipe(
                switchMap(res => {
                  this.storeBasketGuid('');
                  sessionStorage.setItem('deliveryBasketGuid', res.id);
                  return this.getLocation(res.vendorid).pipe(
                    map(location => {
                      return this.oloMapping.orderToOrder(res, location);
                    })
                  );
                })
              );
            })
          );
        }
      })
    );
  }

  fireOrder(orderID: string): Observable<Order> {
    return this.oloAPI.submitManualFireOrder(orderID, {}).pipe(map(res => this.oloMapping.orderToOrder(res.order, null)));
  }

  getOrderHistory(userID: string): Observable<Order[]> {
    return this.oloAPI.getUserRecentOrders(userID).pipe(
      switchMap(res => {
        if (res.orders.length === 0) {
          return of<Order[]>([]);
        } else {
          return from(res.orders).pipe(
            concatMap(order => this.getOrderWithLocationWithHours(order.vendorid, order)),
            toArray()
          );
        }
      })
    );
  }

  // tslint:disable-next-line:max-line-length
  reorderFromHistory(authToken: string, externalOrderRef: string, orderID: string, ignoreUnavailableProducts: boolean): Observable<Order> {
    // tslint:disable-next-line:max-line-length
    return this.oloAPI.createBasketFromPreviousOrder(authToken, externalOrderRef, orderID, ignoreUnavailableProducts).pipe(
      switchMap(basketResponse => {
        this.storeBasketGuid(basketResponse.id);
        return this.getLocation(basketResponse.vendorid).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(basketResponse.id).pipe(
              switchMap(billingSchemes => {
                const order = this.oloMapping.createBasketResponseToOrder(basketResponse, location, billingSchemes.billingschemes);
                return this.contentService.getOrderItemsWithSlugsandImages(order);
              })
            );
          })
        );
      })
    );
  }

  getProduct(locationID: string, handoffType: HandoffType, menuID: string, categoryID: string, productID: number): Observable<Product> {
    if (isNaN(locationID as any)) {
      // URL contains a slug
      return this.directusService.getSingleLocationBySlug(locationID).pipe(
        switchMap(locationInfo => {
          return this.getProduct(locationInfo.menu_id, handoffType, menuID, categoryID, productID);
        })
      );
    } else {
      return this.getMenuByHandoffType(locationID, handoffType).pipe(
        switchMap(deMenu => {
          return this.oloAPI.getProductModifiersAndOptions(productID).pipe(
            switchMap(res => {
              const prod = this.getProductFromMenu(deMenu, categoryID, productID.toString());
              const product = this.oloMapping.productModifiersToProduct(prod, res.optiongroups, deMenu.imageBaseUrl);
              return this.contentService.getProductWithImages(product).pipe(
                map(mappedProd => {
                  return mappedProd;
                })
              );
            })
          );
        })
      );
      //     }
      // }
    }
  }

  getRecommendedDealsForProduct(locationID: string | number, handoffType: HandoffType, productID: string | number): Observable<Product[]> {
    return this.directusService.getContentProducts().pipe(
      switchMap(products => {
        return this.getMenuByHandoffType(String(locationID), handoffType).pipe(
          switchMap(menu => {
            const product = this.getProductFromMenu(menu, '', productID.toString());
            if (!product) {
              return of([]);
            } else {
              const contentProduct = products.find(p => p.name.trim() === product.name.trim());
              if (!contentProduct) {
                return of([]);
              } else {
                if (contentProduct.recommended_deals && contentProduct.recommended_deals.length) {
                  // get all of the products that are recommended deals
                  return from(contentProduct.recommended_deals).pipe(
                    switchMap(recommendedDeal => {
                      const category = menu.categories.find(cat =>
                        cat.products.find(prod => prod.name.trim() === recommendedDeal.deal_item_id.name.trim())
                      );
                      if (category) {
                        return of(category.products.find(prod => prod.name.trim() === recommendedDeal.deal_item_id.name.trim()));
                      } else {
                        return of(null);
                      }
                    }),
                    filter(prod => prod !== null),
                    toArray()
                  );
                }
              }
            }
          })
        );
      })
    );
  }

  @Cacheable({
    maxCacheCount: 100,
    maxAge: 5 * 60 * 1000, // 5 minutes
  })
  getMenuByHandoffType(locationID: string, handoffType: HandoffType): Observable<Menu> {
    const oloHandoffType = this.oloMapping.handoffTypeToHandoffType(handoffType);
    if (isNaN(locationID as any)) {
      // URL contains a slug
      return this.directusService.getSingleLocationBySlug(locationID).pipe(
        switchMap(locationInfo => {
          return this.getMenuByHandoffType(locationInfo.menu_id, handoffType);
        })
      );
    } else {
      return this.oloAPI.getRestaurantMenu(locationID).pipe(
        switchMap(menu => {
          menu.categories.forEach(cat => {
            cat.products = cat.products.filter(product => !product.unavailablehandoffmodes.includes(oloHandoffType));
          });
          const deMenu = this.oloMapping.restaurantMenuToMenu(locationID, menu);
          return this.contentService.getMenuWithImages(deMenu);
        })
      );
    }
  }

  addDonation(basketGuid: string, id: number, amount: number): Observable<Order> {
    throw new Error('Method not implemented.');
  }

  private storeBasketGuid(basketGuid: string) {
    sessionStorage.setItem(this.basketGuidKey, basketGuid);
  }

  private getBasketGuid(): string {
    return sessionStorage.getItem(this.basketGuidKey);
  }

  private removeBasketGuid(): void {
    sessionStorage.removeItem(this.basketGuidKey);
  }

  private storeOLOAuthToken(authToken: string): Observable<void> {
    return from(Storage.set({ key: this.oloAuthTokenKey, value: authToken }));
    // localStorage.setItem(this.oloAuthTokenKey, authToken);
  }

  private getOLOAuthToken(): Observable<string> {
    return from(Storage.get({ key: this.oloAuthTokenKey })).pipe(
      map(data => {
        return data.value;
      })
    );
    // return localStorage.getItem(this.oloAuthTokenKey);
  }

  private storePunchhAuthToken(authToken: string): Observable<void> {
    return from(Storage.set({ key: this.punchhAuthTokenKey, value: authToken }));
    // localStorage.setItem(this.punchhAuthTokenKey, authToken);
  }

  private getPunchhAuthToken(): Observable<string> {
    return from(Storage.get({ key: this.punchhAuthTokenKey })).pipe(
      map(data => {
        return data.value;
      })
    );
    // return localStorage.getItem(this.punchhAuthTokenKey);
  }

  private storePunchhMobileAuthToken(authToken: string): Observable<void> {
    return from(Storage.set({ key: this.punchhMobileAuthTokenKey, value: authToken }));
    // localStorage.setItem(this.punchhMobileAuthTokenKey, authToken);
  }

  private getPunchhMobileAuthToken(): Observable<string> {
    return from(Storage.get({ key: this.punchhMobileAuthTokenKey })).pipe(
      map(data => {
        return data.value;
      })
    );
    // return localStorage.getItem(this.punchhMobileAuthTokenKey);
  }

  private storePunchhAccessToken(token: string): Observable<void> {
    return from(Storage.set({ key: this.punchhAccessTokenKey, value: token }));
    // localStorage.setItem(this.punchhAccessTokenKey, token);
  }

  private getPunchhAccessToken(): Observable<string> {
    return from(Storage.get({ key: this.punchhAccessTokenKey })).pipe(
      map(data => {
        return data.value;
      })
    );
    // return localStorage.getItem(this.punchhAccessTokenKey);
  }

  private storePunchhMobileAccessToken(token: string): Observable<void> {
    return from(Storage.set({ key: this.punchhMobileAccessTokenKey, value: token }));
    // localStorage.setItem(this.punchhMobileAccessTokenKey, token);
  }

  private getPunchhMobileAccessToken(): Observable<string> {
    return from(Storage.get({ key: this.punchhMobileAccessTokenKey })).pipe(
      map(data => {
        return data.value;
      })
    );
    // return localStorage.getItem(this.punchhMobileAccessTokenKey);
  }

  private storePunchhOauthJwtToken(token: string): Observable<void> {
    if (!token) {
      token = '';
    }
    return from(Storage.set({ key: this.punchhOauthJwtTokenKey, value: token }));
    // localStorage.setItem(this.punchhOauthJwtTokenKey, token);
  }

  private getPunchhOauthJwtToken(): Observable<string> {
    return from(Storage.get({ key: this.punchhOauthJwtTokenKey })).pipe(
      map(data => {
        return data.value;
      })
    );
    // return localStorage.getItem(this.punchhOauthJwtTokenKey);
  }

  private storePunchhOauthCode(token: string): Observable<void> {
    if (!token) {
      token = '';
    }
    return from(Storage.set({ key: this.punchhOauthCodeKey, value: token }));
    // localStorage.setItem(this.punchhOauthCodeKey, token);
  }

  private getPunchhOauthCode(): Observable<string> {
    return from(Storage.get({ key: this.punchhOauthCodeKey })).pipe(
      map(data => {
        return data.value;
      })
    );
    // return localStorage.getItem(this.punchhOauthCodeKey);
  }

  private storePunchhAppleSignInUser(user: AppleUser): Observable<void> {
    return from(
      Storage.set({
        key: this.punchhAppleUserDataKey,
        value: JSON.stringify(user),
      })
    );
  }

  private getPunchhAppleSignInUser(): Observable<AppleUser> {
    return from(Storage.get({ key: this.punchhAppleUserDataKey })).pipe(
      map(data => {
        return JSON.parse(data.value);
      })
    );
  }

  private removePunchhAuthToken(): void {
    Storage.remove({ key: this.punchhAuthTokenKey });
    // localStorage.removeItem(this.punchhAuthTokenKey);
  }

  private removePunchhAccessToken(): void {
    Storage.remove({ key: this.punchhAccessTokenKey });
    // localStorage.removeItem(this.punchhAccessTokenKey);
  }

  private removeOLOAuthToken(): void {
    Storage.remove({ key: this.oloAuthTokenKey });
    // localStorage.removeItem(this.oloAuthTokenKey);
  }

  private removePunchhMobileAccessToken(): void {
    Storage.remove({ key: this.punchhMobileAccessTokenKey });
    // localStorage.removeItem(this.punchhMobileAccessTokenKey);
  }

  private toCommaDelimitedOptionsIDs(options: OrderItemModifier[]): string {
    let str = ''; // TODO this needs to be more complex for recursive options
    if (options) {
      options.forEach(op => {
        str += op.optionID + ',';
      });
    }
    return str;
  }

  private getProductFromMenu(menu: Menu, categoryID: string, productID: string): Product {
    const category = menu.categories.find(cat => cat.categoryID === categoryID);
    if (category) {
      return category.products.find(prod => prod.productID === productID);
    } else {
      let product = null;
      for (const catKey in menu.categories) {
        if (Object.prototype.hasOwnProperty.call(menu.categories, catKey) && (!product || product === null)) {
          product = menu.categories[catKey].products.find(prod => prod.productID === productID);
        }
      }
      if (product) {
        return product;
      } else {
        return menu.singleUseProducts?.products.find(prod => prod.productID === productID);
      }
    }
  }

  private getLocationWithHours(location: Location): Observable<Location> {
    // tslint:disable-next-line:max-line-length
    return this.oloAPI.getRestaurantOperatingHoursForThisWeek(Number(location.locationID), location.orderAheadDays).pipe(
      map(calendarRes => {
        return this.oloMapping.getLocationWithHours(location, calendarRes.calendar);
      })
    );
  }

  private getOrderWithLocationWithHours(vendorid: number, order: OloOrder): Observable<Order> {
    return this.getLocation(vendorid).pipe(
      map(location => {
        return this.oloMapping.orderToOrder(order, location);
      })
    );
  }

  setSpecialInstructions(orderID: string | number, inst: string): Observable<Order> {
    this.customData = [];
    const tableNum = sessionStorage.getItem('tablenumber');
    if (tableNum && tableNum !== '0') {
      this.customData.push({ key: 'Table #', value: tableNum });
    }
    if (inst) {
      this.customData.push({ key: 'Special Instructions', value: inst });
    }
    return this.getCurrentOrder(null);
    // throw new Error('Method not implemented.');
  }

  // tslint:disable-next-line:max-line-length
  startGroupOrder(
    restaurantID: number,
    basketGuid: string,
    deadline: Date,
    members: string[],
    note: string,
    name: string
  ): Observable<GroupOrder> {
    return this.getOLOAuthToken().pipe(
      switchMap(id => {
        return this.getLocation(restaurantID).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(basketGuid).pipe(
              switchMap(billingSchemes => {
                return this.oloAPI.createGroupOrder(id, restaurantID, basketGuid, deadline, note).pipe(
                  map((response: GroupOrderResponse) => {
                    console.log(response);
                    const order = this.oloMapping.groupOrderResponseToOrder(response, location, billingSchemes.billingschemes, true);
                    console.log(order);
                    return order;
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  // tslint:disable-next-line:max-line-length
  updateGroupOrder(
    restaurantID: number,
    basketGuid: string,
    groupGuid: string,
    deadline: Date,
    members: string[],
    note: string,
    name: string
  ): Observable<GroupOrder> {
    return this.getOLOAuthToken().pipe(
      switchMap(id => {
        return this.getLocation(restaurantID).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(basketGuid).pipe(
              switchMap(billingSchemes => {
                // tslint:disable-next-line:max-line-length
                return this.oloAPI.updateGroupOrder(id, groupGuid, deadline, note).pipe(
                  switchMap((response: GroupOrderResponse) => {
                    // tslint:disable-next-line:max-line-length
                    const order = this.oloMapping.groupOrderResponseToOrder(
                      response,
                      location,
                      billingSchemes.billingschemes,
                      name === response.ownername
                    );
                    this.storeGroupGuid(order.groupOrderID);
                    return this.oloAPI.sendGroupOrderInvites(order.groupOrderID, id, members).pipe(
                      map(() => {
                        return order;
                      })
                    );
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  clearGroupOrder(): Observable<any> {
    this.removeGroupGuid();
    return of(null);
  }

  getCurrentGroupOrder(restaurantID: number, basketGuid: string, name: string): Observable<GroupOrder> {
    return this.getOLOAuthToken().pipe(
      switchMap(id => {
        return this.getLocation(restaurantID).pipe(
          switchMap(location => {
            return this.oloAPI.getAllBillingSchemesAndAccounts(basketGuid).pipe(
              switchMap(billingSchemes => {
                const groupID = this.getGroupGuid();
                if (groupID) {
                  return this.oloAPI.getGroupOrder(groupID, id, true, basketGuid).pipe(
                    map((response: GroupOrderResponse) => {
                      return this.oloMapping.groupOrderResponseToOrder(
                        response,
                        location,
                        billingSchemes.billingschemes,
                        name === response.ownername
                      );
                    })
                  );
                } else {
                  return of(null);
                }
              })
            );
          })
        );
      })
    );
  }

  getGroupOrder(groupID: string, name: string, basketID: string): Observable<GroupOrder> {
    return this.getOLOAuthToken().pipe(
      switchMap(id => {
        return this.oloAPI.getGroupOrder(groupID, id, true, basketID).pipe(
          switchMap((response: GroupOrderResponse) => {
            return this.getLocation(response.basket.vendorid).pipe(
              switchMap(location => {
                return this.oloAPI.getAllBillingSchemesAndAccounts(response.basket.id).pipe(
                  map(billingSchemes => {
                    return this.oloMapping.groupOrderResponseToOrder(
                      response,
                      location,
                      billingSchemes.billingschemes,
                      name === response.ownername
                    );
                  })
                );
              })
            );
          })
        );
      })
    );
  }

  joinGroupOrder(groupGuid: string, name: string, basketID: string): Observable<GroupOrder> {
    this.storeGroupGuid(groupGuid);
    return this.getGroupOrder(groupGuid, name, basketID).pipe(
      map(groupOrder => {
        this.storeBasketGuid(groupOrder.order.orderID);
        return groupOrder;
      })
    );
  }

  private storeGroupGuid(groupGuid: string) {
    sessionStorage.setItem(this.groupGuidKey, groupGuid);
  }

  private getGroupGuid(): string {
    return sessionStorage.getItem(this.groupGuidKey);
  }

  private removeGroupGuid(): void {
    sessionStorage.removeItem(this.groupGuidKey);
  }

  private setTableNumber(orderID: string, tableNum: string, customerName: string): Observable<Order> {
    return this.getCurrentOrder(null).pipe(
      switchMap(order => {
        // tslint:disable-next-line:max-line-length
        const tableNumCustomfield = order.location.customFields?.find(
          field => field.applicableHandoffs.length === 1 && field.applicableHandoffs.includes(HandoffType.dineIn)
        );
        console.log('tableNumCustomfield', tableNumCustomfield);
        if (tableNum) {
          if (tableNumCustomfield) {
            return this.setBasketCustomfield(String(orderID), tableNumCustomfield.id, tableNum).pipe(
              switchMap(() => {
                return this.setOnPremiseAndFinish(orderID, [tableNum, ...customerName.split(' ')].join(''));
              })
            );
          } else {
            return this.setOnPremiseAndFinish(orderID, [tableNum, ...customerName.split(' ')].join(''));
          }
        } else {
          return of(order);
        }
      })
    );
  }

  private setOnPremiseAndFinish(orderID: string, tableNum: string): Observable<Order> {
    return this.oloAPI.setBasketOnPremiseDetails(String(orderID), tableNum).pipe(
      switchMap(basket => {
        return this.getCurrentOrder(null);
      }),
      catchError(() => {
        return this.getCurrentOrder(null);
      })
    );
  }

  // tslint:disable-next-line:max-line-length
  private findDigitalWalletBillingScheme(billingSchemes: Billingscheme[], isDigitalWallet = false, type?: string): number | undefined {
    if (!isDigitalWallet) {
      return undefined;
    }
    if (type) {
      console.log('type', type);
      return billingSchemes.find(scheme => scheme.name === (type.includes('apple') ? 'Apple Pay' : 'Google Pay'))?.id;
    } else {
      return billingSchemes.find(scheme => scheme.name === (Capacitor.getPlatform() === 'ios' ? 'Apple Pay' : 'Google Pay'))?.id;
    }
  }

  private getRWGToken(): Observable<string | null> {
    // first check if expired
    return from(Storage.get({ key: LocalStorageKey.GOOGLE_RWG_TOKEN_EXPIRATION })).pipe(
      switchMap(expiration => {
        if (expiration.value) {
          const exp = moment(expiration.value);
          if (moment().isBefore(exp)) {
            return from(Storage.get({ key: LocalStorageKey.GOOGLE_RWG_TOKEN })).pipe(
              map(token => {
                return token.value;
              })
            );
          }
          return of(null);
        }
        return of(null);
      })
    );
  }

  private removeRWGToken(): void {
    Storage.remove({ key: LocalStorageKey.GOOGLE_RWG_TOKEN });
    Storage.remove({ key: LocalStorageKey.GOOGLE_RWG_TOKEN_EXPIRATION });
  }

  getApplePassUrl(userID: string): Observable<string> {
    return of(null);
  }

  getGooglePassData(userID: string): Observable<GooglePass> {
    return of(null);
  }

  markGooglePassAsSaved(userID: string, objectCode: string): Observable<boolean> {
    return of(true);
  }

  getReferrals(userID: string): Observable<Referral[]> {
    return of([]);
  }

  sendReferrals(userID: string, emails: string[], message: string): Observable<Referral[]> {
    return of([]);
  }

  supportsReferrals(): boolean {
    return false;
  }

  checkIfCanSupportMultipleRewards(orderID: string): Observable<boolean> {
    return this.getOLOAuthToken().pipe(
      switchMap(id => {
        return this.oloAPI.getLoyaltySchemes(orderID, id).pipe(
          map(res => {
            return res.schemes.some(scheme => scheme.multiplerewardsenabled);
          })
        );
      })
    );
  }
}
