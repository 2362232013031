import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../../../services/vendor-config-service/user.service';
import { Observable, of } from 'rxjs';
import { switchMap, map, filter, take, catchError } from 'rxjs/operators';
import { OrderService } from '../../../services/vendor-config-service/order.service';
import { Store } from '@ngxs/store';
import { User } from '../../../interfaces/user.interface';
import { Order } from '../../../interfaces/order.interface';
import { NavigationService } from '@modules/navigation/services';
import { ModeService } from '../../../services/mode.service';
import { ValidateOrder } from '../../../store/actions/order.actions';

@Injectable({
  providedIn: 'root',
})
export class CheckoutGuard {
  constructor(
    private navigation: NavigationService,
    private mode: ModeService,
    private store: Store
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store
      .select(state => state.order.order)
      .pipe(
        take(1), // Take the current value of the order and complete
        switchMap((order: Order | null) => {
          if (order && order.items.length > 0) {
            // If there is an order with items, dispatch the action
            return this.store.dispatch(new ValidateOrder()).pipe(
              // Handle any errors from the action
              catchError(error => {
                console.error('PreResolveAction failed:', error);
                // Continue navigation even if the action fails
                return of(null);
              }),
              // After dispatching the action, proceed with the original navigation logic
              map(() => order)
            );
          } else {
            // If there's no order or no items, proceed without dispatching the action
            return of(order);
          }
        }),
        map((order: Order | null) => {
          if (order) {
            if (order.items.length > 0) {
              // If the order has items, allow navigation
              return true;
            } else {
              // If the order exists but has no items, navigate to the menu page
              const locationID = order.location.slugURL ? order.location.slugURL : order.location.locationID;
              this.navigation.navigateToMenuPage(locationID);
              return false;
            }
          } else {
            // If there's no order, navigate based on the mode
            if (this.mode.mode === 'kiosk') {
              this.navigation.navigateToHomePage('kiosk');
            } else {
              this.navigation.navigateToLocationsPage();
            }
            return false;
          }
        })
      );
  }
}
