import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { GlobalStateModel } from '../../../../store/state.model';
import { Observable } from 'rxjs';
import { VendorSetup } from '../../../../interfaces/vendor.interface';
import { User } from '../../../../interfaces/user.interface';
import { MobileAppSettings } from '../../../../vendors/directus/interfaces/mobile-app-settings.interface';
import { Navigation, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { OrderTypeService } from '@modules/cart/services/order-type.service';
import { NavigationService } from '@modules/navigation/services';
import { InboxMessage } from '../../../../interfaces/inbox-message.interface';

@Component({
  selector: 'app-tab-bar',
  templateUrl: './tab-bar.component.html',
  styleUrls: ['tab-bar.component.scss'],
})
export class TabBarComponent implements OnInit {
  @Select((state: GlobalStateModel) => state.app.vendorSetup) vendorSetup$: Observable<VendorSetup>;
  @Select((state: GlobalStateModel) => state.user.user) user$: Observable<User>;
  @Select((state: GlobalStateModel) => state.app.mobileAppSettings) mobileAppSettings$: Observable<MobileAppSettings>;
  @Select((state: GlobalStateModel) => state.user.inboxMessages) inboxMessages$: Observable<InboxMessage[]>;

  adjustedMobileAppSettings$: Observable<{
    settings: MobileAppSettings;
    adjustedBackgroundColor: string;
    contrastingTextColor: string;
  }>;

  constructor(
    public router: Router,
    private orderType: OrderTypeService,
    private store: Store,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {
    this.adjustedMobileAppSettings$ = this.mobileAppSettings$.pipe(
      map(settings => {
        let adjustedBackgroundColor = null;
        let contrastingTextColor = null;
        if (settings && settings.background_color) {
          adjustedBackgroundColor = this.adjustColor(settings.background_color);
          contrastingTextColor = this.getContrastingTextColor(adjustedBackgroundColor);
        }
        return {
          settings,
          adjustedBackgroundColor,
          contrastingTextColor,
        };
      })
    );
  }

  adjustColor(hexColor: string): string {
    // Convert hex to RGB
    const rgb = this.hexToRgb(hexColor);
    if (!rgb) {
      return hexColor; // Return original color if invalid
    }
    // Convert RGB to HSL
    const hsl = this.rgbToHsl(rgb.r, rgb.g, rgb.b);

    // Adjustable parameters
    const lightnessThreshold = 0.5; // Adjust this threshold between 0 and 1
    const lightnessAdjustment = 0.1; // Adjust this value between 0 and 1

    if (hsl.l > lightnessThreshold) {
      // Light color, so darken it
      hsl.l = Math.max(0, hsl.l - lightnessAdjustment);
    } else {
      // Dark color, so lighten it
      hsl.l = Math.min(1, hsl.l + lightnessAdjustment);
    }
    // Convert HSL back to RGB
    const rgbAdjusted = this.hslToRgb(hsl.h, hsl.s, hsl.l);
    // Convert RGB back to hex
    const hexAdjusted = this.rgbToHex(rgbAdjusted.r, rgbAdjusted.g, rgbAdjusted.b);
    return hexAdjusted;
  }

  hexToRgb(hex: string): { r: number; g: number; b: number } | null {
    // Remove the hash if present
    hex = hex.replace(/^#/, '');
    // Expand shorthand form (e.g., "03F") to full form (e.g., "0033FF")
    if (hex.length === 3) {
      hex = hex
        .split('')
        .map(c => c + c)
        .join('');
    }
    if (hex.length !== 6) {
      return null;
    }
    const num = parseInt(hex, 16);
    return {
      r: (num >> 16) & 255,
      g: (num >> 8) & 255,
      b: num & 255,
    };
  }

  rgbToHex(r: number, g: number, b: number): string {
    return (
      '#' +
      [r, g, b]
        .map(x => {
          const hex = x.toString(16);
          return hex.length === 1 ? '0' + hex : hex;
        })
        .join('')
    );
  }

  rgbToHsl(r: number, g: number, b: number): { h: number; s: number; l: number } {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h = 0,
      s = 0,
      l = (max + min) / 2;

    if (max !== min) {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }

    return { h, s, l };
  }

  hslToRgb(h: number, s: number, l: number): { r: number; g: number; b: number } {
    let r: number, g: number, b: number;

    if (s === 0) {
      r = g = b = l; // Achromatic
    } else {
      const hue2rgb = (p: number, q: number, t: number): number => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255),
    };
  }

  getContrastingTextColor(hexColor: string): string {
    const rgb = this.hexToRgb(hexColor);
    if (!rgb) {
      return '#000';
    }
    const luminance = (0.299 * rgb.r + 0.587 * rgb.g + 0.114 * rgb.b) / 255;
    return luminance > 0.5 ? '#000' : '#fff';
  }

  setNewOrderType() {
    const order = this.store.selectSnapshot((state: GlobalStateModel) => state.order.order);
    if (!order) {
      this.orderType.setNewOrderType(false);
    }
  }
}
