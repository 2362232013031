import { CreateAccount as NewAccount } from '../../interfaces/create-account.interface';
import { User } from '../../interfaces/user.interface';
import { Reward } from '../../interfaces/reward.interface';
import { DollarReward } from '../../interfaces/dollar-reward.interface';
import { SavedCard } from '../../interfaces/saved-card.interface';
import { SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { PurchaseableReward, Variation } from '../../interfaces/purchaseable-reward.interface';
import { GiftCard } from '../../interfaces/giftcard.interface';
import { UserField } from '../../interfaces/user-field';

export class InitializeUser {
  static readonly type = '[User] Initialize User';

  constructor() {}
}

export class SetAdditionalUserFields {
  static readonly type = '[User] Set Additional User Fields';

  constructor() {}
}

export class SetRewards {
  static readonly type = '[User] Set Rewards';

  constructor(
    public userID: string,
    public locationID: string
  ) {}
}

export class SetLoyaltyActivity {
  static readonly type = '[User] Set Loyalty Activity';

  constructor() {}
}

export class SetRewardsBalances {
  static readonly type = '[User] Set Rewards Balances';

  constructor(public userID: string) {}
}

export class SetOffers {
  static readonly type = '[User] Set Offers';

  constructor(public userID: string) {}
}

export class SetSavedCards {
  static readonly type = '[User] Set Saved Cards';

  constructor(
    public userID: string,
    public isAccountPage: boolean
  ) {}
}

export class SetPastOrders {
  static readonly type = '[User] Set Past Orders';

  constructor() {}
}

export class CreateAccount {
  static readonly type = '[User] Create Account';

  constructor(
    public newAccount: NewAccount,
    public additionalFields: UserField[]
  ) {}
}

export class SignIn {
  static readonly type = '[User] Sign In';

  constructor(
    public email: string,
    public password: string
  ) {}
}

export class SignInWithToken {
  static readonly type = '[User] Sign In with Token';

  constructor(
    public readonly token: string,
    public readonly redirectURL: string
  ) {}
}

export class SignInWithFacebook {
  static readonly type = '[User] Sign In with Facebook';

  constructor(
    public email: string,
    public accessToken: string,
    public userID: string
  ) {}
}

export class ConnectWithFacebook {
  static readonly type = '[User] Connect with Facebook';

  constructor(
    public email: string,
    public accessToken: string,
    public userID: string
  ) {}
}

export class SignInWithApple {
  static readonly type = '[User] Sign In with Apple';

  constructor(
    public appleResponse: SignInWithAppleResponse,
    public redirectURI: string
  ) {}
}

export class ConnectWithApple {
  static readonly type = '[User] Connect with Apple';

  constructor(
    public appleResponse: SignInWithAppleResponse,
    public redirectURI: string
  ) {}
}

export class UpdateProfileInfo {
  static readonly type = '[User] Update Profile Info';

  constructor(
    public user: User,
    public additionalFields: UserField[]
  ) {}
}

export class LogOut {
  static readonly type = '[User] Log Out';

  constructor() {}
}

export class RedeemInStoreReward {
  static readonly type = '[User] Redeem In-Store Reward';

  constructor(public reward: Reward) {}
}

export class RedeemRewardPoints {
  static readonly type = '[User] Redeem Reward Points';

  constructor(public points: number) {}
}

export class ReturnRewardPoints {
  static readonly type = '[User] Return Reward Points';

  constructor(public reward: DollarReward) {}
}

export class RedeemPointsFromScanner {
  static readonly type = '[User] Redeem Points from Scanner';

  constructor(public barcode: string) {}
}

export class DeleteSavedCard {
  static readonly type = '[User] Delete Saved Card';

  constructor(public card: SavedCard) {}
}

export class SaveCardAsDefault {
  static readonly type = '[User] Save Card As Default';

  constructor(public card: SavedCard) {}
}

export class UpdateAppliedRewards {
  static readonly type = '[User] Update Applied Rewards';

  constructor(public reward: Reward) {}
}

export class SendPasswordChangeRequest {
  static readonly type = '[User] Send Password Change Request';

  constructor(public email: string) {}
}

export class SendPasswordResetRequest {
  static readonly type = '[User] Send Password Reset Request';

  constructor(public password: string) {}
}

export class SendPasswordResetCode {
  static readonly type = '[User] Send Password Reset Code';

  constructor(public email: string) {}
}

export class SetupCateringLink {
  static readonly type = '[User] Setup Catering Link';

  constructor() {}
}

export class RegisterForPush {
  static readonly type = '[User] Register for Push Notifications';

  constructor() {}
}

export class UserCheckIn {
  static readonly type = '[User] User Check In';

  constructor() {}
}

export class CreateQuickCode {
  static readonly type = '[User] Create Quick Code';

  constructor() {}
}

export class SetThirdPartyWrapped {
  static readonly type = '[User] Set Third Party Wrapped';

  constructor(public readonly isThirdPartyWrapped: boolean) {}
}

export class SetLoyaltyLocations {
  static readonly type = '[User] Set Loyalty Locations';

  constructor() {}
}

export class SetPurchaseableRewards {
  static readonly type = '[User] Set Purchaseable Rewards';

  constructor() {}
}

export class PurchaseReward {
  static readonly type = '[User] Purchase Reward';

  constructor(
    public reward: PurchaseableReward,
    public variation?: Variation
  ) {}
}

export class SetAllowLoyaltyTransfer {
  static readonly type = '[User] Set Allow Loyalty Transfer';

  constructor() {}
}

export class TransferStoredValueToLoyaltyAccount {
  static readonly type = '[User] Transfer Stored Value to Loyalty Account';

  constructor(public from: GiftCard) {}
}

export class DeleteAccount {
  static readonly type = '[User] Delete Account';

  constructor() {}
}

export class SetLoyaltyRewards {
  static readonly type = '[User] Set Loyalty Rewards';
  constructor() {}
}

export class SetInboxMessages {
  static readonly type = '[User] Set Inbox Messages';
}

export class MarkMessageAsRead {
  static readonly type = '[User] Mark Message As Read';

  constructor(public messageId: string) {}
}

export class DeleteMessage {
  static readonly type = '[User] Delete Message';

  constructor(public messageId: string) {}
}

export class SetApplePassbookURL {
  static readonly type = '[User] Set Apple Passbook URL';

  constructor() {}
}

export class SetGoogleWalletData {
  static readonly type = '[User] Set Google Wallet Data';

  constructor() {}
}

export class SetGoogleWalletSaved {
  static readonly type = '[User] Set Google Wallet Saved';

  constructor(public objectCode: string) {}
}

export class SetReferrals {
  static readonly type = '[User] Set Referrals';
}

export class SendReferrals {
  static readonly type = '[User] Send Referrals';

  constructor(
    public emails: string[],
    public message: string
  ) {}
}

export class SendVerificationEmail {
  static readonly type = '[User] Send Verification Email';

  constructor(public email: string) {}
}
