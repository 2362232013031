<ng-container *ngIf="mainSettings$ | async as mainSettings">
  <ng-container [ngSwitch]="mainSettings?.mobile_app_layout">

    <ng-container *ngSwitchCase="'improved'">
      <p>Improved Layout Content</p>
    </ng-container>

    <ng-container *ngSwitchCase="'feature-image'">
      <div class="d-flex flex-column p-2 de-bg-medium cms-card-radius gap-2 m-3 shadow loyalty-card" (click)="onCardClick($event)">
        <div class="w-100 d-flex flex-row flex-nowrap justify-content-between align-items-center gap-2">
          <div class="d-flex flex-column justify-content-center gap-1">
            <div *ngIf="tiers" class="badge rounded-pill w-fit-content" [style.background-color]="'var(--ion-color-primary)'" [style.color]="'var(--ion-color-primary-txt)'">{{ currentTierLabel }}</div>
            <p class="m-0 header-font">{{ userName }}</p>
            <h5 class="fw-bold m-0">{{ userId }}</h5>
          </div>
          <div class="d-flex align-items-center gap-1">
            <h1 class="fw-bold header-font m-0">{{ points }}</h1>
            <small class="font-size-xsmall lh-sm">
              <span [innerHTML]="pointsLabelWithBreaks"></span>
            </small>
          </div>
        </div>
        <div style="position: relative;">
          <div class="progress"
               role="progressbar"
               aria-label="Progress Indicator"
               [attr.aria-valuenow]="progress"
               aria-valuemin="0"
               aria-valuemax="100"
               style="height: 16px;">
            <div class="progress-bar progress-bar-striped progress-bar-animated"
                 [style.width.%]="progress">
            </div>
          </div>
          <img *ngIf="progressIcon?.data?.full_url"
               [src]="progressIcon?.data?.full_url"
               alt="Progress Icon"
               class="progress-icon"
               [style.left]="progress + '%'" />
        </div>
        <div *ngIf="tiers" class="f-12">
          {{ nextTierLimit - currentTierValue }} More
          <span class="text-lowercase">{{ tierValueType }}</span> until
          <span class="fw-bold">{{ nextTierLabel }}</span> Unlocked
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <p>Classic Layout Content</p>
    </ng-container>

  </ng-container>
</ng-container>
