export interface PaytronixConfiguration {
  require_email_verification: boolean;
  id: number;
  merchant_id: string;
  paytronix_client_id: string;
  sandbox_mode: boolean;
  enable_oauth: boolean;
  oauth_base_url: string;
  enroll_card_template: string;
  oauth_card_template: string;
  enable_checkin_in_store: boolean;
  show_code_as_barcode?: boolean;
  prevent_code_toggle: boolean;
  allow_giftcard_transfer_to_loyalty: boolean;
  purchase_gift_cards: boolean;
  purchase_egift_cards: boolean;
  reload_gift_cards: boolean;
  reload_loyalty_balance_via_gift_cards: boolean;
  spreedly_token: string;
  apple_sign_in_integration_id: string;
  ios_push_integration_id: string;
  android_push_integration_id: string;
  store_group_code: string;
  show_tier_progress: boolean;
  tier_wallet_code: string;
  loyalty_tiers: any[];
  home_wallet_code: string;
  physical_gift_card_template_code: number;
  egift_card_template_code: number;
  reward_events?: RewardEvent[];
}

export interface RewardEvent {
  internal_name: string;
  event: EventType;
  wallet_code: string;
  quantity: string;
  program_type: ProgramType;
}

export enum EventType {
  FirstSignInWeb = 'first_sign_in_web',
  FirstSignInApp = 'first_sign_in_app',
  AnySignInWeb = 'any_sign_in_web',
  AnySignInApp = 'any_sign_in_app',
}

export enum ProgramType {
  LoyaltyPoints = 'LP',
  StoredValue = 'SV',
  CheckIn = 'CH',
  Paytronix = 'PX',
}
