<ng-container *ngIf="customPage.header_image?.data; else noImage">
  <img (click)="tileClick.emit()" class="cms-card-radius card more-card-img shadow-sm" [src]="customPage.header_image?.data?.full_url" />
</ng-container>

<ng-template #noImage>
  <div
    class="more-card card shadow-sm p-3"
    (click)="tileClick.emit()"
    [style.background-image]="branding.default_more_tile_background_image?.data?.full_url | cssUrl">
    <div class="icon-container position-relative pb-2">
      <!--        image should be set to this for default, and then pull from cms if there is an entry-->
      <!--    TODO: switch to image with page icon-->
      <i [class]="customPage.icon | bootstrapIcon" class="d-flex align-item-center" style="font-size: 2rem"></i>
      <ng-container *ngIf="inboxMessages$ | async as inboxMessages">
        <ion-badge [style.top.px]="4" [style.border-radius.px]="8" class="position-absolute start-100 translate-middle de-primary-bg" *ngIf="customPage.has_notifications">
          {{ (inboxMessages | unreadMessage) > 99 ? '99+' : inboxMessages | unreadMessage }}
        </ion-badge>
      </ng-container>


    </div>
    <div class="more-title text-break">
      {{ customPage.title }}
    </div>
  </div>
</ng-template>
