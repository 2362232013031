import { HandoffType } from '../../interfaces/handoff-type.enum';

export class SetMenu {
  static readonly type = '[Menu] Set Menu';

  constructor(
    public locationID: string,
    public handoffType: HandoffType
  ) {}
}

export class SetCategory {
  static readonly type = '[Menu] Set Category';

  constructor(
    public locationID: string,
    public handoffType: HandoffType,
    public categoryName: string
  ) {}
}

export class SetProduct {
  static readonly type = '[Menu] Set Product';

  constructor(
    public locationID: string,
    public categoryName: string,
    public productName: string,
    public handoffType: HandoffType
  ) {}
}

export class SetStaticMenu {
  static readonly type = '[Menu] Set Static Menu';

  constructor() {}
}

export class ToggleFullMenu {
  static readonly type = '[Menu] Toggle Full Menu';

  constructor() {}
}

export class GetProductDeals {
  static readonly type = '[Menu] Get Product Deals';

  constructor(public productID: string) {}
}
