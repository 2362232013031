import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../../../interfaces/product.interface';
import { Select } from '@ngxs/store';
import { GlobalStateModel } from '../../../../store/state.model';
import { Observable } from 'rxjs';
import { TextField } from '../../../../vendors/directus/interfaces/text-field.interface';

@Component({
  selector: 'app-recommended-deals-section',
  templateUrl: './recommended-deals-section.component.html',
  styleUrls: ['recommended-deals-section.component.scss'],
})
export class RecommendedDealsSectionComponent implements OnInit {
  @Input() recommendedDeals: Product[];
  @Select((state: GlobalStateModel) => state.app.textField) textField$: Observable<TextField>;

  constructor() {}
  ngOnInit() {}
}
