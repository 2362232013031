import { Image } from './image.interface';

export interface MainSettings {
  customization_layout: 'classic' | 'improved';
  id: number;
  created_on: Date;
  title: string;
  slogan: string;
  brochure_site_url: string;
  client_host_domain_names: string;
  show_private_locations: boolean;
  ga_ua: string;
  gtm_key: string;
  use_universal_analytics: boolean;
  sync_menu: boolean;
  gmaps_key: string;
  seo_title: string;
  seo_description: string;
  seo_keywords: string[];
  fb_pixel: string;
  force_login: boolean;
  disable_coupon: boolean;
  enable_qr_scanner: boolean;
  use_provider_upsells: boolean;
  fb_app_id: string;
  show_nutrition_key: boolean;
  show_category_name: boolean;
  onesignal_app_id: string;
  powered_by_name: string;
  powered_by_logo: Image;
  powered_by_link: string;
  show_instructions: boolean;
  show_verify_delivery_address_message: boolean;
  allow_users_to_pick_delivery_location: boolean;
  allow_manual_tablenumber_entry: boolean;
  enable_sign_in_with_apple: boolean;
  automatically_route_to_locations_page: boolean;
  instagram_refresh_token: string;
  facebook_link: string;
  twitter_link: string;
  instagram_link: string;
  youtube_link: string;
  loyalty_first_welcome_page: boolean;
  prompt_login_at_checkout: boolean;
  allow_call_to_server: boolean;
  allow_coupon_codes: boolean;
  allow_special_instructions: boolean;
  show_loyalty_checkin: boolean;
  loyalty_sections: LoyaltySection[];
  show_loyalty_balance: boolean;
  default_tip_amount: number;
  transaction_type_tags: any;
  location_card_emphasis: boolean;
  checkout_user_sign_up: boolean;
  auto_select_sign_up: boolean;
  auto_select_email_opt_in: boolean;
  menu_layout: string;
  mobile_app_layout: 'classic' | 'improved' | 'feature-image';
  show_group_order: boolean;
  show_apple_pay: boolean;
  show_google_pay: boolean;
  require_location_selection: boolean;
  hide_email_opt_in: boolean;
  show_main_loader: boolean;
  show_welcome_for_guests: boolean;
  product_page_back_route: boolean;
  show_donations: boolean;
  use_accessibe: boolean;
  loyalty_points_threshold: string;
  custom_user_fields: CustomUserField[] | null;
  apple_app_id: string;
  android_app_id: string;
  smart_banner_app_icon: Image;
  disable_smart_banners: boolean;
  show_brochure_in_mobile_app: boolean;
  show_email_opt_in: boolean;
  show_sms_opt_in: boolean;
}

export interface LoyaltySection {
  cta_external: string;
  cta_icon: string;
  cta_link: string;
  cta_title: string;
  emphasize_route: boolean;
  empty_cta: boolean;
  newItem: boolean;
  route_external: boolean;
  route_link: string;
  route_order: string;
  section_title: string;
  section_type: LoyaltySectionType;
  section_icon: string;
  section_description: string;
  show_empty: boolean;
  show_route: boolean;
  show_section: boolean;
}

export enum LoyaltySectionType {
  rewards = 'rewards',
  offers = 'offers',
  activity = 'activity',
  payments = 'payments',
  profile = 'profile',
  vehicles = 'vehicles',
  orders = 'orders',
  purchaseRewards = 'purchase-rewards',
  transferBalance = 'transfer-balance',
  savedAddresses = 'saved-addresses',
}

export interface CustomUserField {
  newItem: boolean;
  'Display Name': string;
  'Provider Field Name': string;
  'Input Type': string;
  'Input Mode': string;
  'Force Full Width': boolean | string;
  Required: boolean | string;
  'Max Length': number;
  'Min Length': number;
}
