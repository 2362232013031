<ng-container *ngIf="navbarSettings$ | async as navbarSettings">
<div id="test" class="option-selector-wrapper p-3"
     [class.ps-lg-0]="navbarSettings.fixed_navbar_width && mode.mode !== 'kiosk' && !isModal">
  <ng-container *ngIf="recommendedDeals$ | async as recommendedDeals">
    <ng-container *ngIf="recommendedDeals.length">
      <app-recommended-deals-section [recommendedDeals]="recommendedDeals"></app-recommended-deals-section>
    </ng-container>
  </ng-container>
  <div *ngIf="optionGroups && !isLoading" class="d-flex flex-column gap-5">

    <ng-container *ngFor="let optionGroup of optionGroups; index as i; trackBy: optionGroupTrackBy">
<!--      <ng-container *ngFor="let ingredientGroup of product.ingredientGroups; index as j">-->
      <ng-container *ngIf="!optionGroup.hideOnEdit">
        <div class="d-flex flex-column gap-3">

          <app-option-group-info
            [optionGroup]="optionGroup"
            [optionGroups]="displayOptionGroups"
          ></app-option-group-info>

          <div class="d-flex flex-row flex-wrap" *ngIf="optionGroup.options">
            <ng-container [ngSwitch]="optionGroup.displayType">

              <ng-container *ngSwitchCase="'images'">
                <app-option-card-selector
                  [optionGroup]="optionGroup"
                  [displayOptionGroups]="displayOptionGroups"
                  [isModifierFlow]="isModifierFlow"
                  (clickedOption)="optionClicked($event)"
                  [selectedOptions]="selectedOptions"
                ></app-option-card-selector>
              </ng-container>

              <ng-container *ngSwitchCase="'simple'">
                <app-radio-checkbox-selector
                  class="w-100"
                  [optionGroup]="optionGroup"
                  [isModifierFlow]="isModifierFlow"
                  (clickedOption)="optionClicked($event)"
                  [selectedOptions]="selectedOptions"
                  [displayOptionGroups]="displayOptionGroups"
                ></app-radio-checkbox-selector>
              </ng-container>

              <ng-container *ngSwitchCase="'button-group'">
                <app-option-card-selector
                  [optionGroup]="optionGroup"
                  [displayOptionGroups]="displayOptionGroups"
                  [isModifierFlow]="isModifierFlow"
                  (clickedOption)="optionClicked($event)"
                  [selectedOptions]="selectedOptions"
                ></app-option-card-selector>
              </ng-container>

              <ng-container *ngSwitchCase="'half-and-half'">
                <app-radio-checkbox-selector
                  class="w-100"
                  [optionGroup]="optionGroup"
                  [isModifierFlow]="isModifierFlow"
                  (clickedOption)="optionClicked($event)"
                  [selectedOptions]="selectedOptions"
                  [displayOptionGroups]="displayOptionGroups"
                ></app-radio-checkbox-selector>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <app-option-card-selector
                  [optionGroup]="optionGroup"
                  [displayOptionGroups]="displayOptionGroups"
                  [isModifierFlow]="isModifierFlow"
                  (clickedOption)="optionClicked($event)"
                  [selectedOptions]="selectedOptions"
                ></app-option-card-selector>
              </ng-container>

            </ng-container>
          </div>

        </div>
      </ng-container>
<!--      </ng-container>-->
    </ng-container>
    <ng-container *ngIf="upsells$ | async as upsells">
      <div *ngIf="upsells.items.length">
        <h5 class="option-group-name header-font text-start mb-3" *ngIf="textField$ | async as textFields">
          {{textFields.upsell_page_title ?? 'We think you might like these...'}}
        </h5>
        <div class="w-100 upsell-grid">
          <ng-container *ngFor="let upsell of upsells.items; trackBy: upsellTrackByFn">
            <ng-container *ngIf="!upsell.canModify">
              <app-upsell-card [upsell]="upsell"></app-upsell-card>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
</ng-container>
