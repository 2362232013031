<ng-container *ngIf="vendorSetup$ | async as vendorSetup">
  <ng-container *ngIf="mobileAppSettings$ | async as mobileAppSettings">
    <ng-container *ngIf="adjustedMobileAppSettings$ | async as adjustedMobileAppSettings">
      <ion-tab-bar class="zi-nav" slot="bottom" [style.background-color]="mobileAppSettings.background_color">
        <ng-container *ngIf="user$ | async as user">
          <ion-tab-button
            [routerLink]="'/welcome'"
            routerLinkActive
            #rlaHome="routerLinkActive"
            [routerLinkActiveOptions]="{ exact: true }"
            [style.background-color]="rlaHome.isActive ? adjustedMobileAppSettings.adjustedBackgroundColor : null">
            <ng-container *ngIf="mobileAppSettings.active_home_icon?.data && mobileAppSettings.inactive_home_icon?.data; else defaultHome">
              <img
                class="tab-icon"
                [src]="
                  rlaHome.isActive
                    ? mobileAppSettings.active_home_icon?.data?.full_url
                    : mobileAppSettings.inactive_home_icon?.data?.full_url
                " />
            </ng-container>
            <ng-template #defaultHome>
              <ion-icon
                class="tab-icon"
                name="home-outline"
                [style.color]="rlaHome.isActive ? mobileAppSettings.active_text_color : mobileAppSettings.inactive_text_color"></ion-icon>
            </ng-template>
            <ion-label [style.color]="rlaHome.isActive ? mobileAppSettings.active_text_color : mobileAppSettings.inactive_text_color">
              {{ mobileAppSettings.home_section_copy }}
            </ion-label>
          </ion-tab-button>
        </ng-container>
        <!--   Order Tab-->
        <ng-container *ngIf="vendorSetup.order_provider !== 'none'">
          <ion-tab-button
            (click)="setNewOrderType()"
            routerLink="/menu"
            routerLinkActive
            #rlaOrder="routerLinkActive"
            [routerLinkActiveOptions]="{ exact: false }"
            [style.background-color]="
              rlaOrder.isActive || router.url.includes('locations') ? adjustedMobileAppSettings.adjustedBackgroundColor : null
            ">
            <ng-container
              *ngIf="mobileAppSettings.active_order_icon?.data && mobileAppSettings.inactive_order_icon?.data; else defaultOrder">
              <img
                class="tab-icon"
                [src]="
                  rlaOrder.isActive || router.url.includes('locations')
                    ? mobileAppSettings.active_order_icon?.data?.full_url
                    : mobileAppSettings.inactive_order_icon?.data?.full_url
                "
                [alt]="mobileAppSettings.order_section_copy" />
            </ng-container>
            <!-- Bootstrap icon -->
            <ng-template #defaultOrder>
              <ion-icon
                class="tab-icon"
                [src]="'/assets/icons/order.svg'"
                [style.color]="
                  rlaOrder.isActive || router.url.includes('locations')
                    ? mobileAppSettings.active_text_color
                    : mobileAppSettings.inactive_text_color
                "></ion-icon>
            </ng-template>
            <ion-label
              [style.color]="
                rlaOrder.isActive || router.url.includes('locations')
                  ? mobileAppSettings.active_text_color
                  : mobileAppSettings.inactive_text_color
              ">
              {{ mobileAppSettings.order_section_copy }}
            </ion-label>
          </ion-tab-button>
        </ng-container>

        <!--  Rewards tab-->
        <ng-container *ngIf="vendorSetup.loyalty_provider !== 'none'">
          <ng-container *ngIf="user$ | async as user">
            <ion-tab-button
              [routerLink]="user.isGuest ? '/user/login' : '/profile/rewards'"
              routerLinkActive
              #rlaRewards="routerLinkActive"
              [routerLinkActiveOptions]="{ exact: true }"
              [style.background-color]="rlaRewards.isActive ? adjustedMobileAppSettings.adjustedBackgroundColor : null">
              <ng-container
                *ngIf="mobileAppSettings.active_rewards_icon?.data && mobileAppSettings.inactive_rewards_icon?.data; else defaultRewards">
                <img
                  class="tab-icon"
                  [src]="
                    rlaRewards.isActive
                      ? mobileAppSettings.active_rewards_icon?.data?.full_url
                      : mobileAppSettings.inactive_rewards_icon?.data?.full_url
                  "
                  [alt]="mobileAppSettings.rewards_section_copy" />
              </ng-container>
              <ng-template #defaultRewards>
                <ion-icon
                  class="tab-icon"
                  [src]="'/assets/icons/award.svg'"
                  [style.color]="
                    rlaRewards.isActive ? mobileAppSettings.active_text_color : mobileAppSettings.inactive_text_color
                  "></ion-icon>
              </ng-template>
              <ion-label [style.color]="rlaRewards.isActive ? mobileAppSettings.active_text_color : mobileAppSettings.inactive_text_color">
                {{ mobileAppSettings.rewards_section_copy }}
              </ion-label>
            </ion-tab-button>
          </ng-container>
        </ng-container>
        <!--      More Tab  -->
        <ng-container>
          <ion-tab-button
            routerLink="/more"
            routerLinkActive
            #rlaMore="routerLinkActive"
            class="position-relative"
            [routerLinkActiveOptions]="{ exact: true }"
            [style.background-color]="rlaMore.isActive ? adjustedMobileAppSettings.adjustedBackgroundColor : null">
            <ng-container *ngIf="inboxMessages$ | async as inboxMessages">
              <ion-badge mode="ios" class="fw-bold p-1 de-primary-bg position-absolute top-0 start-50 mt-1 ms-2" *ngIf="(inboxMessages | unreadMessage) > 0">
                {{ (inboxMessages | unreadMessage) > 99 ? '99+' : inboxMessages | unreadMessage }}
              </ion-badge>
            </ng-container>

            <ng-container *ngIf="mobileAppSettings.active_more_icon?.data && mobileAppSettings.inactive_more_icon?.data; else defaultMore">
              <img
                class="tab-icon"
                [src]="
                  rlaMore.isActive
                    ? mobileAppSettings.active_more_icon?.data?.full_url
                    : mobileAppSettings.inactive_more_icon?.data?.full_url
                "
                [alt]="mobileAppSettings.more_section_copy" />
            </ng-container>
            <ng-template #defaultMore>
              <ion-icon
                class="tab-icon"
                name="ellipsis-horizontal-outline"
                [style.color]="rlaMore.isActive ? mobileAppSettings.active_text_color : mobileAppSettings.inactive_text_color"></ion-icon>
            </ng-template>
            <ion-label [style.color]="rlaMore.isActive ? mobileAppSettings.active_text_color : mobileAppSettings.inactive_text_color">
              {{ mobileAppSettings.more_section_copy }}
            </ion-label>
          </ion-tab-button>
        </ng-container>
      </ion-tab-bar>
    </ng-container>
  </ng-container>
</ng-container>
