import { Component, Input, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Menu } from '../../../../interfaces/menu.interface';
import { Category as DineEngineCategory } from '../../../../interfaces/category.interface';
import { ThemeColor } from '../../../../vendors/directus/interfaces/theme-color.interface';
import { MobileService } from '../../../../services/mobile.service';
import { MainSettings } from '../../../../vendors/directus/interfaces/main-settings.interface';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Select } from '@ngxs/store';
import { GlobalStateModel } from '../../../../store/state.model';
import { NavbarSettings } from '../../../../vendors/directus/interfaces/navbar-settings.interface';

@Component({
  selector: 'app-home-menu',
  templateUrl: './home-menu.component.html',
  styleUrls: ['./home-menu.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('void', style({ opacity: '0' })),
      state('*', style({ opacity: '1' })),
      transition('void <=> *', animate('400ms ease-in')),
    ]),
  ],
})
export class HomeMenuComponent implements OnInit {
  // @Select(state => state.menu.menu) menu$: Observable<Menu>;
  // @Select(state => state.app.theme) theme$: Observable<ThemeColor[]>;
  // @Select(state => state.order.order) order$: Observable<Order>;
  // @Select(state => state.app.mainSettings) mainSettings$: Observable<MainSettings>;
  @Select((state: GlobalStateModel) => state.app.navbarSettings) navbarSettings$: Observable<NavbarSettings>;

  @Input() displayMenu: Menu;
  @Input() displayTheme: ThemeColor;
  @Input() isMobile: boolean;
  @Input() mainSettings: MainSettings;
  @Output() categoryClick = new EventEmitter<DineEngineCategory>();

  carouselWidth: number;
  menuLoading = false;
  displayMenuCategories = '';

  // Keep track of how many we show by default
  initialLimit = 10;
  showAllCategories = false;

  subs: Subscription[] = [];

  constructor(public mobile: MobileService) {}

  ngOnInit() {
    this.carouselWidth = window.innerWidth;
    this.setInitialLimit(window.innerWidth);
    this.carouselWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent) {
    const width = (event.target as Window).innerWidth;
    this.setInitialLimit(width);
  }

  // Decide how many categories to show by default
  private setInitialLimit(width: number): void {
    if (width <= 1139) {
      this.initialLimit = 6;
    } else if (width <= 1337) {
      this.initialLimit = 8;
    } else {
      this.initialLimit = 10;
    }
  }

  goToCategory(category: DineEngineCategory) {
    this.categoryClick.emit(category);
  }

  toggleShowAll(): void {
    this.showAllCategories = !this.showAllCategories;
  }
}
