import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Select } from '@ngxs/store';
import { GlobalStateModel } from '../../../../store/state.model';
import { Observable } from 'rxjs';
import { User } from '../../../../interfaces/user.interface';
import { MainSettings } from '../../../../vendors/directus/interfaces/main-settings.interface';
import { Image } from '../../../../vendors/directus/interfaces/image.interface';
import { RewardsBalances } from '../../../../interfaces/rewards-balances.interface';

@Component({
  selector: 'app-loyalty-card',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './loyalty-card.component.html',
  styleUrls: ['loyalty-card.component.scss'],
})
export class LoyaltyCardComponent {
  @Select((state: GlobalStateModel) => state.user.user) user$: Observable<User>;
  @Select((state: GlobalStateModel) => state.app.mainSettings)
  mainSettings$: Observable<MainSettings>;

  @Input() userName: string;
  @Input() userId: string;
  @Input() points: number;
  @Input() pointsLabel: string = 'Points Balance';
  @Input() progress: number;
  @Input() progressIcon: Image;
  @Input() currentTierLabel: string;
  @Input() currentTierValue = 0;
  @Input() nextTierLabel = 'Next Tier';
  @Input() nextTierLimit = 100;
  @Input() tierValueType = 'Points';
  @Input() tiers: boolean;


  @Output() cardClick = new EventEmitter<Event>();

  private user: User;
  private mainSettings: MainSettings;

  public get pointsLabelWithBreaks(): string {
    return this.pointsLabel?.replace(/ /g, '<br>') || '';
  }

  onCardClick(event: Event) {
    this.cardClick.emit(event);
  }
}
