<ng-container *ngIf="product">
  <div tabindex="0" class="product-card" [class.upsell-card]="isUpsell" (click)="cardClicked()"
       (keydown.enter)="cardClicked()">
    <ng-container *ngIf="isListCard; else verticalCard">
      <div class="product-card-list">
        <ng-container *ngTemplateOutlet="cardBody"></ng-container>
        <ng-container *ngTemplateOutlet="cardTop"></ng-container>
      </div>
      <!--        <ng-container *ngTemplateOutlet="cardFooter"></ng-container>-->
    </ng-container>
    <ng-template #verticalCard>
      <ng-container *ngTemplateOutlet="cardTop"></ng-container>
      <ng-container *ngTemplateOutlet="cardBody"></ng-container>
      <ng-container *ngIf="isUpsell">
        <ng-container *ngTemplateOutlet="cardFooter"></ng-container>
      </ng-container>
    </ng-template>
  </div>

  <ng-template #imgSkeleton>
    <div class="skeleton-image"></div>
  </ng-template>

  <ng-template #cardTop>

    <div class="product-card-top">
      <ng-container *ngIf="!isListCard">
        <div class="allergen-card">
          <app-allergen-icons
            [allergenList]="product.allergenInfo"></app-allergen-icons>
        </div>
      </ng-container>

      <ng-template #noConfigs>
        <ng-container *ngIf="!product?.requiresModification">
          <ng-container *ngIf="!cardLoading; else notLoaded">
            <button
              type="button"
              class="de-primary-btn-sm image-add d-flex flex-row flex-nowrap align-items-center justify-content-center"
              [attr.data-cy]="product?.name"
              (click)="$event.stopPropagation(); addToCartClicked()"
              (keydown.enter)="$event.stopPropagation(); addToCartClicked()">
              <ng-container
                *ngIf="
                    menuService.productLoading === product?.productID &&
                    addLoading
                  ">
                <div class="de-btn-loader"></div>
              </ng-container>
              <ng-container
                *ngIf="
                    menuService.productLoading !== product?.productID ||
                    !addLoading
                  ">
                <div class="me-1">Add</div>
                <i class="bi bi-plus-lg font-size-body d-flex"></i>
              </ng-container>
            </button>
          </ng-container>
        </ng-container>
      </ng-template>

      <ng-container
        *ngIf="product?.configurations?.length > 0; else noConfigs">
        <div class="image-configs">
          <ng-container *ngIf="product?.configurations[0]">
            <button
              type="button"
              class="de-primary-btn-sm image-config-btn d-flex flex-row flex-nowrap align-items-center justify-content-center"
              (click)="
                  $event.stopPropagation();
                  addConfiguredClicked(product?.configurations[0].ids, 0)
                "
              (keydown.enter)="
                  $event.stopPropagation();
                  addConfiguredClicked(product?.configurations[0].ids, 0)
                "
              [class.de-unselectable-item]="
                  addLoading || customizeLoading || addConfiguredLoading[1]
                "
              [class.right]="
                  product?.canModify &&
                  product?.configurations &&
                  product?.configurations?.length === 1
                "
              [class.left]="
                  product?.canModify &&
                  product?.configurations &&
                  product?.configurations?.length > 1
                ">
              <ng-container
                *ngIf="
                    menuService.productLoading === product?.productID &&
                    addConfiguredLoading[0]
                  ">
                <div class="de-btn-loader config-loader"></div>
              </ng-container>
              <div
                class="d-flex flex-row flex-nowrap align-items-center justify-content-center"
                [class.is-loading]="
                    menuService.productLoading === product?.productID &&
                    addConfiguredLoading[0]
                  ">
                  <span class="me-1">
                    {{ product?.configurations[0].name }}
                  </span>
                <i class="bi bi-plus-lg font-size-body d-flex"></i>
              </div>
            </button>
          </ng-container>
          <ng-container *ngIf="product?.configurations[1]">
            <button
              type="button"
              class="de-primary-btn-sm image-config-btn d-flex flex-row flex-nowrap align-items-center justify-content-center ms-2"
              (click)="
                  $event.stopPropagation();
                  addConfiguredClicked(product?.configurations[1].ids, 1)
                "
              (keydown.enter)="
                  $event.stopPropagation();
                  addConfiguredClicked(product?.configurations[1].ids, 1)
                "
              [class.de-unselectable-item]="
                  addLoading || addConfiguredLoading[0] || customizeLoading
                ">
              <ng-container
                *ngIf="
                    menuService.productLoading === product?.productID &&
                    addConfiguredLoading[1]
                  ">
                <div class="de-btn-loader config-loader"></div>
              </ng-container>
              <div
                class="d-flex flex-row flex-nowrap align-items-center justify-content-center"
                [class.is-loading]="
                    menuService.productLoading === product?.productID &&
                    addConfiguredLoading[1]
                  ">
                  <span class="me-1">
                    {{ product?.configurations[1].name }}
                  </span>
                <i class="bi bi-plus-lg font-size-body d-flex"></i>
              </div>
            </button>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="!mobile.isMobile; else mobileInfo">
        <button
          type="button"
          class="product-card-info"
          [ngbTooltip]="
              product?.longDesc
                ? product?.longDesc
                : product?.shortDesc
                ? product?.shortDesc
                : product?.seoDescription
            "
          *ngIf="
              product?.longDesc || product?.shortDesc || product?.seoDescription
            "
          placement="bottom-right">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-info-circle"
            viewBox="0 0 16 16">
            <path
              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
          </svg>
        </button>
      </ng-container>
      <ng-template #mobileInfo>
        <button
          type="button"
          class="product-card-info"
          (click)="
              $event.stopPropagation();
              $event.preventDefault();
              openInfoModal($event)
            "
          (keydown.enter)="
              $event.stopPropagation();
              $event.preventDefault();
              openInfoModal($event)
            "
          *ngIf="
              product?.longDesc || product?.shortDesc || product?.seoDescription
            ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-info-circle"
            viewBox="0 0 16 16">
            <path
              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
              d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
          </svg>
        </button>
      </ng-template>

      <!--            <ng-container *ngIf="!isListCard">-->
      <!--                <ng-container *ngIf="product?.configurations?.length > 1">-->
      <!--                    <ng-container *ngTemplateOutlet="customizeButton"></ng-container>-->
      <!--                </ng-container>-->
      <!--            </ng-container>-->

      <img
        *ngIf="product?.thumbnailImageURL && !isUpsell"
        [defaultImage]="'assets/img/600x600.png'"
        [lazyLoad]="product?.thumbnailImageURL"
        [alt]="product?.name"
        class="product-card-top-image"
        onerror="this.src='assets/img/600x600.png'" />
      <img
        *ngIf="product?.thumbnailImageURL && isUpsell"
        [src]="product?.thumbnailImageURL"
        [alt]="product?.name"
        class="product-card-top-image"
        onerror="this.src='assets/img/600x600.png'" />
      <ng-container *ngIf="!isListCard">
        <div
          *ngIf="!product?.thumbnailImageURL"
          [class.no-image-vertical]="isListCard"
          [class.no-image-category]="!isListCard">
          <img
            [alt]="product?.name"
            class="image"
            src="../../../../assets/img/600x600.png" />
          <div class="no-image-text">{{ product?.name }}</div>
        </div>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #cardBody>
    <div class="product-card-body">
      <div class="w-100 h-100 d-flex flex-column body-alignment p-3">
        <div class="d-flex flex-column w-100">
          <ng-container
            *ngIf="
                (product.badge_color &&
                  product.badge_text_color &&
                  product.badge_text) ||
                product?.canModify
              ">
            <div class="d-flex flex-row flex-wrap">
              <ng-container *ngIf="product?.canModify">
                <div
                  class="badge rounded-pill bg-light border w-fit-content me-1 mb-1 cy-modify-button"
                  [attr.data-cy]="product.name">
                  Customize
                </div>
              </ng-container>
              <ng-container
                *ngIf="
                    product.badge_color &&
                    product.badge_text_color &&
                    product.badge_text
                  ">
                <div
                  class="badge rounded-pill w-fit-content mb-1"
                  [ngStyle]="{
                      color: product.badge_text_color
                        ? product.badge_text_color
                        : null,
                      'background-color': product.badge_color
                        ? product.badge_color
                        : null
                    }">
                  {{ product.badge_text }}
                </div>
              </ng-container>
            </div>
          </ng-container>
          <div class="product-card-body-title" [class.fs-5]="mode.mode === 'kiosk'">
            {{ product?.name }}
          </div>
          <div class="product-card-body-price" [class.fs-6]="mode.mode === 'kiosk'">
            <ng-container *ngIf="product?.priceCents > 0; else pricesVary">
              <div class="text-muted font-size-body-small">
                {{ product?.priceCents / 100 | currency }}
              </div>
            </ng-container>
            <ng-template #pricesVary>
              <ng-container *ngIf="textFields$ | async as textField">
                <div class="text-muted" [class.font-size-body-small]="mode.mode !== 'kiosk'">
                  {{ textField.no_base_price_override }}
                </div>
              </ng-container>
            </ng-template>
          </div>
          <ng-container *ngIf="isListCard">
            <app-allergen-icons
              [allergenList]="product.allergenInfo"
              [isListCard]="true"></app-allergen-icons>
          </ng-container>
        </div>

        <!--                <ng-container *ngIf="isListCard && product?.configurations?.length > 1">-->
        <!--                    <div class="d-flex justify-content-start pt-2">-->
        <!--                        <ng-container *ngTemplateOutlet="customizeButton"></ng-container>-->
        <!--                    </div>-->
        <!--                </ng-container>-->
      </div>
    </div>
  </ng-template>

  <ng-template #cardFooter>
    <ng-container *ngIf="!isUpsell">
      <div class="product-card-footer">
        <ng-container
          *ngIf="product?.canModify && product?.configurations?.length === 1">
          <button
            type="button"
            class="de-secondary-btn-sm cy-modify-button left"
            [attr.data-cy]="product?.name"
            (click)="$event.stopPropagation(); customizeClicked()"
            (keydown.enter)="$event.stopPropagation(); customizeClicked()"
            [class.de-unselectable-item]="
              addLoading || addConfiguredLoading[0] || addConfiguredLoading[1]
            ">
            <div
              *ngIf="
                menuService.productLoading === product?.productID &&
                customizeLoading
              "
              class="de-btn-loader"></div>
            <div
              *ngIf="
                menuService.productLoading !== product?.productID ||
                !customizeLoading
              ">
              Customize
            </div>
          </button>
        </ng-container>

        <ng-container
          *ngIf="product?.configurations?.length > 0; else noConfigs">
          <ng-container *ngIf="product?.configurations[0]">
            <button
              type="button"
              class="de-primary-btn-sm"
              (click)="
                $event.stopPropagation();
                addConfiguredClicked(product?.configurations[0].ids, 0)
              "
              (keydown.enter)="
                $event.stopPropagation();
                addConfiguredClicked(product?.configurations[0].ids, 0)
              "
              [class.de-unselectable-item]="
                addLoading || customizeLoading || addConfiguredLoading[1]
              "
              [class.right]="
                product?.canModify &&
                product?.configurations &&
                product?.configurations?.length === 1
              "
              [class.left]="
                product?.canModify &&
                product?.configurations &&
                product?.configurations?.length > 1
              ">
              <div
                *ngIf="
                  menuService.productLoading === product?.productID &&
                  addConfiguredLoading[0]
                "
                class="de-btn-loader"></div>
              <div
                *ngIf="
                  menuService.productLoading !== product?.productID ||
                  !addConfiguredLoading[0]
                ">
                {{ product?.configurations[0].name }}
              </div>
            </button>
          </ng-container>
          <ng-container *ngIf="product?.configurations[1]">
            <button
              type="button"
              class="de-primary-btn-sm right"
              (click)="
                $event.stopPropagation();
                addConfiguredClicked(product?.configurations[1].ids, 1)
              "
              (keydown.enter)="
                $event.stopPropagation();
                addConfiguredClicked(product?.configurations[1].ids, 1)
              "
              [class.de-unselectable-item]="
                addLoading || addConfiguredLoading[0] || customizeLoading
              ">
              <div
                *ngIf="
                  menuService.productLoading === product?.productID &&
                  addConfiguredLoading[1]
                "
                class="de-btn-loader"></div>
              <div
                *ngIf="
                  menuService.productLoading !== product?.productID ||
                  !addConfiguredLoading[1]
                ">
                {{ product?.configurations[1].name }}
              </div>
            </button>
          </ng-container>
        </ng-container>

        <ng-template #noConfigs>
          <ng-container *ngIf="!product?.requiresModification">
            <ng-container
              *ngIf="
                product?.canModify &&
                ((product?.configurations &&
                  product?.configurations?.length > 0) ||
                  !product?.requiresModification)
              ">
              <button
                type="button"
                class="de-secondary-btn-sm cy-modify-button left"
                [attr.data-cy]="product?.name"
                (click)="$event.stopPropagation(); customizeClicked()"
                (keydown.enter)="$event.stopPropagation(); customizeClicked()"
                [class.de-unselectable-item]="
                  addLoading ||
                  addConfiguredLoading[0] ||
                  addConfiguredLoading[1]
                ">
                <div
                  *ngIf="
                    menuService.productLoading === product?.productID &&
                    customizeLoading
                  "
                  class="de-btn-loader"></div>
                <div
                  *ngIf="
                    menuService.productLoading !== product?.productID ||
                    !customizeLoading
                  ">
                  Customize
                </div>
              </button>
            </ng-container>
            <ng-container *ngIf="!cardLoading; else notLoaded">
              <button
                type="button"
                class="de-primary-btn-sm"
                [class.right]="
                  product?.canModify &&
                  ((product?.configurations &&
                    product?.configurations?.length > 0) ||
                    !product?.requiresModification)
                "
                [attr.data-cy]="product?.name"
                (click)="$event.stopPropagation(); addToCartClicked()"
                (keydown.enter)="$event.stopPropagation(); addToCartClicked()"
                [class.de-unselectable-item]="
                  customizeLoading ||
                  addConfiguredLoading[0] ||
                  addConfiguredLoading[1]
                ">
                <div
                  *ngIf="
                    menuService.productLoading === product?.productID &&
                    addLoading
                  "
                  class="de-btn-loader"></div>
                <div
                  *ngIf="
                    menuService.productLoading !== product?.productID ||
                    !addLoading
                  ">
                  Add
                </div>
              </button>
            </ng-container>
          </ng-container>
        </ng-template>

        <ng-container
          *ngIf="
            product?.canModify &&
            (!product?.configurations ||
              product?.configurations?.length <= 0) &&
            product?.requiresModification
          ">
          <button
            type="button"
            class="de-secondary-btn-sm cy-modify-button"
            [attr.data-cy]="product?.name"
            (click)="$event.stopPropagation(); customizeClicked()"
            (keydown.enter)="$event.stopPropagation(); customizeClicked()"
            [class.de-unselectable-item]="
              addLoading || addConfiguredLoading[0] || addConfiguredLoading[1]
            ">
            <div
              *ngIf="
                menuService.productLoading === product?.productID &&
                customizeLoading
              "
              class="de-btn-loader"></div>
            <div
              *ngIf="
                menuService.productLoading !== product?.productID ||
                !customizeLoading
              ">
              Customize
            </div>
          </button>
        </ng-container>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #customizeButton>
    <ng-container
      *ngIf="
        product?.canModify &&
        ((product?.configurations && product?.configurations?.length > 0) ||
          !product?.requiresModification)
      ">
      <button
        type="button"
        class="de-secondary-btn-sm cy-modify-button"
        [attr.data-cy]="product?.name"
        (click)="$event.stopPropagation(); customizeClicked()"
        (keydown.enter)="$event.stopPropagation(); customizeClicked()"
        [class.de-unselectable-item]="
          addLoading || addConfiguredLoading[0] || addConfiguredLoading[1]
        ">
        <div
          *ngIf="
            menuService.productLoading === product?.productID &&
            customizeLoading
          "
          class="de-btn-loader"></div>
        <div
          *ngIf="
            menuService.productLoading !== product?.productID ||
            !customizeLoading
          ">
          Customize
        </div>
      </button>
    </ng-container>
  </ng-template>

  <ng-template #infoModal>
    <div class="p-3">
      {{
        product?.longDesc
          ? product?.longDesc
          : product?.shortDesc
            ? product?.shortDesc
            : product?.seoDescription
      }}
    </div>
  </ng-template>

  <ng-template #upsellTop>
    <!--
      Minimal “card top” for an upsell item:
      - Shows product thumbnail if available
      - Fallback to placeholder if no image
    -->

    <!-- Product Image -->
    <img
      *ngIf="product?.thumbnailImageURL"
      [defaultImage]="'assets/img/600x600.png'"
      [lazyLoad]="product?.thumbnailImageURL"
      [alt]="product?.name"
      class="card-img-top upsell-card-img"
      onerror="this.src='assets/img/600x600.png'"
    />
    <div
      *ngIf="!product?.thumbnailImageURL"
      class="upsell-card-img empty"
    >
      <img
        [alt]="product?.name"
        class="image"
        src="../../../../assets/img/600x600.png"
      />
      <div class="no-image-text">{{ product?.name }}</div>
    </div>
  </ng-template>

  <ng-template #upsellBody>
    <!--
      Minimal “card body” for an upsell item:
      - Displays name, short desc, and price
    -->
    <div class="card-body d-flex flex-column p-3">
      <ng-container
        *ngIf="
                (product.badge_color &&
                  product.badge_text_color &&
                  product.badge_text) ||
                product?.canModify
              ">
        <div class="d-flex flex-row flex-wrap">
          <ng-container *ngIf="product?.canModify">
            <div
              class="badge rounded-pill bg-light border w-fit-content me-1 mb-1 cy-modify-button"
              [attr.data-cy]="product.name">
              Customize
            </div>
          </ng-container>
          <ng-container
            *ngIf="
                    product.badge_color &&
                    product.badge_text_color &&
                    product.badge_text
                  ">
            <div
              class="badge rounded-pill w-fit-content mb-1"
              [ngStyle]="{
                      color: product.badge_text_color
                        ? product.badge_text_color
                        : null,
                      'background-color': product.badge_color
                        ? product.badge_color
                        : null
                    }">
              {{ product.badge_text }}
            </div>
          </ng-container>
        </div>
      </ng-container>
      <!-- Product Title -->
      <h5 class="card-title fw-bold mb-1" title="{{ product?.name }}">{{ product?.name }}</h5>

      <!-- Short Description -->
      <p class="text-muted mb-2 line-clamp-3" title="{{ product?.shortDesc }}">
        {{ product?.shortDesc }}
      </p>

      <!-- Price -->
      <div class="mt-auto pt-2">
        <div class="d-flex align-items-center justify-content-between mb-2">
        <span class="price fw-bold text-success">
          <!-- Convert cents to dollars (or local currency) -->
          {{ product?.priceCents / 100 | currency:'USD':'symbol':'1.0-2' }}
        </span>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #upsellFooter>
    <!--
      Minimal “card footer” for an upsell item:
      - Single “Add to Cart” CTA
      - (Optional) “No thanks” link if your flow requires it
    -->
    <div class="card-footer border-0 bg-white">
      <ng-container *ngIf="!cardLoading; else notLoaded">
        <!-- Add to Cart Button -->
        <button
          type="button"
          class="de-primary-btn-sm rounded-btn-bottom w-100" [class.invisible]="product?.requiresModification"
          [attr.data-cy]="product?.name"
          (click)="$event.stopPropagation(); addToCartClicked()"
          (keydown.enter)="$event.stopPropagation(); addToCartClicked()"
          [disabled]="!product?.isAvailable"
        >
          Add to Cart
        </button>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #notLoaded>
    <button
      type="button"
      class="de-primary-btn-sm skeleton-button"
      [class.rounded-btn-bottom]="isUpsell"
      [attr.data-cy]="product?.name">
      <!--            Loading-->
    </button>
  </ng-template>
</ng-container>
