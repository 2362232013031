<div class="profile-page d-none d-lg-flex flex-column gap-3">
  <div class="container d-flex flex-nowrap flex-row w-100">
    <div class="col-12 col-lg-3 col-xl-2 w-100 ps-lg-0 pe-3 d-none d-lg-block">
      <div class="d-flex flex-column gap-2 sticky-sidebar">
        <app-dashboard-navigation-section></app-dashboard-navigation-section>
      </div>
    </div>
    <div class="col-12 col-lg-9 col-xl-10 d-flex flex-column gap-5 w-100 px-3 pe-lg-0 pt-0">
      <div class="w-100 container p-0 d-flex flex-column gap-6">
        <div class="d-flex flex-row flex-wrap gap-3 w-100">
          <div class="d-flex flex-row flex-wrap gap-3 w-100">
            <app-saved-addresses-section class="w-100"></app-saved-addresses-section>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="mainSettings$ | async as mainSettings">
  <div class="mobile-profile-page pt-3 w-100 d-flex flex-column gap-3 d-lg-none">

    <ng-container *ngIf="mainSettings.mobile_app_layout !== 'classic' && Capacitor.getPlatform() !== 'web'; else backToProfile">
      <div class="w-100">
        <div class="color-dark d-flex align-items-center fw-bold gap-2 cursor-pointer w-fit-content px-3 py-2" (click)="location.back()">
          <i class="bi bi-chevron-left"></i>
          Back
        </div>
      </div>
    </ng-container>
    <ng-template #backToProfile>
      <app-back-to-profile></app-back-to-profile>
    </ng-template>

    <app-saved-addresses-section class="w-100"></app-saved-addresses-section>
  </div>
</ng-container>
