<div class="w-100 d-flex flex-row flex-nowrap gap-2 align-items-center deal-card">
  <div class="flex-grow-1 text-start">
    <div>{{ recommendedDeal.name }}</div>
    <div *ngIf="recommendedDeal.priceCents" class="text-muted font-size-small">{{(recommendedDeal.priceCents/ 100) | currency}}</div>
  </div>
  <img [src]="recommendedDeal.thumbnailImageURL" style="width: 40px; height: 40px; object-fit: cover" class="rounded" *ngIf="recommendedDeal.thumbnailImageURL">
  <button
    type="button"
    class="de-primary-btn-sm image-add d-flex flex-row flex-nowrap align-items-center justify-content-center"
    (click)="addToOrder()"
  >

    <ng-container *ngIf="!isLoading else loading">
      <div class="me-1">Add</div>
      <i class="bi bi-plus-lg font-size-body d-flex"></i>
    </ng-container>
    <ng-template #loading>
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </ng-template>
  </button>
</div>
